import { css } from '@emotion/react';
import { navigate, Redirect, RouteComponentProps, useParams } from '@reach/router';
import { FC, useEffect, useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useMutation } from 'react-query';
import { Chat } from '../components/Chat';
import { useFeatureToggles, useIrisApi, useTenantData } from '../lib/api';
import { button, color } from '../styles';

import * as Sentry from '@sentry/browser';

export const ChatPage: FC<RouteComponentProps> = () => {
    const featureToggles = useFeatureToggles();
    const tenant = useTenantData();
    const irisApi = useIrisApi();
    let { chatId } = useParams();

    const [chatSecret, setChatSecret] = useState<undefined | null | string>(undefined);

    const bc = useRef<BroadcastChannel>();

    const [playChatSounds, setPlayChatSounds] = useState(true);

    useEffect(() => {
        if (sessionStorage.getItem(`chat_${chatId}_secret`)) {
            setChatSecret(sessionStorage.getItem(`chat_${chatId}_secret`));
        } else {
            setChatSecret(null);
        }
    }, [chatId]);

    useEffect(() => {
        if (featureToggles.only_one_chat) {
            bc.current = new window.BroadcastChannel('marco_polo');
            bc.current.onmessage = e => {
                if (e.data === 'marco') {
                    bc.current?.postMessage('polo');
                }
            };

            () => {
                bc.current?.close();
            };
        }
    }, [featureToggles.only_one_chat]);

    const closeChat = useMutation(async () => {
        await irisApi.post(`/api/v1/chat/${chatId}/close`, null, { params: { chatSecret } });
        try {
            sessionStorage.clear();
        } catch (err) {
            console.error(`Error clearing session storage: ${err}`);
            Sentry.captureException(err);
        }

        // closes broadcasting
        bc.current?.close();

        await navigate(`/chat_finished/${chatId}`, { replace: true });
    });

    if (chatSecret === undefined) {
        return <div>Laddar...</div>;
    }

    if (chatSecret === null) {
        return <Redirect to="/" noThrow />;
    }

    return (
        <Div100vh
            css={css`
                display: grid;
                grid-template-rows: auto auto 1fr;
                margin: 0 24px;
                padding: 24px 0;

                @media (min-width: 600px) {
                    padding: 16px 0;
                }
            `}
        >
            <div
                css={css`
                    border-bottom: 1px solid #ececec;
                    width: 100%;
                    max-width: 900px;
                    margin: 0 auto;
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    color: ${color.lightgrey};
                    font-size: 20px;
                    img {
                        height: 48px;
                    }
                `}
            >
                {tenant.LogoImage ? <img src="/jourhavande.png" /> : <div>{tenant.Title}</div>}
                <img src="/rkuf-logo.png" />
            </div>
            <div>
                <div
                    css={css`
                        max-width: 790px;
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 16px;
                        gap: 12px;
                    `}
                >
                    <button
                        css={css`
                            border-radius: 3px;
                            border: 0;
                            padding: 8px 16px;
                            background: ${color.lighterGrey}
                            :hover {
                                background: ${color.lightgrey};
                            }
                        `}
                        onClick={() => {
                            setPlayChatSounds(prev => !prev);
                        }}
                    >
                        {playChatSounds ? 'Tysta chatten' : 'Slå på ljudet'}
                    </button>
                    <button
                        css={button}
                        onClick={() => {
                            closeChat.mutate();
                        }}
                    >
                        Avsluta chatt
                    </button>
                </div>
            </div>
            <div
                css={css`
                    max-width: 790px;
                    width: 100%;
                    margin: 0 auto;
                `}
            >
                <Chat chatId={chatId} chatSecret={chatSecret} playChatSounds={playChatSounds} />
            </div>
        </Div100vh>
    );
};
