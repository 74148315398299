import { css } from '@emotion/react';
import { navigate, RouteComponentProps, useParams } from '@reach/router';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import { useAuthStatus, useFeatureToggles, useIrisApi } from '../../lib/api';
import { Chat } from '../../components/Chat';
import { button, buttonReset, color } from '../../styles';
import * as Sentry from '@sentry/browser';
import { setSentryChatId } from '../../App';

export const LoggedInChat: FC<RouteComponentProps> = () => {
    let { chatId } = useParams();

    setSentryChatId(chatId);

    return (
        <div
            css={css`
                max-width: 790px;
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr;
            `}
        >
            <ChatButtons chatId={chatId} />
            <Chat chatId={chatId} />
        </div>
    );
};

const ChatButtons: FC<{ chatId: string }> = ({ chatId }) => {
    const featureToggles = useFeatureToggles();
    const irisApi = useIrisApi();
    const queryClient = useQueryClient();
    const [showAddVolunteerDialog, setShowAddVolunteerDialog] = useState(false);
    const leaveChat = useMutation(
        async () => {
            await irisApi.post(`/api/v1/chat/${chatId}/leave`);
            await queryClient.invalidateQueries('chat_list');
            await navigate('/frivillig/chat');
        },
        { retry: 3 }
    );
    const addVideo = useMutation(async () => {
        await irisApi.post(`/api/v1/chat/${chatId}/addVideo`);
    });

    return (
        <div
            css={css`
                border-bottom: 1px solid ${color.lightgrey};
                border-top: 1px solid ${color.lightgrey};
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;

                button {
                    ${buttonReset};
                    border-left: 1px solid ${color.lightgrey};
                    border-right: 1px solid ${color.lightgrey};
                    padding: 8px 16px;

                    &:hover {
                        background: #ececec;
                    }
                }
            `}
        >
            <AddVolunteerDialog
                chatId={chatId}
                showDialog={showAddVolunteerDialog}
                close={() => setShowAddVolunteerDialog(false)}
            />

            <button onClick={() => leaveChat.mutate()} disabled={leaveChat.isLoading}>
                {leaveChat.isLoading ? 'Lämnar...' : 'Lämna chatt'}
            </button>
            {featureToggles.videochat && (
                <button onClick={() => addVideo.mutate()} disabled={addVideo.isLoading}>
                    {addVideo.isLoading ? 'Laddar...' : 'Växla till video'}
                </button>
            )}
            <button onClick={() => setShowAddVolunteerDialog(true)}>Lägg till frivillig</button>
        </div>
    );
};

type ActiveUser = {
    ID: number;
    Name: string;
    Attributes: Record<string, number[]>;
    ChatCount: number;
};

const AddVolunteerDialog: FC<{
    chatId: string;
    showDialog: boolean;
    close: () => void;
}> = ({ chatId, showDialog, close }) => {
    return (
        <Dialog
            isOpen={showDialog}
            onDismiss={close}
            css={css`
                max-width: 700px;
                width: 100%;
            `}
            aria-labelledby={'volunteer-dialog-label'}
        >
            <h1 id={'volunteer-dialog-label'}>Lägg till frivillig</h1>
            <AddVolunteerDialogInner chatId={chatId} close={close} />
        </Dialog>
    );
};

const AddVolunteerDialogInner: FC<{ chatId: string; close: () => void }> = ({ chatId, close }) => {
    const irisApi = useIrisApi();
    const users = useQuery('active_users', async () => {
        let res = await irisApi.get<ActiveUser[]>('/api/v1/active_users');
        return res.data;
    });

    const authStatus = useAuthStatus();

    const addUser = useMutation(
        async (userId: number) => {
            try {
                await irisApi.post(`/api/v1/chat/${chatId}/addUser`, { userId });
                close();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                Sentry.captureException(err);
                let maybeMessage = err.response?.data;
                if (maybeMessage && typeof maybeMessage === 'string') {
                    throw new Error(maybeMessage);
                }
                throw err;
            }
        },
        { useErrorBoundary: false }
    );

    if (
        addUser.isLoading ||
        users.isLoading ||
        !users.data ||
        authStatus.isLoading ||
        !authStatus.data
    ) {
        return <div>Laddar...</div>;
    }

    return (
        <div>
            {addUser.error ? <div>{(addUser.error as Error).toString()}</div> : null}
            {users.data
                .filter(u => u.ID != authStatus.data.user?.id)
                .map(user => (
                    <button
                        css={css`
                            ${button};
                            display: block;
                            width: 100%;
                            text-align: left;
                        `}
                        key={user.ID}
                        onClick={() => addUser.mutate(user.ID)}
                    >
                        {user.Name}
                    </button>
                ))}
        </div>
    );
};
