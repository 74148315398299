import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import { StartChat } from '../components/StartChat';
import { color } from '../styles';

export const Preview: FC<RouteComponentProps> = () => {
    return (
        <div
            css={css`
                max-width: 866px;
                margin: 20vh auto;
                border: 1px solid ${color.lighterGrey};
                border-width: 1px 0px 1px 0px;
            `}
        >
            <StartChat />
        </div>
    );
};
