import { css } from '@emotion/react';
import { Link, LinkGetProps, RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import { FC, PropsWithChildren } from 'react';
import { useQuery } from 'react-query';
import { useIrisApi } from '../../lib/api';
import { color } from '../../styles';
import { LoggedInLayout } from './LoggedInLayout';

export const Archive: FC<PropsWithChildren<RouteComponentProps>> = ({ children }) => {
    return (
        <LoggedInLayout fullSize={true}>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 48px;
                    height: 100%;
                    min-height: 100%;
                `}
            >
                <ChatList />
                {children}
            </div>
        </LoggedInLayout>
    );
};

const ChatList: FC = () => {
    const irisApi = useIrisApi();
    let chats = useQuery('chat_archive', async () => {
        let res = await irisApi.get<Array<{
            ID: string;
            CreatedAt: string;
            FriendlyName: string;
            Users: string[];
            Attributes: Record<string, string>;
            Feedback: Record<string, string>;
        }> | null>('/api/v1/chat_archive');

        return res.data?.filter(chat => !chat.ID.includes('internal'));
    });

    if (chats.isLoading || chats.data === undefined) {
        return <div>Laddar...</div>;
    }

    if (chats.data === null) {
        return <div>Inga aktiva chattar </div>;
    }

    return (
        <div
            css={css`
                height: 100%;
                max-height: 100%;
                position: relative;
                width: 250px;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    overflow-y: scroll;
                `}
            >
                {(chats.data || []).map(chat => (
                    <Link
                        key={chat.ID}
                        getProps={isActive}
                        css={css`
                            display: grid;
                            padding: 8px;
                            gap: 8px;
                            grid-template-columns: auto auto;
                            background: #ececec;
                            border-radius: 3px;
                            margin-bottom: 8px;

                            &[data-active='true'] {
                                background: ${color.lavendel};
                            }
                        `}
                        to={`/frivillig/archive/${chat.ID}`}
                    >
                        <div
                            css={css`
                                grid-column: 1 / span 2;
                                color: ${color.lightgrey};
                                font-size: 0.8em;
                            `}
                        >
                            {format(new Date(chat.CreatedAt), 'dd.MM.yyyy HH:mm')}
                        </div>
                        <div
                            css={css`
                                grid-column: 1 / span 2;
                            `}
                        >
                            {chat.FriendlyName}
                        </div>
                        {chat.Users.filter(Boolean).map((user, idx) => (
                            <div
                                key={idx}
                                css={css`
                                    grid-column: 1 / span 2;
                                `}
                            >
                                {user}
                            </div>
                        ))}
                        {Object.entries(chat.Attributes).map(([key, val]) => (
                            <>
                                <div
                                    css={css`
                                        font-style: italic;
                                    `}
                                    key={key}
                                >
                                    {key}
                                </div>
                                <div
                                    css={css`
                                        justify-self: start;
                                    `}
                                >
                                    {val.length > 20 ? `${val.slice(0, 20)}...` : val}
                                </div>
                            </>
                        ))}
                    </Link>
                ))}
            </div>
        </div>
    );
};

const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent ? { 'data-active': 'true' } : {};
};
