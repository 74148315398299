import { RouteComponentProps, useParams } from '@reach/router';
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useIrisApi, useTenantData } from '../lib/api';
import * as Sentry from '@sentry/browser';

export const MoreFeedback: FC<RouteComponentProps> = () => {
    const irisApi = useIrisApi();
    let { chatId } = useParams();
    const tenantData = useTenantData();
    const [submitted, setSubmitted] = useState(false);
    const [formState, setFormState] = useState<{
        q1?: boolean;
        q2?: string;
        q3?: string;
        q4?: boolean;
    }>();

    const questions = {
        q1: 'Är det första gången du skriver till oss?',
        q2: 'Vad tyckte du om samtalet?',
        q3: 'Har du förslag på hur chatten kan förbättras?',
        q4: 'Får vi använda citat från dina svar anonymt när vi informerar om chatten',
    };

    let { mutate } = useMutation(
        async () => {
            if (formState) {
                try {
                    await irisApi.post(`/api/v1/chat/${chatId}/feedback`, {
                        feedback: {
                            q1: formState.q1 ? 'Ja' : 'Nej',
                            q2: formState.q2,
                            q3: formState.q3,
                            q4: formState.q4 ? 'Ja' : 'Nej',
                        },
                    });
                } catch (error) {
                    Sentry.captureException(error);
                    throw error;
                }
            }
        },
        { useErrorBoundary: false }
    );

    const filled = formState
        ? Object.values(formState).filter(e => e !== null).length == Object.keys(questions).length
        : false;

    const submitButtonFilter = submitted
        ? css`
              filter: grayscale(60%);
          `
        : css`
              filter: none;
          `;

    const isJourhanvande = tenantData.Title.includes('Jourhavande');
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
            `}
        >
            <div
                css={css`
                    width: 520px;
                    height: auto;
                    margin: 0 auto;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-size: 1px;
                    border-style: solid;
                    margin-bottom: 8px;
                    gap: 24px;
                    border-color: #ff5e5e;
                    box-shadow: 0 0 0.2rem 0.1rem #ff5e5e;
                `}
            >
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            gap: 8px;
                            width: 80%;
                        `}
                    >
                        <div
                            css={css`
                                font-weight: bold;
                                font-size: 14px;
                            `}
                        >
                            Vi vill veta vad du tycker om chatten!
                        </div>
                        <div
                            css={css`
                                font-style: italic;
                                font-size: 12px;
                            `}
                        >
                            Det du skriver här sammanställs anonymt tillsammans med de svar alla
                            andra gett och används för att göra chatten bättre. Det kan också komma
                            att användas i utbildning av frivilliga, när vi informerar om chatten
                            och i återrapportering till våra finansiärer.
                        </div>
                    </div>
                    <div
                        css={css`
                            img {
                                height: 36px;
                            }
                        `}
                    >
                        <img src="/rkuf-logo.png" />
                    </div>
                </div>
                <div
                    css={css`
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        width: 80%;
                    `}
                >
                    <div css={question}>
                        <div>{questions.q1}</div>
                        <div css={radioButtonDiv}>
                            <input
                                type="radio"
                                checked={formState?.q1}
                                onChange={() => {
                                    setFormState({ ...formState, q1: true });
                                }}
                            />{' '}
                            Ja
                            <input
                                type="radio"
                                checked={formState?.q1 === false}
                                onChange={() => {
                                    setFormState({ ...formState, q1: false });
                                }}
                            />{' '}
                            Nej
                        </div>
                    </div>
                    <div css={question}>
                        <div>{questions.q2}</div>
                        <input
                            type="text"
                            value={formState?.q2}
                            placeholder={'Skriv här...'}
                            onChange={event => {
                                setFormState({ ...formState, q2: event.target.value });
                            }}
                            css={input}
                        />
                    </div>
                    <div css={question}>
                        <div>{questions.q3}</div>
                        <input
                            type="text"
                            value={formState?.q3}
                            placeholder={'Skriv här...'}
                            onChange={event => {
                                setFormState({ ...formState, q3: event.target.value });
                            }}
                            css={input}
                        />
                    </div>
                    <div css={question}>
                        <div>{questions.q4}</div>
                        <div css={radioButtonDiv}>
                            <input
                                type="radio"
                                checked={formState?.q4}
                                onChange={() => {
                                    setFormState({ ...formState, q4: true });
                                }}
                            />{' '}
                            Ja
                            <input
                                type="radio"
                                checked={formState?.q4 === false}
                                onChange={() => {
                                    setFormState({ ...formState, q4: false });
                                }}
                            />{' '}
                            Nej
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    `}
                >
                    <div
                        css={css`
                            width: 70%;
                            justify-content: flex-start;
                            font-style: italic;
                            font-size: 14px;
                        `}
                    >
                        Tack för att du delar med dig av din åsikt! Vill du svara på fler frågor?{' '}
                        <a
                            href={
                                isJourhanvande
                                    ? 'https://forms.office.com/Pages/ResponsePage.aspx?id=Cz8EYivH-k6AVY5Pw_TMoY4k6iWocxFLudsvV74zO0BURUpROURCQTgxSUFQNVhaRElXOVMxTkI3QiQlQCN0PWcu'
                                    : 'https://forms.office.com/Pages/ResponsePage.aspx?id=Cz8EYivH-k6AVY5Pw_TMobRAHPB-XjZClS6XsfHW45hUNENYNDFPNFhPTUZLUERUVFdKRzVJMkNONS4u'
                            }
                            target="_blank"
                            css={css`
                                text-decoration: underline;
                                color: #5f5f5f;
                            `} rel="noreferrer"
                        >
                            Svara gärna på vår enkät.
                        </a>
                    </div>
                    <div
                        css={css`
                            align-self: flex-end;
                        `}
                    >
                        <button
                            onClick={() => {
                                if (filled) {
                                    mutate();
                                    setSubmitted(true);
                                }
                            }}
                            css={css`
                                border-radius: 8px;
                                border: 1px solid #ff5e5e;
                                background: #ff5e5e;
                                padding: 6px 12px;
                                align-self: flex-end;
                                color: #ffffff;
                                font-size: 12px;
                                font-weight: bold;
                                margin-top: 24px;
                                ${submitButtonFilter}
                            `}
                        >
                            Skicka svar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const radioButtonDiv = css`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-left: -0.3rem;
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 50%;
        width: 12px;
        height: 12px;

        border: 2px solid black;
        transition: 0.2s all linear;
    }

    input:checked {
        background: black;
    }
`;

const input = css`
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    font-size: 12px;
    ::placeholder {
        font-style: italic;
    }
`;

const question = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 450;
    gap: 8px;
`;
