import { css } from '@emotion/react';
import { RouteComponentProps, Router } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';
import * as Sentry from '@sentry/react';

import { useAuthStatus, useFeatureToggles, useTenantData } from './lib/api';
import './global.css';
import { GlobalEventsProvider } from './lib/global_events';
import { UserAdmin } from './pages/settings/UserAdmin';
import { ChatPage } from './pages/Chat';
import { Preview } from './pages/Home';
import { LoggedInChat } from './pages/logged_in/LoggedInChat';
import { LoggedInHome } from './pages/logged_in/LoggedInHome';
import { Login } from './pages/logged_in/Login';
import { MyChats } from './pages/logged_in/MyChats';
import { Profile } from './pages/logged_in/Profile';
import { Queue } from './pages/logged_in/Queue';
import { ResetPassword } from './pages/ResetPassword';
import { QueueSettings } from './pages/settings/QueueSettings';
import { Archive } from './pages/logged_in/Archive';
import { ArchiveChat } from './pages/logged_in/ArchiveChat';
import { ErrorBoundary, FallbackProps, useErrorHandler } from 'react-error-boundary';
import { button, lavendelButton } from './styles';
import { TextAdmin } from './pages/settings/TextsAdmin';
import { AttributesAdmin } from './pages/settings/AttributesAdmin';
import { SetupChat } from './pages/SetupChat';
import { Embed } from './pages/Embed';
import { ChatFinished } from './pages/ChatFinished';
import { Stats } from './pages/logged_in/Stats';
import { Helmet } from 'react-helmet';
import { InternalChatPage } from './pages/InternalChat';
import { MoreFeedback } from './pages/MoreFeedback';
import { AlreadyInChat } from './pages/AlreadyInChat';

const NotFound: FC<RouteComponentProps> = () => (
    <div
        css={css`
            font-size: 20px;
            margin: 20vh auto;
            text-align: center;
        `}
    >
        Sidan finns inte
    </div>
);

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    return (
        <div
            role="alert"
            css={css`
                font-size: 20px;
                margin: 20vh auto;
                text-align: center;
            `}
        >
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>

            <button
                css={lavendelButton}
                onClick={() => {
                    //! resetErrorBoundary was called before, but this could lead to a never ending failing loop. A refresh will trigger some redirections that will avoid this.
                    //! Also in the scenario where you lost your admin/manager status, a reload will remove the administration button from showing.
                    return window.location.reload();
                }}
            >
                Try again
            </button>
        </div>
    );
}

const TestServerError: FC<RouteComponentProps> = () => {
    throw new Error('Test Server Error');
};

const sentryErrorHandler = (error: Error, { componentStack }: { componentStack: string }) => {
    Sentry.withScope(scope => {
        scope.setExtras({ componentStack });
        Sentry.captureException(error);
    });
};

const setTrackingId = (trackingId: string) => {
    Sentry.setUser({ id: trackingId });
};

export const setSentryFriendlyName = (name: string) => {
    Sentry.setUser({ name: name });
};

export const setSentryChatId = (id: string) => {
    Sentry.setContext('chat', {
        id: id,
    });
};

const App = () => {
    const queryErrorResetBoundary = useQueryErrorResetBoundary();
    const tenantData = useTenantData();

    return (
        <ErrorBoundary
            onError={sentryErrorHandler}
            FallbackComponent={ErrorFallback}
            onReset={() => {
                queryErrorResetBoundary.reset();
            }}
        >
            <React.StrictMode>
                <Helmet>
                    <title>{tenantData.Title}</title>
                </Helmet>
                <GlobalEventsProvider>
                    <Router>
                        <Preview path="/" />
                        <Preview path="/preview" />
                        <Embed path="/embed" />
                        <ChatPage path="/chat/:chatId" />
                        <SetupChat path="/setupChat/:attributes" />
                        <AlreadyInChat path="already-in-chat" />
                        <ResetPassword path="/reset_password/:token" />
                        <TestError path="/test-error" />
                        <TestServerError path="/test-server-error" />
                        <LoggedInApp path="/frivillig/*" />
                        <ChatFinished path="/chat_finished/:chatId" />
                        <MoreFeedback path="/more-feedback/:chatId" />
                        <NotFound default />
                    </Router>
                </GlobalEventsProvider>
            </React.StrictMode>
        </ErrorBoundary>
    );
};

const TestError: FC<RouteComponentProps> = () => {
    let errorHandler = useErrorHandler();
    useEffect(() => {
        errorHandler(new Error('Test Error'));
    });

    return <div>Loading...</div>;
};

const LoggedInApp: FC<RouteComponentProps> = () => {
    let { data: authStatus, isLoading, error } = useAuthStatus();
    const featureToggles = useFeatureToggles();

    if (error) {
        throw error;
    }

    if (isLoading || !authStatus) {
        return <div>Laddar...</div>;
    }

    if (authStatus.status !== 'logged_in') {
        return <Login />;
    }

    if (authStatus.user) {
        setTrackingId(authStatus.user.trackingId);
    }

    return (
        <Router>
            <LoggedInHome path="/" />
            <Queue path="queue" />
            <MyChats path="chat">
                <LoggedInChat path=":chatId" />
            </MyChats>
            <Archive path="archive">
                <ArchiveChat path=":chatId" />
            </Archive>
            <UserAdmin path="settings/users" />
            <TextAdmin path="settings/texts" />
            <AttributesAdmin path="settings/attributes" />
            <QueueSettings path="settings/queue" />
            <Profile path="profile" />
            <Stats path="stats" />
            {featureToggles.display_internal_chat && <InternalChatPage path="/internal-chat" />}
            <NotFound default />
        </Router>
    );
};

export default App;
