import { useQueryClient, useQuery } from 'react-query';
import { useIrisApi } from './api';
import { useGlobalEvents } from './global_events';

type ChatType = {
    ID: string;
    CreatedAt: string;
    UpdatedAt: string;
    FriendlyName: string;
    Attributes: Record<string, string>;
    ReadMark: string;
    ChatUserJoinedAt: string;
    MessageCreatedAtMax: string;
    UserIsActive: boolean;
    Closed: boolean;
    NumUnreadMessages: number;
    VideoID: string;
};

export function useMyChats() {
    const irisApi = useIrisApi();
    const queryClient = useQueryClient();

    const chats = useQuery(
        'chat_list',
        async () => {
            let res = await irisApi.get<Array<ChatType> | null>('/api/v1/chat');
            return res.data?.filter(c => !c.ID.includes('internal'));
        },
        { refetchInterval: 30000 }
    );

    useGlobalEvents('my_chats_updated', () => {
        queryClient.invalidateQueries('chat_list').catch(err => console.error(err));
    });

    return chats;
}
