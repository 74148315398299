import { css } from '@emotion/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { FC } from 'react';
import Div100vh from 'react-div-100vh';
import { Feedback } from '../components/Feedback';

export const ChatFinished: FC<RouteComponentProps> = () => {
    let { chatId } = useParams();

    return (
        <Div100vh
            css={css`
                display: flex;
                align-items: center;
                font-size: 24px;
                flex-direction: column;
                justify-content: center;
                margin-top: 20vh;
                margin: 0 16px;
            `}
        >
            <div
                css={css`
                    margin-bottom: 5vh;
                `}
            >
                <Feedback chatId={chatId} />
            </div>
            Du kan nu stänga detta fönster
        </Div100vh>
    );
};
