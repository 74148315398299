import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Buttons, Field, Form } from '../../components/Form';
import { useAuthStatus, useFeatureToggles, useIrisApi } from '../../lib/api';
import { SettingsLayout } from './SettingsLayout';
import Editor from 'react-pell';
import { Link, TextBolder, TextItalic, TextUnderline } from 'phosphor-react';
import { exec } from 'pell';
import { s, fontStyle } from '../../styles';

const keys = ['queue_is_open', 'queue_is_full', 'queue_is_closed'];
const internal_info_key = 'internal_info';

export const TextAdmin: FC<RouteComponentProps> = () => {
    const irisApi = useIrisApi();
    const authStatus = useAuthStatus();

    if (authStatus.data?.user?.role == 'volunteer') {
        return <Redirect to="/frivillig/queue"></Redirect>;
    }

    // We don't want this to update after the page is loaded, so we use a separate use-query
    // cache key
    const texts = useQuery(
        'texts-for-admin',
        async () => {
            let res = await irisApi.get<Array<{ key: string; value: string }>>('/api/v1/texts');
            let data: Record<string, string> = {};
            res.data.map(({ key, value }) => (data[key] = value));
            return data;
        },
        { refetchOnWindowFocus: false }
    );

    const [values, setValues] = useState<Record<string, string>>({});
    const featureToggles = useFeatureToggles();

    useEffect(() => {
        if (texts.status === 'success') {
            setValues(texts.data);
        }
    }, [texts.status, texts.data]);

    const saveTexts = useMutation(
        async () => {
            await irisApi.patch('/api/v1/texts', values);
        },
        { useErrorBoundary: false, retry: 2 }
    );

    return (
        <SettingsLayout>
            <section
                css={css`
                    max-width: 350px;
                `}
            >
                <Form
                    error={saveTexts.error as Error}
                    onSubmit={() => {
                        saveTexts.mutate();
                        return true;
                    }}
                >
                    {keys.map(key => (
                        <Field label={key} key={key}>
                            <textarea
                                autoComplete="off"
                                value={values[key] || ''}
                                onChange={ev => {
                                    setValues(old => ({
                                        ...old,
                                        [key]: ev.target.value,
                                    }));
                                }}
                            />
                        </Field>
                    ))}
                    {featureToggles.internal_info && (
                        <div
                            css={css`
                                display: block;
                                margin-bottom: ${s(4)};
                            `}
                        >
                            <label
                                css={css`
                                    ${fontStyle.label};
                                `}
                                htmlFor={internal_info_key}
                            >
                                {internal_info_key}
                            </label>
                            <InternalInfoEditor
                                id={internal_info_key}
                                content={values[internal_info_key] || ''}
                                onChange={(message: string) => {
                                    setValues(old => ({
                                        ...old,
                                        [internal_info_key]: message,
                                    }));
                                }}
                            />
                        </div>
                    )}
                    <Buttons>
                        <Button disabled={saveTexts.isLoading}>
                            {saveTexts.isLoading ? 'Laddar' : 'Spara'}
                        </Button>
                    </Buttons>
                </Form>
            </section>
        </SettingsLayout>
    );
};

const InternalInfoEditor = ({
    id,
    content,
    onChange,
}: {
    id: string;
    content: string;
    onChange: (message: string) => void;
}) => {
    return (
        <>
            {' '}
            <div
                id={id}
                css={css`
                    display: flex;
                    grid-area: buttons;
                    gap: 8px;
                    position: relative;
                    button {
                        border: 0;
                        border-radius: 0.25rem;
                        font-size: 1rem;
                        line-height: 1.2;
                        white-space: nowrap;
                        text-decoration: none;
                        margin: 8px 0;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        div {
                            display: flex;
                            vertical-align: center;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                `}
            >
                <button
                    title="Bold"
                    onClick={() => {
                        exec('bold');
                    }}
                >
                    <div>
                        <TextBolder />
                    </div>
                </button>
                <button
                    title="Underline"
                    onClick={() => {
                        exec('underline');
                    }}
                >
                    <div>
                        <TextUnderline />
                    </div>
                </button>
                <button
                    title="Italic"
                    onClick={() => {
                        exec('italic');
                    }}
                >
                    <div>
                        <TextItalic />
                    </div>
                </button>
                <button
                    title="Link"
                    onClick={() => {
                        const url = window.prompt('Enter the link URL');
                        if (url) exec('createLink', url);
                    }}
                >
                    <div>
                        <Link />
                    </div>
                </button>
            </div>
            <Editor
                defaultContent={content}
                actions={[]}
                buttonClass="editor-button"
                contentClass="internal-info-editor-content"
                containerClass="editor-container"
                onChange={onChange}
            />
        </>
    );
};
