import { useQueryClient, useQuery } from 'react-query';
import { useIrisApi } from './api';
import { useGlobalEvents } from './global_events';

export function useInternalChat() {
    const irisApi = useIrisApi();
    const queryClient = useQueryClient();

    const chats = useQuery(
        'get-internal-chat',
        async () => {
            let res = await irisApi.get<{
                NumUnreadMessages: number;
            } | null>('/api/v1/internal-chat');
            return res.data;
        },
        { refetchInterval: 30000 }
    );

    useGlobalEvents('internal_chat_updated', () => {
        queryClient.invalidateQueries('get-internal-chat').catch(err => console.error(err));
    });

    return chats;
}
