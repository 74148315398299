import { RouteComponentProps } from '@reach/router';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useAuthStatus, useBaseUrl, useIrisApi } from '../lib/api';
import { LoggedInLayout } from './logged_in/LoggedInLayout';
import { useQuery, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { css } from '@emotion/react';
import { buttonReset, color } from '../styles';
import { InternalPellEditor } from '../components/InternalPellEditor';
import { DownloadSimple } from 'phosphor-react';

export const InternalChatPage: FC<RouteComponentProps> = () => {
    return (
        <LoggedInLayout fullSize={true}>
            <InternalChat />
        </LoggedInLayout>
    );
};

export const InternalChat: FC = () => {
    const chatId = 'internal';
    const chatSecret = nanoid();
    const [now, setNow] = useState(() => new Date());
    const inputFieldInFocus = useRef(false);
    const unMarkedMessages = useRef(false);

    useEffect(() => {
        let interval = setInterval(() => {
            setNow(new Date());
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const [input, setInput] = useState('');

    useEffect(() => {
        setInput('');
    }, [chatId]);

    const queryClient = useQueryClient();
    const authStatus = useAuthStatus();
    const messagesDiv = useRef<HTMLDivElement>(null);
    const apiBaseUrl = useBaseUrl();
    const irisApi = useIrisApi();

    const setReadMark = useCallback(
        (readMark: string | undefined) => {
            if (!readMark) {
                return;
            }

            if (inputFieldInFocus.current) {
                (async () => {
                    await irisApi.post(`/api/v1/chat/${chatId}/read-mark`, {
                        readMark,
                        chatSecret,
                    });

                    await queryClient.invalidateQueries('chat_list');
                })().catch(err => {
                    console.error('Error setting read mark', err);
                    Sentry.captureException(err);
                });
            } else {
                unMarkedMessages.current = true;
            }
        },
        [chatId, chatSecret, irisApi, queryClient]
    );

    const isTyping = useRef(false);
    const sendIsTypingTimeout = useRef<null | NodeJS.Timeout>(null);

    useEffect(() => {
        sendIsTypingTimeout.current = null;
    }, [chatId]);

    useEffect(() => {
        // Send if the user is typing at most every 500ms
        if (input.length == 0 || input === '<br>') {
            isTyping.current = false;
        } else {
            isTyping.current = true;
        }

        async function sendIsTyping() {
            sendIsTypingTimeout.current = null;
            await irisApi.post(`/api/v1/chat/${chatId}/isTyping`, {
                isTyping: isTyping.current,
                chatSecret,
            });
        }

        if (!sendIsTypingTimeout.current) {
            sendIsTypingTimeout.current = setTimeout(() => {
                sendIsTyping().catch(err => console.error(err));
            }, 500);
        }

        // When input is changing, send a "is typing" message
    }, [input, irisApi, chatId, chatSecret]);

    let chatData = useQuery<InternalChatData>(['chat_data', chatId], async () => {
        let res = await irisApi.get<{
            messages?: Array<InternalIrisMessage>;
            files?: Array<InternalIrisFile>;
            users?: Array<User>;
            me: number;
        }>(`/api/v1/chat/internal`);

        let data = res.data;

        let messages = (data.messages || []).map((msg: any) => mapInternalMessage(msg));
        let users = data.users as User[];
        let userRecord: Record<number, User> = {};
        users.forEach(user => {
            userRecord[user.UserID] = user;
        });

        setReadMark(data.messages?.[data.messages.length - 1]?.CreatedAt);

        return {
            messages: messages,
            files: (data.files || []).map(file => mapInternalFiles(file)),
            users: userRecord,
            me: data.me,
        };
    });

    useEffect(() => {
        if (chatData.status != 'success') {
            // Don't start SSE connection until after we have checked that the chat exists
            return;
        }
        let evtSource: EventSource | null = null;
        let closed = false;
        let reconnectTimer: NodeJS.Timeout | null = null;

        function connect() {
            if (evtSource) {
                evtSource.close();
                evtSource = null;
            }
            let url = `${apiBaseUrl}/api/v1/chat/${chatId}/sse`;

            evtSource = new EventSource(url, {
                withCredentials: true,
            });

            evtSource.addEventListener('chat_message', function (event_) {
                (async () => {
                    let event = event_ as MessageEvent;

                    const data: InternalChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        let msg = mapInternalMessage(JSON.parse(event.data));
                        data.messages.push(msg);

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                })().catch(err => {
                    console.error(`Error parsing SSE message`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                });
            });

            evtSource.addEventListener('chat_file', function (event_) {
                (async () => {
                    let event = event_ as MessageEvent;
                    // Cancel any queries so they don't overwrite our update
                    await queryClient.cancelQueries(['chat_data', chatId]);

                    const data: InternalChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);

                    if (data) {
                        let msg = mapInternalFiles(
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            JSON.parse(event.data)
                        );
                        data.files.push(msg);
                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                })().catch(err => {
                    console.error(`Error parsing SSE file`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                });
            });

            evtSource.addEventListener('internal_chat_updated', () => {
                // Generic event that just says "refresh data"
                console.log('chat updated');

                queryClient
                    .invalidateQueries(['chat_data', chatId])
                    .catch(err => console.error(err));
            });

            evtSource.addEventListener('ping', () => {
                irisApi
                    .post(`/api/v1/chat/${chatId}/ping`, { chatSecret })
                    .catch(err => console.error('Error pinging chat', err));
            });

            evtSource.addEventListener('user_is_typing', function (event_) {
                try {
                    let event = event_ as MessageEvent;

                    const data: InternalChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        let msg = JSON.parse(event.data) as { UserID: number; IsTyping: boolean };

                        let value = msg.IsTyping ? new Date().toISOString() : undefined;

                        data.users[msg.UserID].IsTyping = value;

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                } catch (err) {
                    console.error(`Error parsing SSE is typing`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                }
            });

            evtSource.addEventListener('user_read_mark', function (event_) {
                try {
                    let event = event_ as MessageEvent;

                    const data: InternalChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        let msg = JSON.parse(event.data) as { UserID: number; ReadMark: string };

                        let value = msg.ReadMark ? msg.ReadMark : '';

                        data.users[msg.UserID].ReadMark = value;

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                } catch (err) {
                    console.error(`Error parsing SSE is typing`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                }
            });

            evtSource.onerror = error => {
                if (evtSource?.readyState === 2 && !closed) {
                    // Force a reconnect if event source is closed
                    if (reconnectTimer) {
                        clearTimeout(reconnectTimer);
                    }
                    reconnectTimer = setTimeout(() => connect(), 5000);
                }
            };
        }
        connect();

        return () => {
            closed = true;
            if (evtSource) {
                evtSource.close();
            }
            evtSource = null;
        };
    }, [chatData.status, queryClient, irisApi]);

    useLayoutEffect(() => {
        if (messagesDiv.current) {
            messagesDiv.current.scrollTo(0, messagesDiv.current.scrollHeight);
        }
    }, [chatData.data?.messages.length]);

    if (chatData.isLoading || authStatus.isLoading || !chatData.data || !authStatus.data) {
        return <div>Laddar...</div>;
    }

    const chatState = chatData.data;

    // When chatSecret is set, we are a "support seeker". If not, we should be a logged in user
    let me = chatState.me;

    let messages = mergeInternalMessages(chatState);

    let whoIsTyping = Object.values(chatState.users || {})
        .filter(
            user =>
                user.UserID !== me &&
                user.IsTyping &&
                new Date(user.IsTyping).getTime() > now.getTime() - 30000
        )
        .map(u => u.Name);

    let userReadMark: Date | null = null;

    let biggest: Date | null = null;
    Object.entries(chatState.users).forEach(([k, v]) => {
        let d = new Date(v.ReadMark);
        if (biggest == null || d > biggest) {
            biggest = d;
        }
    });
    userReadMark = biggest;

    let lastSeenMessageId: number | null = null;

    if (userReadMark) {
        let filteredList = chatState.messages.filter(
            message => userReadMark! >= new Date(message.CreatedAt)
        );
        lastSeenMessageId = filteredList.length > 0 ? filteredList[filteredList.length - 1].id : -1;
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={css`
                    position: relative;
                    min-height: 100px;
                    width: 100%;
                    height: 100%;
                    background: #fff8ef;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                        /* Absolute positiion to make overflow: scroll work */
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        padding-right: 8px;
                    `}
                    ref={messagesDiv}
                >
                    {messages.map(message => {
                        if (message.type == 'user') {
                            let sanitizedHtml = DOMPurify.sanitize(message.content.trim(), {
                                USE_PROFILES: { html: true },
                            });
                            let user = chatState.users[message.userId];
                            let seen = false;

                            if (message.id === lastSeenMessageId) {
                                seen = true;
                            }

                            return (
                                <div
                                    key={`user_${message.id}`}
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 24px;
                                        ${message.userId === me
                                            ? 'align-self: flex-end'
                                            : 'align-self: flex-start'};
                                    `}
                                >
                                    <div
                                        key={`user_${message.id}`}
                                        css={css`
                                            font-size: 12px;
                                            line-height: 1.5;
                                            ${message.userId === me
                                                ? 'align-self: flex-end'
                                                : 'align-self: flex-start'};
                                        `}
                                    >
                                        {format(new Date(message.CreatedAt), 'HH:mm')}
                                    </div>
                                    <div
                                        css={css`
                                            ${message.userId === me
                                                ? 'align-self: flex-end'
                                                : 'align-self: flex-start'};
                                        `}
                                    >
                                        <div
                                            css={css`
                                                ${message.userId === me
                                                    ? 'align-self: flex-end'
                                                    : 'align-self: flex-start'};
                                                padding: 8px;
                                                background: ${message.userId === me
                                                    ? color.lavendel
                                                    : '#ececec'};
                                                border-radius: 4px;
                                                max-width: 600px;

                                                p:first-of-type {
                                                    margin-top: 0;
                                                }
                                                p:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            `}
                                            key={message.id}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizedHtml,
                                                }}
                                            />
                                        </div>
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                font-weight: bold;
                                                line-height: 1.5;
                                                padding: 0 2px;
                                                ${message.userId === me
                                                    ? 'align-self: flex-end'
                                                    : 'align-self: flex-start'};
                                            `}
                                        >
                                            {seen && message.userId === me ? 'Sett' : ''}
                                        </div>
                                    </div>
                                    {/* Show name, but only if the feature is enabled (Volunteers always see the name) */}
                                    {user && message.userId !== me && (
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                line-height: 1.5;
                                                ${message.userId === me
                                                    ? 'align-self: flex-end'
                                                    : 'align-self: flex-start'};
                                            `}
                                        >
                                            {user.Name}
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        if (message.type === 'file') {
                            let user = chatState.users[message.UserID];
                            return (
                                <a
                                    href={`${apiBaseUrl}/api/v1/chat/internal/file/${message.ID}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    css={css`
                                        ${buttonReset};
                                        text-align: left;
                                        margin-bottom: 24px;
                                        ${message.UserID === me
                                            ? 'align-self: flex-end'
                                            : 'align-self: flex-start'};
                                    `}
                                >
                                    <div
                                        css={css`
                                            display: grid;
                                            border: 1px solid ${color.lightgrey};
                                            padding: 16px;
                                            grid-template-areas:
                                                'name icon'
                                                'filesize icon';
                                            grid-gap: 8px 16px;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                grid-area: name;
                                                font-weight: bold;
                                                font-size: 16px;
                                            `}
                                        >
                                            {shortenFileName(message.EncryptedFilename)}
                                        </div>
                                        <div
                                            css={css`
                                                grid-area: filesize;
                                                font-size: 14px;
                                            `}
                                        >
                                            {humanFileSize(message.Filesize)}
                                        </div>
                                        <div
                                            css={css`
                                                grid-area: icon;
                                            `}
                                        >
                                            <DownloadSimple />
                                        </div>
                                    </div>
                                    {user && message.UserID !== me && (
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                line-height: 1.5;
                                                ${message.UserID === me
                                                    ? 'align-self: flex-end'
                                                    : 'align-self: flex-start'};
                                            `}
                                        >
                                            {user.Name}
                                        </div>
                                    )}
                                </a>
                            );
                        }
                    })}
                </div>
            </div>

            <InternalPellEditor
                whoIsTyping={whoIsTyping}
                input={input}
                setInput={setInput}
                setTextFieldFocus={(value: boolean) => {
                    inputFieldInFocus.current = value;
                    if (unMarkedMessages.current && value) {
                        setReadMark(chatState.messages?.[chatState.messages.length - 1]?.CreatedAt);
                    }
                }}
            />
        </div>
    );
};

function mapInternalMessage(msg: any): InternalIrisMessage {
    return {
        userId: msg.UserID,
        CreatedAt: msg.CreatedAt,
        content: msg.EncryptedMessage.String,
        id: msg.ID,
    };
}

function mapInternalFiles(file: any): InternalIrisFile {
    return {
        ID: file.ID,
        CreatedAt: file.CreatedAt,
        EncryptedFilename: '',
        FilenameNonce: file.Filenamenonce,
        ContentNonce: file.ContentNonce,
        UserID: file.UserID,
        ContentType: file.ContentType,
        Filesize: file.Filesize,
    };
}

function mergeInternalMessages(chatData: InternalChatData): MergedInternalMessage[] {
    let messagesMerged: MergedInternalMessage[] = chatData.messages.map(m => ({
        type: 'user',
        ...m,
    }));
    let filesMerged: MergedInternalMessage[] = chatData.files.map(m => ({ type: 'file', ...m }));

    let merged = [...messagesMerged, ...filesMerged];
    merged.sort((a, b) => a.CreatedAt.localeCompare(b.CreatedAt));

    return merged;
}

type MergedInternalMessage =
    | ({ type: 'user' } & InternalIrisMessage)
    | ({ type: 'file' } & InternalIrisFile);

type InternalChatData = {
    messages: InternalIrisMessage[];
    me: number;
    files: InternalIrisFile[];
    users: Record<number, User>;
};

type User = {
    UserID: number;
    Name: string;
    IsTyping?: string;
    ReadMark: string;
};

type InternalIrisMessage = {
    CreatedAt: string;
    id: number;
    content: string;
    userId: number;
};

type InternalIrisFile = {
    ID: number;
    CreatedAt: string;
    EncryptedFilename: string;
    ContentNonce: string;
    FilenameNonce: string;
    ContentType: string;
    Filesize: number;
    UserID: number;
};

function shortenFileName(filename: string): string {
    if (filename.length < 25) {
        return filename;
    }

    let lastPart = filename.substring(filename.length - 5);
    let firstPart = filename.substring(0, 15);

    return `${firstPart}...${lastPart}`;
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}
