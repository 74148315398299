import { css } from '@emotion/react';
import { Link, LinkGetProps } from '@reach/router';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { use100vh } from 'react-div-100vh';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import Helmet from 'react-helmet';
import { useAuthStatus, useFeatureToggles, useIrisApi } from '../../lib/api';
import { useGlobalEvents } from '../../lib/global_events';
import { useQueueStatus } from '../../lib/use-queue-status';
import { buttonReset, color } from '../../styles';
import { useMyChats } from '../../lib/use-my-chats';
import useSound from 'use-sound';
import { useInternalChat } from '../../lib/use-internal-chat';

type QueueSettingsData = {
    CreatedAt: string;
    QueueOpen: boolean;
    MaxQueueLength: number;
};

export const LoggedInLayout: FC<PropsWithChildren<{ fullSize?: boolean }>> = ({
    children,
    fullSize,
}) => {
    const irisApi = useIrisApi();
    const authStatus = useAuthStatus();
    const height = use100vh();
    const queryClient = useQueryClient();
    const myChats = useMyChats();
    const prevUnreadMessages = useRef<number>(0);
    const prevUnreadInternalMessages = useRef<number>(0);
    const featureToggles = useFeatureToggles();
    const internalChat = featureToggles.display_internal_chat
        ? useInternalChat()
        : { data: undefined };

    const iris_chat = require('../../assets/sounds/iris_chat.mp3');
    const [playNewChatSound] = useSound(iris_chat);

    const iris_chat_internal = require('../../assets/sounds/iris_internal_msg.mp3');
    const [playNewInternalChatSound] = useSound(iris_chat_internal);

    const [playOldInternalChatSound] = useSound(iris_chat_internal);

    const numberOfUnreadMessages = myChats.data
        ? myChats.data.map(chat => chat.NumUnreadMessages).reduce((a, b) => a + b, 0)
        : 0;

    const numberOfUnreadInternalMessages = internalChat.data
        ? internalChat.data.NumUnreadMessages
        : 0;

    useEffect(() => {
        prevUnreadMessages.current = numberOfUnreadMessages;
        prevUnreadInternalMessages.current = numberOfUnreadInternalMessages;
    }, []);

    useEffect(() => {
        if (myChats.data) {
            if (
                featureToggles.play_new_chat_sound &&
                numberOfUnreadMessages > prevUnreadMessages.current
            ) {
                playNewChatSound();
            }

            prevUnreadMessages.current = numberOfUnreadMessages;
        }
    }, [myChats.data]);

    useEffect(() => {
        if (featureToggles.display_internal_chat && internalChat.data) {
            if (
                featureToggles.play_internal_chat_sound &&
                numberOfUnreadInternalMessages > prevUnreadInternalMessages.current
            ) {
                if (featureToggles.different_sounds) {
                    playNewInternalChatSound();
                } else {
                    playOldInternalChatSound();
                }
            }
            prevUnreadInternalMessages.current = numberOfUnreadInternalMessages;
        }
    }, [internalChat.data]);

    const logout = useMutation(
        async () => {
            await irisApi.post('/api/v1/auth/logout');
            await queryClient.invalidateQueries('auth_status');
        },
        { retry: 3 }
    );

    const queueSettings = useQuery('queue_settings', async () => {
        let res = await irisApi.get<QueueSettingsData>('/api/v1/queue-settings');
        return res.data;
    });

    const toggleQueueOpen = useMutation(async () => {
        let newIsOpen = !queueSettings.data?.QueueOpen;
        let data = queryClient.getQueryData<QueueSettingsData>('queue_settings');
        if (data) {
            data.QueueOpen = newIsOpen;
            queryClient.setQueryData('queue_settings', data);
            await irisApi.put('/api/v1/queue-settings', { ...data, QueueOpen: newIsOpen });
        }

        await queryClient.invalidateQueries('queue_settings');
    });

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('queue_settings').catch(err => console.error(err));
    });

    return (
        <div
            css={css`
                display: grid;
                grid-template-areas:
                    'header_left header_right'
                    'line line'
                    'main main';
                grid-template-rows: auto auto 1fr;
                padding: 24px;
                font-size: 20px;
                ${fullSize
                    ? css`
                          height: ${height ? `${height}px` : '100vh'};
                      `
                    : ''};
            `}
        >
            <div
                css={css`
                    grid-area: header_left;
                    ${menuStyle};
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Link getProps={isActive} to="/frivillig/queue">
                        Kö
                    </Link>
                    <QueueLength />
                </div>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Link getProps={isActive} to="/frivillig/chat">
                        Mina chattar
                    </Link>
                    <NumberNotification>{numberOfUnreadMessages}</NumberNotification>
                </div>
                {featureToggles.display_internal_chat && (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Link getProps={isActive} to={'/frivillig/internal-chat'}>
                            Intern chatt
                        </Link>
                        <NumberNotification>{numberOfUnreadInternalMessages}</NumberNotification>
                    </div>
                )}

                {authStatus.data?.user?.role == 'admin' ? (
                    <Link getProps={isActive} to="/frivillig/archive">
                        Arkiv
                    </Link>
                ) : null}
                {authStatus.data?.user?.role == 'admin' ? (
                    <Link getProps={isActive} to="/frivillig/stats">
                        Statistik
                    </Link>
                ) : null}
            </div>
            <div
                css={css`
                    grid-area: header_right;
                    justify-self: end;
                    ${menuStyle};
                `}
            >
                <Link getProps={isActive} to="/frivillig/profile">
                    Min profil
                </Link>

                <Link getProps={isActive} to="/frivillig/settings/queue">
                    Administration
                </Link>

                <button onClick={() => logout.mutate()}>Logga ut</button>
                <button
                    onClick={() => {
                        let warning = '';
                        let newIsOpen = !queueSettings.data?.QueueOpen;
                        if (newIsOpen) {
                            warning = 'Nu öppnar du chatten för alla användare. Vill du fortsätta?';
                        } else {
                            warning =
                                'Nu stänger du chatten för detta passet. Inga nya chattar kan komma in och befintliga chattar kommer att stängas. Vill du fortsätta?';
                        }
                        if (window.confirm(warning)) {
                            toggleQueueOpen.mutate();
                        }
                    }}
                >
                    <QueueStatus status={queueSettings} />
                </button>
            </div>

            <div
                css={css`
                    grid-area: main;
                `}
            >
                {children}
            </div>
            <div
                css={css`
                    grid-area: line;
                    border-bottom: 1px solid ${color.black};
                    margin-top: 16px;
                    margin-bottom: 24px;
                `}
            />
        </div>
    );
};

const QueueStatus: FC<{
    status: UseQueryResult<QueueSettingsData>;
}> = ({ status }) => {
    if (status.isLoading) {
        return <>........</>;
    }

    if (status.data?.QueueOpen) {
        return <>Stäng chatt</>;
    } else {
        return <>Öppna chatt</>;
    }
};

const menuStyle = css`
    display: flex;
    gap: 24px;

    button {
        ${buttonReset};
        :hover {
            text-decoration: underline;
        }
    }

    a {
        :hover {
            text-decoration: underline;
        }

        &[data-active='true'] {
            text-decoration: underline;
        }
    }
`;

const isActive = ({ isPartiallyCurrent }: LinkGetProps) => {
    return isPartiallyCurrent ? { 'data-active': 'true' } : {};
};

const QueueLength: FC = () => {
    const queueLength = useQueueStatus();
    const featureToggles = useFeatureToggles();
    const prevQueueLenght = useRef<number>(0);

    const iris_queue = require('../../assets/sounds/iris_queue.mp3');
    const [playQueueSound] = useSound(iris_queue);

    const iris_queue_old = require('../../assets/sounds/iris_queue_old.mp3');
    const [playOldQueueSound] = useSound(iris_queue_old);

    useEffect(() => {
        if (queueLength.data) {
            prevQueueLenght.current = queueLength.data.queueLength;
        }
    }, []);

    useEffect(() => {
        if (queueLength.data) {
            const newQueueLength = queueLength.data.queueLength;
            if (featureToggles.play_new_queue_sound && newQueueLength > prevQueueLenght.current) {
                if (featureToggles.different_sounds) {
                    playQueueSound();
                } else {
                    playOldQueueSound();
                }
            }
            prevQueueLenght.current = newQueueLength;
        } else {
            prevQueueLenght.current = 0;
        }
    }, [queueLength.data]);

    return (
        <NumberNotification>
            <Helmet>
                {queueLength.error ? (
                    <title>err</title>
                ) : queueLength.isLoading ? (
                    <title>...</title>
                ) : (
                    <title>{`${queueLength.data.queueLength} i kø`}</title>
                )}
            </Helmet>{' '}
            {queueLength.error ? 'err' : queueLength.isLoading ? '.' : queueLength.data.queueLength}
        </NumberNotification>
    );
};

const NumberNotification: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div
            css={css`
                font-family: monospace;
                margin-left: 8px;
                border-radius: 100%;
                height: 24px;
                width: 24px;
                display: flex;
                line-height: 16px;
                font-size: 16px;
                font-weight: bold;
                padding-top: 0px;
                justify-content: center;
                align-items: center;
                background: ${color.lavendel};
            `}
        >
            {children}
        </div>
    );
};
