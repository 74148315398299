import { CryptographyKey, SodiumPlus, X25519SecretKey } from 'sodium-plus';

export async function getPrivateKeyPair(): Promise<CryptographyKey> {
    let sodium = await SodiumPlus.auto();

    let cached;
    try {
        cached = sessionStorage.getItem('iris-private-key');
    } catch (err) {
        cached = null;
    }

    if (cached) {
        let privateKey = X25519SecretKey.from(cached, 'base64');
        let publicKey = await sodium.crypto_box_publickey_from_secretkey(privateKey);
        // There is a bug in the types, this has the wrong name
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let keypair = await (sodium as any).crypto_box_keypair_from_secretkey_and_publickey(
            privateKey,
            publicKey
        );

        return keypair as CryptographyKey;
    }

    let keypair = await sodium.crypto_box_keypair();
    let secret = await sodium.crypto_box_secretkey(keypair);

    let secretBase64 = secret.toString('base64');

    try {
        sessionStorage.setItem('iris-private-key', secretBase64);
    } catch (err) {
        console.warn('Could not save private key. Reloading the page will loose the session');
    }

    return keypair;
}
