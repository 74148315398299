import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { FC, useEffect, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { Form, Field, Buttons, Button } from '../../components/Form';
import { useAuthStatus, useIrisApi } from '../../lib/api';
import { s } from '../../styles';
import { SettingsLayout } from './SettingsLayout';

export const QueueSettings: FC<RouteComponentProps> = () => {
    return (
        <SettingsLayout>
            <ChangeSettings />
        </SettingsLayout>
    );
};

type QueueSettingsData = {
    CreatedAt: string;
    QueueOpen: boolean;
    MaxQueueLength: number;
};

const ChangeSettings = () => {
    const irisApi = useIrisApi();

    const queueSettings = useQuery('queue_settings_page', async () => {
        let res = await irisApi.get<QueueSettingsData>('/api/v1/queue-settings');
        return res.data;
    });

    let [maxQueueLength, setMaxQueueLength] = useState('');

    useEffect(() => {
        if (!queueSettings.isLoading) {
            setMaxQueueLength(queueSettings.data!.MaxQueueLength.toString());
        }
    }, [queueSettings.isLoading, queueSettings.data?.MaxQueueLength, queueSettings.data]);

    const queryClient = useQueryClient();

    let updateQueueSettings = useMutation(
        async () => {
            await irisApi.put('/api/v1/queue-settings', {
                ...queueSettings.data,
                MaxQueueLength: parseInt(maxQueueLength, 10),
            });
            await queryClient.invalidateQueries('queue_settings_page');
            await queryClient.invalidateQueries('queue_settings');
        },
        { useErrorBoundary: false }
    );

    let queueLengthError = !/^\d+$/.test(maxQueueLength) && 'Kölängd måste vara ett nummer';

    const hasErrors = !!queueLengthError;

    return (
        <section
            css={css`
                max-width: 350px;
                margin-bottom: ${s(5)};
                h1 {
                    font-size: 20px;
                }
            `}
        >
            <Form
                error={updateQueueSettings.error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        updateQueueSettings.mutate();
                        return true;
                    }
                }}
            >
                <Field label="Längsta tillåtna kölängd" error={queueLengthError}>
                    <input
                        placeholder="0"
                        value={maxQueueLength}
                        autoComplete="off"
                        onChange={ev => setMaxQueueLength(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={updateQueueSettings.isLoading}>
                        {updateQueueSettings.isLoading ? 'Laddar' : 'Spara'}
                    </Button>
                </Buttons>
            </Form>
        </section>
    );
};
