import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { Attribute, useAttributes, useFeatureToggles, useTextMapper } from '../lib/api';
import { useQueueStatus } from '../lib/use-queue-status';
import { color, lavendelButton, button, s } from '../styles';

export const StartChat: FC = () => {
    const queueStatus = useQueueStatus();
    const attributes = useAttributes();
    const featureToggles = useFeatureToggles();

    const [selectedAttributes, setSelectedAttributes] = useState<Record<string, string>>({});
    const [textArea, setTextArea] = useState<string>('');

    if (queueStatus.isLoading || attributes.isLoading || !queueStatus.data || !attributes.data) {
        return (
            <div
                css={css`
                    padding: 16px;
                `}
            >
                Laddar...
            </div>
        );
    }

    const showTextArea = featureToggles.show_textarea_help_request;
    const selectedTextArea = showTextArea ? textArea !== '' : true;
    const hasSelectedAllAttributes =
        attributes.data.filter(attr => !selectedAttributes[attr.Key]).length === 0 &&
        selectedTextArea;

    const extra_msg = () => {
        if (featureToggles.show_queue_length) {
            let attributeInfo = '';
            const userAvailAttributes = attributes.data.filter(val => val.UserAvailable);

            if (userAvailAttributes.length === 1) {
                const attribute = userAvailAttributes[0];

                const selectedAttribute = selectedAttributes[attribute.Key];

                let valueData = attribute.Values.find(v => v.value == selectedAttribute);

                if (valueData) {
                    attributeInfo = `Idag kan ${valueData.numVolunteers} hjälpa dig med ${selectedAttribute}`;
                }
            }

            const firstLine = attributeInfo
                ? attributeInfo
                : 'Idag är vi ' + queueStatus.data.numVolunteers + ' läxhjälpare';

            return (
                `\n${firstLine}\n` +
                'Det är just nu ' +
                queueStatus.data.queueLength +
                (queueStatus.data.queueLength == 1 ? ' elev' : ' elever') +
                ' i kö'
            );
        }
        return '';
    };

    let message;
    {
        if (!queueStatus.data.queueOpen) {
            message = <Message k="queue_is_closed" align="left" color="white" />;
        } else if (queueStatus.data.queueIsMaxLength) {
            message = <Message k="queue_is_full" align="left" />;
        } else {
            message = <Message k="queue_is_open" align="left" extra_msg={extra_msg()} />;
        }
    }
    const showRedBubble = !queueStatus.data.queueOpen;

    return (
        <div
            css={css`
                height: 100%;
                padding: 40px 16px;
                display: grid;
                grid-template-areas: 'left' 'right';
                grid-template-columns: 1fr;
                justify-content: center;
                grid-gap: 0px;

                @media (min-width: 600px) {
                    padding: 40px 16px;
                    grid-template-areas: 'left right';
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 60px;
                }
            `}
        >
            <div
                css={css`
                    grid-area: left;
                    align-self: center;
                `}
            >
                <div
                    css={css`
                        position: relative;
                        background-color: ${showRedBubble ? '#E3000B' : 'none'};
                        border-radius: 6px;
                        padding: ${showRedBubble ? '25px' : '20px 0px'};
                    `}
                >
                    {message}
                </div>
                {showRedBubble && (
                    <>
                        <svg
                            css={css`
                                margin: -25px 0px 0px 25px;
                                z-index: -1;
                            `}
                            width="71"
                            height="64"
                            viewBox="0 0 71 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 57.2774V6.5C0 3.18629 2.68629 0.5 6 0.5H64.0313C69.5715 0.5 72.1518 7.36722 67.9823 11.0155L9.95103 61.7929C6.07153 65.1874 0 62.4323 0 57.2774Z"
                                fill="#E3000B"
                            />
                        </svg>
                    </>
                )}
            </div>
            <div
                css={css`
                    grid-area: right;
                    justify-self: center;
                    align-self: center;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        @media (min-width: 600px) {
                            display: grid;
                            grid-template-areas: 'venstre hoyre';
                            grid-template-columns: 1fr ${attributes.data.length == 1
                                    ? 'auto'
                                    : '1fr'};
                            column-gap: ${attributes.data.length == 1 ? '0px' : '20px'};
                            align-items: stretch;
                        }
                    `}
                >
                    {attributes.data
                        .sort((attr, attr2) => attr.ID - attr2.ID)
                        .map((attr, idx) => (
                            <SetAttribute
                                key={attr.ID}
                                attribute={attr}
                                idx={idx}
                                total={attributes.data.length}
                                selectedAttributes={selectedAttributes}
                                setAttribute={(key: string, val: string) => {
                                    setSelectedAttributes(before => {
                                        return {
                                            ...before,
                                            [key]: val,
                                        };
                                    });
                                }}
                            />
                        ))}
                    {showTextArea && attributes.data.length > 0 && (
                        <label
                            css={css`
                                display: block;
                                color: ${!queueStatus.data.queueOpen ||
                                queueStatus.data.queueIsMaxLength
                                    ? color.lightgrey
                                    : '#000'};
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 24px;
                                grid-column-start: venstre;
                                grid-column-end: hoyre;

                                textarea {
                                    font-size: 18px;
                                    display: block;
                                    padding: 8px 12px;
                                    width: 100%;
                                    border: 1px solid ${color.lightgrey};
                                    border-radius: 3px;
                                    resize: none;
                                }

                                textarea::placeholder {
                                    color: #000;
                                    opacity: 0.5;
                                }

                                textarea:disabled {
                                    border: 1px solid ${color.lightgrey};
                                    background-color: white;
                                    color: ${color.lightgrey};
                                }
                            `}
                        >
                            Vad behöver du hjälp med?
                            <span
                                css={css`
                                    color: ${color.negative};
                                `}
                            >
                                {' '}
                                *
                            </span>
                            <textarea
                                placeholder="Skriv här..."
                                css={css`
                                    margin-bottom: 20px;
                                `}
                                disabled={
                                    !queueStatus.data.queueOpen || queueStatus.data.queueIsMaxLength
                                }
                                onChange={ev => {
                                    setTextArea(ev.target.value);
                                    setSelectedAttributes(before => {
                                        return {
                                            ...before,
                                            ['Meddelande']: ev.target.value,
                                        };
                                    });
                                }}
                            />
                        </label>
                    )}
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: left;
                            flex-direction: column;
                            grid-gap: 24px;
                            grid-column-start: venstre;
                            grid-column-end: hoyre;

                            @media (min-width: 600px) {
                                grid-gap: 10px;
                                flex-direction: row;
                                align-items: ${attributes.data.length == 1 ? 'end' : 'center'};
                                grid-column-start: ${attributes.data.length == 1
                                    ? 'hoyre'
                                    : 'venstre'};
                                grid-column-end: hoyre;
                            }
                            a {
                                font-size: 18px;
                                text-align: center;
                                text-weight: 100;

                                ${button};

                                padding: 10px 16px;
                                width: ${attributes.data.length == 1 ? '100%' : '137.5px'};

                                ${(!queueStatus.data.queueOpen ||
                                    queueStatus.data.queueIsMaxLength) &&
                                css`
                                    background-color: ${color.lighterGrey};
                                    pointer-events: none;
                                `}

                                @media (min-width: 600px) {
                                    width: auto;
                                }
                            }
                        `}
                    >
                        <div
                            css={css`
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 27px;
                                color: ${!queueStatus.data.queueOpen ||
                                queueStatus.data.queueIsMaxLength
                                    ? color.lightgrey
                                    : '#000'};
                            `}
                        >
                            {featureToggles.videochat ? 'Fortsätta med' : ''}
                        </div>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: ${featureToggles.videochat
                                    ? 'space-between'
                                    : 'flex-end'};
                                flex-direction: row;
                                grid-gap: 10px;
                                grid-column-start: venstre;
                                grid-column-end: hoyre;
                                width: ${featureToggles.videochat ? 'auto' : '100%'};
                            `}
                        >
                            <a
                                target="_parent"
                                href={`/setupChat/${encodeURIComponent(
                                    JSON.stringify(selectedAttributes)
                                )}`}
                                onClick={ev => {
                                    if (
                                        !hasSelectedAllAttributes ||
                                        !queueStatus.data.queueOpen ||
                                        queueStatus.data.queueIsMaxLength
                                    ) {
                                        ev.preventDefault();
                                    }
                                }}
                            >
                                {featureToggles.videochat ? 'Chatt' : 'Gå till chatt'}
                            </a>
                            {featureToggles.videochat && (
                                <>
                                    <p
                                        css={css`
                                            color: ${!queueStatus.data.queueOpen ||
                                            queueStatus.data.queueIsMaxLength
                                                ? color.lightgrey
                                                : '#000'};
                                        `}
                                    >
                                        eller
                                    </p>
                                    <a
                                        target="_parent"
                                        href={`/setupChat/${encodeURIComponent(
                                            JSON.stringify(selectedAttributes)
                                        )}/?video=true`}
                                        onClick={ev => {
                                            if (
                                                !hasSelectedAllAttributes ||
                                                !queueStatus.data.queueOpen ||
                                                queueStatus.data.queueIsMaxLength
                                            ) {
                                                ev.preventDefault();
                                            }
                                        }}
                                    >
                                        Videochatt
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SetAttribute: FC<{
    attribute: Attribute;
    selectedAttributes: Record<string, string>;
    idx: number;
    total: number;
    setAttribute: (key: string, val: string) => void;
}> = ({ attribute, selectedAttributes, idx, total, setAttribute }) => {
    const featureToggles = useFeatureToggles();
    let selectedAttribute = selectedAttributes[attribute.Key];
    let valueData = attribute.Values.find(v => v.value == selectedAttribute);
    const queueStatus = useQueueStatus();

    return (
        <div
            css={css`
                grid-column-start: ${idx == 1 ? 'hoyre' : 'venstre'};
                grid-column-end: ${idx == 0 ? 'venstre' : 'hoyre'};
                ${total == 1 || 'margin-bottom: 24px;'}
            `}
        >
            <label
                css={css`
                    display: grid;
                    align-items: center;
                    gap: 2px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: ${!queueStatus?.data?.queueOpen || queueStatus.data.queueIsMaxLength
                        ? color.lightgrey
                        : '#000'};

                    select {
                        font-size: 18px;
                        line-height: 24px;
                        display: block;
                        width: 100%;
                        border: 1px solid ${color.lightgrey};
                        border-radius: 3px;
                        padding: ${s(1)} ${s(1)};
                        height: 43px;
                    }

                    select:invalid {
                        color: ${color.lightgrey};
                    }

                    select:disabled {
                        border: 1px solid ${color.lightgrey};
                        opacity: 1;
                        background: white;
                        color: ${color.lightgrey};
                    }
                `}
            >
                <div>
                    {attribute.Key}
                    <span
                        css={css`
                            color: ${color.negative};
                        `}
                    >
                        {' '}
                        *
                    </span>
                </div>
                <select
                    css={css`
                        width: 100%;

                        option:focus {
                            border: #000;
                        }
                    `}
                    value={selectedAttribute}
                    onChange={ev => setAttribute(attribute.Key, ev.target.value)}
                    disabled={!queueStatus?.data?.queueOpen || queueStatus.data.queueIsMaxLength}
                    required
                >
                    <option value="" disabled selected hidden>
                        {attribute.Key === 'Kommun'
                            ? 'I vilken kommun bor du? '
                            : `Välj ${attribute.Key.toLowerCase()}`}
                    </option>
                    {(attribute.Key === 'Kommun'
                        ? attribute.Values.sort((a, b) => a.value.localeCompare(b.value))
                        : attribute.Values
                    )
                        .filter(val => val.userAvailable)
                        .map(val => (
                            <option key={val.id} value={val.value}>
                                {val.value}
                            </option>
                        ))}
                </select>
            </label>
        </div>
    );
};

const Message: FC<{ k: string; align?: string; color?: string; extra_msg?: string }> = ({
    k: key,
    align = 'center',
    color = 'black',
    extra_msg = '',
}) => {
    const { t } = useTextMapper();
    const message = t(key) + extra_msg;
    const parts = message.split('\n').filter(a => a !== '');

    return (
        <div
            css={css`
                text-align: ${align};
                font-size: 20px;
                line-height: 28px;
                height: 100%;
                color: ${color};
                ${align === 'center' &&
                css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}

                p {
                    margin-top: 0;
                    margin-bottom: 8px;
                }

                p:first-of-type {
                    line-height: 38px;
                    font-size: 30px;
                    font-weight: 900;
                }

                p:last-of-type {
                    margin-bottom: 5px;
                }

                @media (min-width: 600px) {
                    padding: 16px 0px;
                    font-size: 24px;
                    line-height: 31px;

                    p:first-of-type {
                        line-height: 47px;
                        font-size: 40px;
                        font-weight: 900;
                    }
                }
            `}
        >
            {parts.map((m, idx) => (
                <p key={idx}>{m.trim()}</p>
            ))}
        </div>
    );
};
