import { css } from '@emotion/react';
import 'pell/dist/pell.css';
import { Paperclip, SpinnerGap, TextBolder, TextItalic, TextUnderline } from 'phosphor-react';
import { FC, useEffect, useRef, useState } from 'react';
import Editor from 'react-pell';
import { useMutation } from 'react-query';
import { exec } from 'pell';
import { useFeatureToggles, useIrisApi } from '../lib/api';
import { buttonReset, color } from '../styles';
import { SodiumPlus } from 'sodium-plus';

export const InternalPellEditor: FC<{
    input: string;
    whoIsTyping: string[];
    setInput: (input: string) => void;
    setTextFieldFocus: (value: boolean) => void;
}> = ({ input, setInput, whoIsTyping, setTextFieldFocus }) => {
    const irisApi = useIrisApi();
    const featureToggles = useFeatureToggles();

    const [showPostMessageIsLoading, setShowPostMessageIsLoading] = useState(false);
    const editorRef = useRef<HTMLDivElement>(null);

    const fileUploadRef = useRef<HTMLInputElement>(null);

    let uploadFile = useMutation(
        async (file: {
            content: ArrayBuffer;
            contentType: string;
            fileName: string;
            size: number;
        }) => {
            let sodium = await SodiumPlus.auto();

            let contentNonce = await sodium.randombytes_buf(24);
            let fileNameNonce = await sodium.randombytes_buf(24);

            /*
            let encryptedContent = new Blob([
                await sodium.crypto_secretbox(file.content, contentNonce, encryptionKey),
            ]);
            let encryptedFileName = new Blob([
                await sodium.crypto_secretbox(file.fileName, fileNameNonce, encryptionKey),
            ]);
            */

            let formData = new FormData();

            formData.append('encryptedContent', new Blob([file.content]));
            formData.append('encryptedFileName', new Blob([file.fileName]));
            formData.append('contentNonce', new Blob([contentNonce]));
            formData.append('fileNameNonce', new Blob([fileNameNonce]));
            formData.append('fileSize', file.size.toString());
            formData.append('contentType', file.contentType);

            await irisApi.post(`/api/v1/chat/internal/file`, formData, {
                headers: { 'content-type': 'multipart/form-data' },
            });
        },
        { useErrorBoundary: false }
    );

    let postMessage = useMutation(
        async () => {
            if (input === '') return;
            await irisApi
                .post(`/api/v1/chat/internal/message`, {
                    value: input,
                })
                .catch(err => console.log(err));

            setInput('');
        },
        { retry: 2, useErrorBoundary: false }
    );

    useEffect(() => {
        if (postMessage.isLoading) {
            let timeout = setTimeout(() => setShowPostMessageIsLoading(true), 100);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            setShowPostMessageIsLoading(false);
            const listener = (event: {
                code: string;
                shiftKey: boolean;
                preventDefault: () => void;
            }) => {
                if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
                    event.preventDefault();
                    postMessage.mutate();
                }
            };
            if (editorRef && editorRef.current) {
                const editorReference = editorRef;
                editorReference.current?.addEventListener('keydown', listener);
                return () => {
                    editorReference.current?.removeEventListener('keydown', listener);
                };
            }
        }
    }, [postMessage, postMessage.isLoading]);

    const setMessage = (message: string) => {
        editorRef.current?.focus();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setInput(message);
    };

    return (
        <div
            css={css`
                display: flex;
                align-items: flex-end;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    display: grid;
                    grid-template-areas:
                        'error error'
                        'buttons writing'
                        'editor editor';
                `}
                ref={editorRef}
                onFocus={() => setTextFieldFocus(true)}
                onBlur={() => setTextFieldFocus(false)}
            >
                {postMessage.error ? (
                    <div
                        css={`
                            grid-area: error;
                        `}
                    >
                        Kunde inte skicka meddelandet: {(postMessage.error as Error).toString()}
                    </div>
                ) : null}

                <div
                    css={css`
                        display: flex;
                        grid-area: buttons;
                        gap: 8px;
                        button {
                            border: 0;
                            border-radius: 0.25rem;
                            font-size: 1rem;
                            line-height: 1.2;
                            white-space: nowrap;
                            text-decoration: none;
                            margin: 8px 0;
                            padding: 4px 0 0 0;
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    `}
                >
                    <button
                        title="Bold"
                        onClick={() => {
                            exec('bold');
                        }}
                    >
                        <div>
                            <TextBolder />
                        </div>
                    </button>
                    <button
                        title="Underline"
                        onClick={() => {
                            exec('underline');
                        }}
                    >
                        <div>
                            <TextUnderline />
                        </div>
                    </button>
                    <button
                        title="Italic"
                        onClick={() => {
                            exec('italic');
                        }}
                    >
                        <div>
                            <TextItalic />
                        </div>
                    </button>
                    {featureToggles.upload_files && (
                        <button
                            title="Upload file"
                            onClick={ev => {
                                ev.preventDefault();
                                if (fileUploadRef.current) {
                                    fileUploadRef.current.click();
                                }
                            }}
                        >
                            <div>
                                <Paperclip />
                            </div>
                        </button>
                    )}
                </div>
                <div
                    css={css`
                        grid-area: writing;
                        align-self: flex-start;
                        margin-top: 16px;
                        font-style: italic;
                        height: 24px;
                        text-align: right;
                    `}
                >
                    {whoIsTyping.length > 0 && `${whoIsTyping.join(' och ')} skriver...`}
                </div>
                <Editor
                    defaultContent={input}
                    actions={[]}
                    buttonClass="editor-button"
                    contentClass="editor-content"
                    containerClass="editor-container"
                    onChange={setMessage}
                />
            </div>
            <form
                css={css`
                    display: grid;
                    grid-template-columns: 1fr auto;
                `}
                onSubmit={ev => {
                    ev.preventDefault();
                    postMessage.mutate();
                }}
            >
                <button
                    type="submit"
                    disabled={input === '' || postMessage.isLoading}
                    css={css`
                        ${buttonReset};
                        color: ${color.lila};
                        padding: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        :disabled {
                            color: ${color.lightgrey};
                        }
                    `}
                >
                    {showPostMessageIsLoading ? <SpinningLoader /> : <SendIcon />}
                </button>
            </form>
            <input
                type="file"
                // multiple
                ref={fileUploadRef}
                css={css`
                    opacity: 0;
                    height: 0px;
                    width: 0px;
                    position: absolute;
                `}
                onChange={ev => {
                    const target = ev.target;
                    if (!ev.target.files || ev.target.files.length === 0) return;

                    let file = ev.target.files[0];

                    const reader = new FileReader();
                    reader.onload = e => {
                        if (e.target?.result) {
                            let result = e.target.result;

                            uploadFile.mutate({
                                content: result as ArrayBuffer,
                                contentType: file.type,
                                fileName: file.name,
                                size: file.size,
                            });

                            target.value = '';
                        }
                    };
                    reader.onerror = err => {
                        console.error(err);
                    };
                    reader.readAsArrayBuffer(file);

                    // TODO: Encrypt
                    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
                    // let formData = new FormData();
                    // formData.append('userfile', ev.target.files[0]);
                    // if (chatSecret) {
                    //     formData.append('chatSecret', chatSecret);
                    // }

                    // uploadFile.mutate(ev.target.files[0]);

                    // Array.from(ev.target.files || []).forEach(file => {
                    //     const target = ev.target;

                    //     formData.append('userfile_', file);

                    // if (!file.type.startsWith('image/')) {
                    //     alert('We only support images');
                    //     return;
                    // }
                    // const reader = new FileReader();
                    // reader.onload = e => {
                    //     if (e.target?.result) {
                    //         let result = e.target.result;
                    //         setFiles(old => [...old, result as string]);
                    //         target.value = '';
                    //     }
                    // };
                    // reader.onerror = err => {
                    //     console.error(err);
                    // };
                    // reader.readAsDataURL(file);
                    // });
                }}
            />
        </div>
    );
};

const SpinningLoader = () => (
    <SpinnerGap size={30}>
        <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="3s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"
        ></animateTransform>
    </SpinnerGap>
);

function SendIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
            <path d="M30 15L0 30 5.5 15 0 0z" className="arrow"></path>
        </svg>
    );
}
