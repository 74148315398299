import axios, { AxiosError, AxiosInstance } from 'axios';
import React, { FC, useContext, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useGlobalEvents } from './global_events';
import * as Sentry from '@sentry/react';

export function getApiHostForBackendToBackend() {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:3005';
    } else {
        return 'http://iris-backend:3005';
    }
}

export type TenantData = {
    ApiBaseUrl: string;
    ApiDomain: string;
    Title: string;
    Features: string[];
    LogoImage?: string;
    RedirectRoot?: string;
};

export function createIrisApi({
    tenant,
    xHost,
}: {
    tenant: TenantData;
    xHost?: string;
}): AxiosInstance {
    let headers: Record<string, string> = {};
    if (xHost) {
        headers['x-host'] = xHost;
    }

    return axios.create({
        baseURL: tenant.ApiBaseUrl,
        withCredentials: true,
        timeout: typeof window === 'undefined' ? 2000 : 30000,
        headers,
    });
}

const TenantContext = React.createContext<{
    axiosInstance: AxiosInstance;
    tenant: TenantData;
} | null>(null);

export const TenantProvider: FC<
    PropsWithChildren<{
        tenant: TenantData;
    }>
> = ({ children, tenant }) => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const apiHost = tenant.ApiBaseUrl;
            const protocol = apiHost.split('://')[0];
            const baseUrl = apiHost.split('://')[1];

            Sentry.init({
                dsn: `${protocol}://8d89d9897c714d36830cbb44685b6286@${baseUrl}/whoops/4`,
                integrations: [],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.0,
            });
        }
    }, [tenant.ApiBaseUrl]);

    return (
        <TenantContext.Provider value={{ axiosInstance: createIrisApi({ tenant }), tenant }}>
            {children}
        </TenantContext.Provider>
    );
};

export function useIrisApi(): AxiosInstance {
    let value = useContext(TenantContext);

    if (!value) {
        throw new Error('useIrisApi used outside of a TenantProvider');
    }

    return value.axiosInstance;
}

export function useTenantData(): TenantData {
    let value = useContext(TenantContext);

    if (!value) {
        throw new Error('useTenantData used outside of a TenantProvider');
    }

    return value.tenant;
}

export type FeatureToggle = Record<string, boolean>;

export function useFeatureToggles(): FeatureToggle {
    let tenantData = useTenantData();
    const featureToggles: FeatureToggle = {};
    (tenantData.Features || []).forEach(feature => (featureToggles[feature] = true));
    return featureToggles;
}

export function useBaseUrl(): string {
    let value = useContext(TenantContext);

    if (!value) {
        throw new Error('useIrisApi used outside of a TenantProvider');
    }

    return value.tenant.ApiBaseUrl;
}

type AuthStatus = {
    status: 'logged_in' | 'logged_out';
    user?: {
        id: number;
        email: string;
        name: string;
        role: string;
        attributes: Record<string, number[]>;
        timeToExpire: number;
        trackingId: string;
    };
};

export function useAuthStatus() {
    const irisApi = useIrisApi();

    const query = useQuery<AuthStatus, AxiosError>(
        'auth_status',
        async () => {
            let res = await irisApi.get<AuthStatus>('/api/v1/auth/status');
            return res.data;
        },
        {
            staleTime: 120000,
        }
    );
    const queryClient = useQueryClient();

    useEffect(() => {
        if (query.data?.user?.timeToExpire) {
            let timeout = setTimeout(() => {
                queryClient.refetchQueries('auth_status').catch(err => console.error(err));
                // timeToExpire is in ns, divide by 1000 to get ms
            }, query.data?.user?.timeToExpire / 1000000 + 10);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [query.data?.user?.timeToExpire, queryClient]);

    return query;
}

export type Attribute = {
    ID: number;
    Key: string;
    UserAvailable: boolean;
    HideInChat: boolean;
    Values: Array<{
        id: number;
        value: string;
        userAvailable: boolean;
        numVolunteers: number;
        hideInChat: boolean;
    }>;
};

export async function fetchAttributes(irisApi: AxiosInstance) {
    let res = await irisApi.get<Array<Attribute>>('/api/v1/attributes');
    return res.data;
}

export function useAttributes() {
    let irisApi = useIrisApi();
    const queryClient = useQueryClient();

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('attributes').catch(err => console.error(err));
    });

    return useQuery('attributes', async () => fetchAttributes(irisApi));
}

export async function fetchTexts(irisApi: AxiosInstance) {
    let res = await irisApi.get<Array<{ key: string; value: string }>>('/api/v1/texts');
    let data: Record<string, string> = {};
    res.data.map(({ key, value }) => (data[key] = value));
    return data;
}

export function useTexts(): Record<string, string> {
    let irisApi = useIrisApi();
    let res = useQuery('texts', async () => fetchTexts(irisApi));

    const queryClient = useQueryClient();

    useGlobalEvents('texts_updated', () => {
        queryClient.invalidateQueries('texts').catch(err => console.error(err));
    });

    if (res.status !== 'success') {
        return {};
    }

    return res.data;
}

export function useTextMapper(): { t: (key: string) => string } {
    let texts = useTexts();

    return {
        t(key: string) {
            if (texts[key]) {
                return texts[key];
            }
            return key;
        },
    };
}
