import { AxiosError, AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useIrisApi } from './api';
import { useGlobalEvents } from './global_events';

type QueuePositionData = {
    queuePosition: number;
    queueLength: number;
};

type QueuePosition =
    | {
          isLoading: true;
          error: undefined;
          data: undefined;
      }
    | {
          isLoading: false;
          error: AxiosError;
          data: undefined;
      }
    | {
          isLoading: false;
          error: undefined;
          data: QueuePositionData;
      };

export async function fetchQueuePosition(irisApi: AxiosInstance, chatId: string) {
    let res = await irisApi.get<QueuePositionData>(`/api/v1/queue-position/${chatId}`);
    return res.data;
}

export function useQueuePosition(chatId: string): QueuePosition {
    let irisApi = useIrisApi();
    let queryClient = useQueryClient();
    let queueLength = useQuery('queue-position', async () => fetchQueuePosition(irisApi, chatId), {
        refetchInterval: 30000,
    });

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('queue-position').catch(err => console.error(err));
    });

    if (queueLength.error) {
        return { isLoading: false, error: queueLength.error as AxiosError, data: undefined };
    }

    if (queueLength.isLoading || !queueLength.data) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    return { data: queueLength.data, isLoading: false, error: undefined };
}
