export function createMapWithKey<T>(keyFn: (o: T) => string, list: Array<T>): Record<string, T> {
    const result: Record<string, T> = {};
    list.forEach(value => (result[keyFn(value)] = value));

    return result;
}

export function toPlural(txt: string): string {
    let translater: Record<string, string> = {
        Ämne: 'Ämnen',
        Kommun: 'Kommuner',
        Årskurs: 'Årskurser',
    };
    for (let key in translater) {
        translater[key.toLowerCase()] = translater[key].toLowerCase();
    }
    if (txt in translater) {
        return translater[txt];
    }
    return txt;
}

// from: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
export function hasTouchScreen(window: Window): boolean {
    let hasTouchScreen = false;

    const navigator = window.navigator;

    const UA = navigator.userAgent;

    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
        const mQ = matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }

    return hasTouchScreen;
}
