import { css } from '@emotion/react';
import 'pell/dist/pell.css';
import { Paperclip, SpinnerGap, TextBolder, TextItalic, TextUnderline } from 'phosphor-react';
import { FC, useEffect, useRef, useState } from 'react';
import Editor from 'react-pell';
import { useMutation } from 'react-query';
import { CryptographyKey, SodiumPlus } from 'sodium-plus';
import { exec } from 'pell';
import { useFeatureToggles, useIrisApi } from '../lib/api';
import { buttonReset, color } from '../styles';
import EmojiPicker from 'emoji-picker-react';

export const PellEditor: FC<{
    chatId: string;
    encryptionKey: CryptographyKey;
    chatSecret?: string;
    input: string;
    whoIsTyping: string[];
    setInput: (input: string) => void;
    setTextFieldFocus: (value: boolean) => void;
    me: string;
}> = ({
    chatId,
    encryptionKey,
    chatSecret,
    input,
    setInput,
    whoIsTyping,
    me,
    setTextFieldFocus,
}) => {
    const irisApi = useIrisApi();
    const featureToggles = useFeatureToggles();

    const [showPostMessageIsLoading, setShowPostMessageIsLoading] = useState(false);
    const [showEmojiSelector, setShowEmojiSelector] = useState(false);
    const editorRef = useRef<HTMLDivElement>(null);

    const fileUploadRef = useRef<HTMLInputElement>(null);

    const caretPosition = useRef<number>(0);

    let uploadFile = useMutation(
        async (file: { content: string; contentType: string; fileName: string; size: number }) => {
            let sodium = await SodiumPlus.auto();

            let contentNonce = await sodium.randombytes_buf(24);
            let fileNameNonce = await sodium.randombytes_buf(24);

            let encryptedContent = new Blob([
                await sodium.crypto_secretbox(file.content, contentNonce, encryptionKey),
            ]);
            let encryptedFileName = new Blob([
                await sodium.crypto_secretbox(file.fileName, fileNameNonce, encryptionKey),
            ]);

            let formData = new FormData();

            if (chatSecret) {
                formData.append('chatSecret', chatSecret);
            }
            formData.append('encryptedContent', encryptedContent);
            formData.append('encryptedFileName', encryptedFileName);
            formData.append('contentNonce', new Blob([contentNonce]));
            formData.append('fileNameNonce', new Blob([fileNameNonce]));
            formData.append('fileSize', file.size.toString());
            formData.append('contentType', file.contentType);

            await irisApi.post(`/api/v1/chat/${chatId}/file`, formData, {
                headers: { 'content-type': 'multipart/form-data' },
            });
        },
        { useErrorBoundary: false }
    );

    let postMessage = useMutation(
        async () => {
            if (input === '') return;
            let sodium = await SodiumPlus.auto();

            let nonce = await sodium.randombytes_buf(24);

            let encryptedContent = await sodium.crypto_secretbox(
                Buffer.from(input, 'utf-8'),
                nonce,
                encryptionKey
            );

            await irisApi.post(`/api/v1/chat/${chatId}/message`, {
                chatSecret,
                encryptedContent: encryptedContent.toString('base64'),
                nonce: nonce.toString('base64'),
            });
            setInput('');
        },
        // Nonce is required to be unique, so there will never be repeating messages, so retrying is
        // safe
        { retry: 2, useErrorBoundary: false }
    );

    useEffect(() => {
        if (postMessage.isLoading || uploadFile.isLoading) {
            let timeout = setTimeout(() => setShowPostMessageIsLoading(true), 100);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            setShowPostMessageIsLoading(false);
            const listener = (event: {
                code: string;
                shiftKey: boolean;
                preventDefault: () => void;
            }) => {
                if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
                    event.preventDefault();
                    postMessage.mutate();
                    return;
                }

                caretListener();
            };

            const caretListener = () => {
                const _range = document.getSelection()?.getRangeAt(0);

                if (!_range?.collapsed) {
                    caretPosition.current = 0;
                }

                const range = _range?.cloneRange();

                if (!range) {
                    return;
                }

                const contentEl = document.querySelector('.editor-content');

                range.selectNodeContents(contentEl!);
                range.setEnd(_range!.endContainer, _range!.endOffset);

                const caretposition = range.toString().length;

                caretPosition.current = caretposition ?? 0;
            };

            if (editorRef && editorRef.current) {
                const editorReference = editorRef;
                editorReference.current?.addEventListener('keydown', listener);
                editorReference.current?.addEventListener('click', caretListener);
                return () => {
                    editorReference.current?.removeEventListener('keydown', listener);
                    editorReference.current?.removeEventListener('click', caretListener);
                };
            }
        }
    }, [postMessage, postMessage.isLoading, uploadFile, uploadFile.isLoading]);

    const setMessage = (message: string) => {
        editorRef.current?.focus();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setInput(message);
    };

    return (
        <div
            css={css`
                display: flex;
                align-items: flex-end;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    display: grid;
                    grid-template-areas:
                        'error error'
                        'buttons writing'
                        'editor editor';
                `}
                ref={editorRef}
                onFocus={() => setTextFieldFocus(true)}
                onBlur={() => setTextFieldFocus(false)}
            >
                {postMessage.error ? (
                    <div
                        css={`
                            grid-area: error;
                        `}
                    >
                        Kunde inte skicka meddelandet: {(postMessage.error as Error).toString()}
                    </div>
                ) : null}
                {uploadFile.error ? (
                    <div
                        css={`
                            grid-area: error;
                        `}
                    >
                        {(uploadFile.error as Error).toString()}
                    </div>
                ) : null}
                <div
                    css={css`
                        display: flex;
                        grid-area: buttons;
                        gap: 8px;
                        position: relative;
                        button {
                            border: 0;
                            border-radius: 0.25rem;
                            font-size: 1rem;
                            line-height: 1.2;
                            white-space: nowrap;
                            text-decoration: none;
                            margin: 8px 0;
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            div {
                                display: flex;
                                vertical-align: center;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    `}
                >
                    <button
                        title="Bold"
                        onClick={() => {
                            exec('bold');
                        }}
                    >
                        <div>
                            <TextBolder />
                        </div>
                    </button>
                    <button
                        title="Underline"
                        onClick={() => {
                            exec('underline');
                        }}
                    >
                        <div>
                            <TextUnderline />
                        </div>
                    </button>
                    <button
                        title="Italic"
                        onClick={() => {
                            exec('italic');
                        }}
                    >
                        <div>
                            <TextItalic />
                        </div>
                    </button>
                    <button
                        title="Emoji selector"
                        onClick={() => {
                            if (!showEmojiSelector) {
                                setShowEmojiSelector(true);
                            } else {
                                setShowEmojiSelector(false);
                            }
                        }}
                    >
                        <div
                            css={css`
                                font-size: 15px;
                                margin-top: -2px;
                            `}
                        >
                            🙂
                        </div>
                    </button>
                    {showEmojiSelector && (
                        <div
                            css={css`
                                position: absolute;
                                left: 125px;
                                bottom: 51px;
                            `}
                        >
                            <EmojiPicker
                                emojiVersion={'12'} // The latest version supported by Nathalie at RKUF's PC
                                autoFocusSearch={true}
                                onEmojiClick={e => {
                                    /* the "input" prop is only updated if the editor loses focus after
                                       the last change. So in case the user opens the emoji selector then
                                       changes the text somehow and then clicks an emoji without first
                                       clicking elsewhere outside of the editor, we have to get the
                                       text directly from the DOM to be sure we have the most updated
                                       version.
                                    */
                                    const contentEl = document.querySelector('.editor-content');
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-expect-error
                                    const content = contentEl?.innerText as string | undefined;

                                    const message = content || input;

                                    const newMessage =
                                        message.substring(0, caretPosition.current) +
                                        e.emoji +
                                        message.substring(caretPosition.current);

                                    setMessage(newMessage);
                                    setShowEmojiSelector(false);
                                }}
                                previewConfig={{ showPreview: false }}
                            />
                        </div>
                    )}
                    {featureToggles.upload_files && (
                        <button
                            title="Upload file"
                            onClick={ev => {
                                ev.preventDefault();
                                if (fileUploadRef.current) {
                                    fileUploadRef.current.click();
                                }
                            }}
                        >
                            <div>
                                <Paperclip />
                            </div>
                        </button>
                    )}
                </div>
                <div
                    css={css`
                        grid-area: writing;
                        align-self: flex-start;
                        margin-top: 16px;
                        font-style: italic;
                        height: 24px;
                        text-align: right;
                    `}
                >
                    {whoIsTyping.length > 0 &&
                        (featureToggles.show_names
                            ? `${whoIsTyping.join(' och ')} skriver...`
                            : me === 'seeker'
                            ? 'Jourhavande kompis skriver..'
                            : 'Skriver...')}
                </div>
                <Editor
                    defaultContent={input}
                    actions={[]}
                    buttonClass="editor-button"
                    contentClass="editor-content"
                    containerClass="editor-container"
                    onChange={setMessage}
                />
            </div>
            <form
                css={css`
                    display: grid;
                    grid-template-columns: 1fr auto;
                `}
                onSubmit={ev => {
                    ev.preventDefault();
                    postMessage.mutate();
                }}
            >
                <button
                    type="submit"
                    disabled={input === '' || postMessage.isLoading}
                    css={css`
                        ${buttonReset};
                        color: ${color.lila};
                        padding: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        :disabled {
                            color: ${color.lightgrey};
                        }
                    `}
                >
                    {showPostMessageIsLoading ? <SpinningLoader /> : <SendIcon />}
                </button>
            </form>
            <input
                type="file"
                // multiple
                ref={fileUploadRef}
                css={css`
                    opacity: 0;
                    height: 0px;
                    width: 0px;
                    position: absolute;
                `}
                onChange={ev => {
                    const target = ev.target;
                    if (!ev.target.files || ev.target.files.length === 0) return;

                    let file = ev.target.files[0];

                    const reader = new FileReader();
                    reader.onload = e => {
                        if (e.target?.result) {
                            let result = e.target.result;

                            uploadFile.mutate({
                                content: result as string,
                                contentType: file.type,
                                fileName: file.name,
                                size: file.size,
                            });

                            target.value = '';
                        }
                    };
                    reader.onerror = err => {
                        console.error(err);
                    };
                    reader.readAsBinaryString(file);

                    // TODO: Encrypt
                    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
                    // let formData = new FormData();
                    // formData.append('userfile', ev.target.files[0]);
                    // if (chatSecret) {
                    //     formData.append('chatSecret', chatSecret);
                    // }

                    // uploadFile.mutate(ev.target.files[0]);

                    // Array.from(ev.target.files || []).forEach(file => {
                    //     const target = ev.target;

                    //     formData.append('userfile_', file);

                    // if (!file.type.startsWith('image/')) {
                    //     alert('We only support images');
                    //     return;
                    // }
                    // const reader = new FileReader();
                    // reader.onload = e => {
                    //     if (e.target?.result) {
                    //         let result = e.target.result;
                    //         setFiles(old => [...old, result as string]);
                    //         target.value = '';
                    //     }
                    // };
                    // reader.onerror = err => {
                    //     console.error(err);
                    // };
                    // reader.readAsDataURL(file);
                    // });
                }}
            />
        </div>
    );
};

const SpinningLoader = () => (
    <SpinnerGap size={30}>
        <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="3s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"
        ></animateTransform>
    </SpinnerGap>
);

function SendIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
            <path d="M30 15L0 30 5.5 15 0 0z" className="arrow"></path>
        </svg>
    );
}
