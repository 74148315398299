import { css } from '@emotion/react';
import { Link, LinkGetProps, RouteComponentProps } from '@reach/router';
import { Chat, PhoneDisconnect, VideoCamera } from 'phosphor-react';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useAttributes, useFeatureToggles } from '../../lib/api';
import { useMyChats } from '../../lib/use-my-chats';
import { color } from '../../styles';
import { LoggedInLayout } from './LoggedInLayout';
import { Timer } from './Queue';

export const MyChats: FC<PropsWithChildren<RouteComponentProps>> = ({ children }) => {
    return (
        <LoggedInLayout fullSize={true}>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 48px;
                    height: 100%;
                `}
            >
                <ChatList />
                {children}
            </div>
        </LoggedInLayout>
    );
};

const ChatList: FC = () => {
    const chats = useMyChats();
    const attributes = useAttributes();

    const featureToggles = useFeatureToggles();

    if (chats.isLoading || chats.data === undefined || attributes.isLoading || !attributes.data) {
        return <div>Laddar...</div>;
    }

    if (chats.data === null) {
        return <div>Inga aktiva chattar </div>;
    }

    return (
        <div
            css={css`
                height: 100%;
                max-height: 100%;
                position: relative;
                width: 350px;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    overflow-y: scroll;
                `}
            >
                {(chats.data.filter(c => c.ID != 'internal') || [])
                    .sort((a, b) => -a.CreatedAt.localeCompare(b.CreatedAt))
                    .map(chat => {
                        // Filter out keys with HideInChat set to true
                        const hideAttrKeys = attributes.data
                            .filter(val => val.HideInChat)
                            .map(val => val.Key);
                        // Only show attribute data if not in hideAttrKeys
                        const showAttrData = Object.keys(chat.Attributes)
                            .filter(key => !hideAttrKeys.includes(key))
                            .map(key => [key, chat.Attributes[key]]);

                        return (
                            <Link
                                key={chat.ID}
                                getProps={isActive}
                                css={css`
                                    display: grid;
                                    padding: 8px;
                                    gap: 8px;
                                    grid-template-columns: auto auto 40px;
                                    background: #ececec;
                                    border-radius: 3px;
                                    margin-bottom: 8px;

                                    &[data-active='true'] {
                                        background: ${color.lavendel};
                                    }
                                `}
                                to={`/frivillig/chat/${chat.ID}`}
                            >
                                <div
                                    css={css`
                                        grid-column: 1 / span 1;
                                    `}
                                >
                                    {chat.FriendlyName}
                                </div>
                                <div
                                    css={css`
                                        justify-self: end;
                                        grid-column: 2 / span 1;
                                        ${chat.Closed ? 'color: #696969;' : ''}
                                    `}
                                >
                                    <Timer
                                        start={
                                            !chat.ChatUserJoinedAt
                                                ? new Date(chat.CreatedAt)
                                                : new Date(chat.ChatUserJoinedAt)
                                        }
                                        end={
                                            chat.Closed || !chat.UserIsActive
                                                ? new Date(chat.UpdatedAt)
                                                : undefined
                                        }
                                    />
                                </div>
                                {showAttrData.map(([key, val]) => (
                                    <Fragment key={key}>
                                        <div
                                            css={css`
                                                font-weight: bold;
                                                grid-column: 1;
                                            `}
                                        >
                                            {key}
                                        </div>
                                        <div
                                            css={css`
                                                justify-self: end;
                                                grid-column: 2;
                                            `}
                                        >
                                            {val.length > 20 ? `${val.slice(0, 20)}...` : val}
                                        </div>
                                    </Fragment>
                                ))}
                                {chat.NumUnreadMessages > 0 ? (
                                    <div
                                        css={css`
                                            grid-column: 3;
                                            font-family: monospace;
                                            font-size: 16px;
                                            justify-self: end;
                                            background: ${color.lavendel};
                                            width: 24px;
                                            height: 24px;
                                            border-radius: 24px;
                                            /* Cannot use 1 / -1 to span everything because of implicit rows. So we need to calculate the number of rows */
                                            grid-row: 1 / span
                                                ${Object.keys(chat.Attributes).length + 1};
                                            align-self: center;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            [data-active='true'] & {
                                                display: none;
                                            }
                                        `}
                                    >
                                        {chat.NumUnreadMessages}
                                    </div>
                                ) : !chat.UserIsActive || chat.Closed ? (
                                    <div
                                        css={css`
                                            grid-column: 3;
                                            justify-self: end;
                                            /* Cannot use 1 / -1 to span everything because of implicit rows. So we need to calculate the number of rows */
                                            grid-row: 1 / span
                                                ${Object.keys(chat.Attributes).length + 1};
                                            align-self: center;
                                            color: red;
                                        `}
                                        title="Det verkar som att eleven inte är i chatten"
                                    >
                                        <PhoneDisconnect />
                                    </div>
                                ) : (
                                    <div
                                        css={css`
                                            grid-column: 3;
                                            justify-self: end;
                                            /* Cannot use 1 / -1 to span everything because of implicit rows. So we need to calculate the number of rows */
                                            grid-row: 1 / span
                                                ${Object.keys(chat.Attributes).length + 1};
                                            align-self: center;
                                        `}
                                        title={
                                            featureToggles.videochat && chat.VideoID
                                                ? 'Videochatt'
                                                : 'Chatt'
                                        }
                                    >
                                        {/* Adds feature toggle check here because there were reports of this icon popping up in tenant that does not have videochat */}
                                        {featureToggles.videochat && chat.VideoID ? (
                                            <VideoCamera />
                                        ) : (
                                            <Chat />
                                        )}
                                    </div>
                                )}
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};

const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent ? { 'data-active': 'true' } : {};
};
