import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useIrisApi } from '../lib/api';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery } from 'react-query';
import { useParams } from '@reach/router';

type FeedbackData = {
    emoji: number;
    comment?: string;
};

export const FeedbackArchive: FC<{}> = ({}) => {
    const irisApi = useIrisApi();
    let { chatId } = useParams();

    const feedbackEmojis = ['🙁', '😐', '🙂', '😁'];

    const { data } = useQuery(['chat_feedback_archive', chatId], async () => {
        let res = await irisApi.get<FeedbackData>(`/api/v1/chat/${chatId}/feedback`);

        return res.data;
    });

    if (data === undefined || Object.keys(data).length === 0) {
        return <div />;
    }

    return (
        <div
            css={css`
                width: 250px;
                max-height: 300;
                margin: 0 auto;
                padding: 16px;
                display: flex;
                flex-direction: column;
                border-size: 1px;
                border-style: solid;
                margin-bottom: 8px;
                gap: 4px;
                border-color: #e2d4f5;
                box-shadow: 0 0 0.3rem 0.1rem #e2d4f5;
            `}
        >
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    margin-top: 4px;
                    font-weight: bold;
                    justify-content: center;
                    font-size: 14px;
                `}
            >
                Hur upplevde du chatten?
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    margin: 8px 0;
                `}
            >
                <div
                    css={css`
                        background: none;
                        color: 'inherit';
                        border: none;
                        font-size: 36px;
                        padding: 0 6px;
                        align-self: center;
                    `}
                >
                    {feedbackEmojis[data?.emoji]}
                </div>
                {data.comment !== undefined && data.comment !== '' ? (
                    <>
                        <div
                            css={css`
                                display: flex;
                                width: 100%;
                                margin-top: 16px;
                                justify-content: center;
                                font-size: 14px;
                            `}
                        >
                            Kommentar:
                        </div>
                        <div
                            css={css`
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                font-size: 14px;
                                font-style: italic;
                            `}
                        >
                            {data.comment}
                        </div>
                    </>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};
