import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import { useBaseUrl } from '../../lib/api';
import { button, lavendelButton, greyButton } from '../../styles';
import { LoggedInLayout } from './LoggedInLayout';

export const Stats: FC<RouteComponentProps> = () => {
    const apiBaseUrl = useBaseUrl();
    return (
        <LoggedInLayout>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    a {
                        ${lavendelButton};
                        display: block;
                        margin-bottom: 8px;
                    }
                `}
            >
                <a href={`${apiBaseUrl}/api/v1/stats.csv`}>Alla chattar</a>
                <a href={`${apiBaseUrl}/api/v1/feedbacks.csv`}>All feedback</a>
                <a href={`${apiBaseUrl}/api/v1/aggregated_stats.csv?interval=year`}>
                    Samlat per år
                </a>
                <a href={`${apiBaseUrl}/api/v1/aggregated_stats.csv?interval=quarter`}>
                    Samlat per kvartal
                </a>
                <a href={`${apiBaseUrl}/api/v1/aggregated_stats.csv?interval=month`}>
                    Samlat per månad
                </a>
                <a href={`${apiBaseUrl}/api/v1/aggregated_stats.csv?interval=day`}>
                    Samlat per dag
                </a>
            </div>
        </LoggedInLayout>
    );
};
