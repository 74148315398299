export const createFriendlyName = () => `${randomAdjective()} ${randomAnimal()}`;

const localStorageFriendlyNameKey = 'chat-friendly-name';

export function createAndKeepFriendlyName(): string {
    try {
        let item: any = localStorage.getItem(localStorageFriendlyNameKey);
        if (item !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            item = JSON.parse(item);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const d = Date.parse(item.date);
            item.parsedDate = new Date(d);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (OnSameDay(item.parsedDate, new Date()) && item.name) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return item.name;
            }
        }
    } catch (error) {
        localStorage.removeItem(localStorageFriendlyNameKey);
    }
    let name = createFriendlyName();
    setFriendlyNameWithExpiry(name);
    return name;
}

const randomAdjective = () => adjectives[Math.floor(Math.random() * adjectives.length)];
const randomAnimal = () => animals[Math.floor(Math.random() * animals.length)];

function setFriendlyNameWithExpiry(name: string) {
    localStorage.setItem(
        localStorageFriendlyNameKey,
        JSON.stringify({
            name,
            date: new Date(),
        })
    );
}

const OnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const adjectives: string[] = [
    'Aktiv',
    'Arbetsam',
    'Artig',
    'Berömd',
    'Bestämd',
    'Blyg',
    'Entusiastisk',
    'Förstående',
    'Genial',
    'Glad',
    'Grön',
    'Hjälpsam',
    'Inspirerad',
    'Ivrig',
    'Kreativ',
    'Listig',
    'Liten',
    'Lugn',
    'Lyhörd',
    'Mjuk',
    'Musikalisk',
    'Mysig',
    'Peppig',
    'Pigg',
    'Prickig',
    'Positiv',
    'Praktisk',
    'Skrattande',
    'Smart',
    'Snabb',
    'Snäll',
    'Subtil',
    'Tillgiven',
    'Trygg',
    'Underbar',
    'Upplyft',
    'Uppmärksam',
    'Viktig',
    'Vänlig',
    'Ödmjuk',
];

const animals: string[] = [
    'Björn',
    'Delfin',
    'Elefant',
    'Flamingo',
    'Flodhäst',
    'Giraff',
    'Gorilla',
    'Groda',
    'Gräshoppa',
    'Haj',
    'Hare',
    'Igelkott',
    'Kamel',
    'Koala',
    'Krokodil',
    'Kräfta ',
    'Känguru',
    'Lama',
    'Lejon',
    'Lemur',
    'Manet ',
    'Marsvin ',
    'Panda',
    'Papegoja',
    'Pingvin',
    'Räv',
    'Salamander',
    'Sjöstjärna',
    'Svan',
    'Säl',
    'Tapir',
    'Tiger',
    'Uggla',
    'Utter',
    'Zebra',
    'Älg',
    'Ödla',
    'Örn',
];
