import { hydrate } from 'react-dom';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import 'iframe-resizer/js/iframeResizer.contentWindow';

import App from './App';
import { TenantProvider } from './lib/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dehydratedState = (window as any).__REACT_QUERY_STATE__;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tenantData = (window as any).TENANT_DATA;

const queryClient = new QueryClient({
    defaultOptions: { queries: { useErrorBoundary: true }, mutations: { useErrorBoundary: true } },
});

hydrate(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <TenantProvider tenant={tenantData}>
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <App />
            </Hydrate>
        </QueryClientProvider>
    </TenantProvider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
