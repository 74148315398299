import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import Div100vh from 'react-div-100vh';
import { useTenantData } from '../lib/api';
import { color } from '../styles';

export const AlreadyInChat: FC<RouteComponentProps> = () => {
    const tenant = useTenantData();

    return (
        <Div100vh
            css={css`
                display: grid;
                grid-template-rows: auto auto 1fr;
                margin: 0 24px;
            `}
        >
            <div
                css={css`
                    border-bottom: 1px solid #ececec;
                    width: 100%;
                    max-width: 900px;
                    margin: 0 auto;
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    color: ${color.lightgrey};
                    font-size: 20px;
                    img {
                        height: 48px;
                    }
                `}
            >
                {tenant.LogoImage ? <img src="/jourhavande.png" /> : <div>{tenant.Title}</div>}
                <img src="/rkuf-logo.png" />
            </div>
            <div
                css={css`
                    font-size: 24px;
                    margin: 0 auto;
                    margin-top: 20vh;
                    width: 75%;
                `}
            >
                Vi hjälper till med ett ämne i taget. Du är välkommen att chatta in när du har
                avslutat chatten du har igång.
            </div>
        </Div100vh>
    );
};
