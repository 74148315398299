import React, { FC, useContext, useEffect, useRef, useState, PropsWithChildren } from 'react';
import { useAuthStatus, useBaseUrl } from './api';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

const EventSourceContext = React.createContext<EventSource | null>(null);

export const GlobalEventsProvider: FC<PropsWithChildren> = ({ children }) => {
    const apiBaseUrl = useBaseUrl();
    const authStatus = useAuthStatus();

    const [eventSource, setEventSource] = useState<EventSource | null>(null);

    useEffect(
        () => {
            let closed = false;
            let eventSorceForClose: EventSource | null = null;
            let reconnectTimer: NodeJS.Timeout | null = null;
            function connect() {
                const evtSource = new EventSource(`${apiBaseUrl}/api/v1/events_sse`, {
                    withCredentials: true,
                });

                Sentry.addBreadcrumb({
                    type: 'default',
                    level: Severity.Log,
                    message: 'Connected to global events sse',
                });

                // EventSource error event is a generic Event: https://developer.mozilla.org/en-US/docs/Web/API/EventSource/error_event
                evtSource.onerror = event => {
                    Sentry.addBreadcrumb({
                        type: 'default',
                        level: Severity.Warning,
                        message: 'lost connection to global events sse',
                    });
                    Sentry.captureMessage('Got error in global events sse');
                    if (evtSource?.readyState === 2 && !closed) {
                        // Force a reconnect if event source is closed
                        console.log('reconnecting');
                        if (reconnectTimer) {
                            clearTimeout(reconnectTimer);
                        }
                        reconnectTimer = setTimeout(() => {
                            Sentry.addBreadcrumb({
                                type: 'default',
                                level: Severity.Log,
                                message: 'reconnecting to global events sse',
                            });
                            connect();
                        }, 5000);
                    }
                };

                eventSorceForClose = evtSource;
                setEventSource(evtSource);
            }

            connect();

            return () => {
                closed = true;
                if (eventSorceForClose) {
                    eventSorceForClose.close();
                }
            };
        },
        // Reconnect when user id changes, this is required since when our cookies change we need to
        // reconect for so that the event source knows about the new cookie
        [authStatus.data?.status, authStatus.data?.user?.id, apiBaseUrl]
    );

    return (
        <EventSourceContext.Provider value={eventSource}>{children}</EventSourceContext.Provider>
    );
};

export function useGlobalEvents(eventName: string, handler: (ev: MessageEvent) => void) {
    // Inspired by https://usehooks.com/useEventListener/

    // Create a ref that stores handler
    const savedHandler = useRef<(ev: MessageEvent) => void>();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    let eventSource = useContext(EventSourceContext);

    useEffect(() => {
        // Create event listener that calls handler function stored in ref

        let evtSource = eventSource;

        if (evtSource) {
            let eventListener = (event: Event) => savedHandler.current?.(event as MessageEvent);

            evtSource.addEventListener(eventName, eventListener);

            return () => {
                evtSource?.removeEventListener(eventName, eventListener);
            };
        }
    }, [eventName, eventSource, handler]);
}
