import { css } from '@emotion/react';
import { Link, LinkGetProps } from '@reach/router';
import { FC, PropsWithChildren } from 'react';
import { useAuthStatus } from '../../lib/api';
import { color } from '../../styles';
import { LoggedInLayout } from '../logged_in/LoggedInLayout';

export const SettingsLayout: FC<PropsWithChildren> = ({ children }) => {
    const authStatus = useAuthStatus();

    return (
        <LoggedInLayout>
            <div
                css={css`
                    border-bottom: 1px solid ${color.black};
                    display: flex;
                    gap: 24px;
                    padding-bottom: 16px;
                    margin-bottom: 24px;

                    a {
                        :hover {
                            text-decoration: underline;
                        }

                        &[data-active='true'] {
                            text-decoration: underline;
                        }
                    }
                `}
            >
                <Link getProps={isActive} to="/frivillig/settings/queue">
                    Kö
                </Link>
                {authStatus.data?.user?.role == 'admin' ? (
                    <Link getProps={isActive} to="/frivillig/settings/texts">
                        Texter
                    </Link>
                ) : null}
                {authStatus.data?.user?.role != 'volunteer' ? (
                    <Link getProps={isActive} to="/frivillig/settings/users">
                        Användare
                    </Link>
                ) : null}
                {authStatus.data?.user?.role == 'admin' ? (
                    <Link getProps={isActive} to="/frivillig/settings/attributes">
                        Egenskaper
                    </Link>
                ) : null}
            </div>
            {children}
        </LoggedInLayout>
    );
};

const isActive = ({ isPartiallyCurrent }: LinkGetProps) => {
    return isPartiallyCurrent ? { 'data-active': 'true' } : {};
};
