import { AxiosError, AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useIrisApi } from './api';
import { useGlobalEvents } from './global_events';

type QueueStatusData = {
    queueLength: number;
    queueOpen: boolean;
    numVolunteers: number;
    queueIsMaxLength: boolean;
};

type QueueLength =
    | {
          isLoading: true;
          error: undefined;
          data: undefined;
      }
    | {
          isLoading: false;
          error: AxiosError;
          data: undefined;
      }
    | {
          isLoading: false;
          error: undefined;
          data: QueueStatusData;
      };

export async function fetchQueueStatus(irisApi: AxiosInstance) {
    let res = await irisApi.get<QueueStatusData>('/api/v1/queue-status');
    return res.data;
}

export function useQueueStatus(): QueueLength {
    let irisApi = useIrisApi();
    let queryClient = useQueryClient();
    let queueLength = useQuery('queue-status', async () => fetchQueueStatus(irisApi), {
        refetchInterval: 30000,
    });

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('queue-status').catch(err => console.error(err));
    });

    if (queueLength.error) {
        return { isLoading: false, error: queueLength.error as AxiosError, data: undefined };
    }

    if (queueLength.isLoading || !queueLength.data) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    return { data: queueLength.data, isLoading: false, error: undefined };
}
