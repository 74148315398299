import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { FC, useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Attribute, useAttributes, useAuthStatus, useIrisApi } from '../../lib/api';
import { Button, Buttons, Field, Form } from '../../components/Form';
import { buttonReset, color, s } from '../../styles';
import { LoggedInLayout } from './LoggedInLayout';
import { toPlural } from '../../lib/utils';

export const Profile: FC<RouteComponentProps> = () => {
    return (
        <LoggedInLayout>
            <SelectChatAttributes />
            <ChangeInfo />
        </LoggedInLayout>
    );
};

const SelectChatAttributes: FC = () => {
    const irisApi = useIrisApi();
    const queryClient = useQueryClient();
    const attributes = useAttributes();
    const authStatus = useAuthStatus();

    let [selectedAttributes, setSelectedAttributes] = useState<Record<string, number[]>>({});

    useEffect(() => {
        if (authStatus.data?.user?.attributes) {
            setSelectedAttributes(authStatus.data?.user?.attributes);
        }
    }, [authStatus.data?.user?.attributes]);

    const setAttributes = useMutation(async (newValue: Record<string, number[]>) => {
        await irisApi.patch('/api/v1/auth/me', {
            Name: authStatus.data?.user?.name,
            Attributes: newValue,
        });
        await queryClient.invalidateQueries('auth_status');
    });

    if (attributes.isLoading || !attributes.data) {
        return <div>Laddar...</div>;
    }

    function attrValueById(attr: Attribute, id: number): string {
        return attr.Values.find(a => a.id === id)?.value || '';
    }

    return (
        <div>
            {attributes.data
                .filter(a => a.UserAvailable)
                .map(attribute => (
                    <div
                        key={attribute.ID}
                        css={css`
                            margin-bottom: 40px;
                        `}
                    >
                        <label
                            key={attribute.ID}
                            css={css`
                                max-width: 350px;
                                display: block;
                                align-items: center;
                                gap: 8px;
                                font-weight: bold;
                                margin-bottom: 16px;
                                select {
                                    margin-top: 8px;
                                    display: block;
                                    font-size: 18px;
                                    line-height: 24px;
                                    display: block;
                                    width: 100%;
                                    border: 1px solid ${color.lightgrey};
                                    border-radius: 3px;
                                    padding: ${s(1)} ${s(2)};
                                }
                            `}
                        >
                            {toPlural(attribute.Key)}
                            <select
                                onChange={ev => {
                                    let value = ev.target.value;
                                    ev.target.value = '';
                                    setSelectedAttributes(before => {
                                        let set = before?.[attribute.ID] || [];
                                        set = Array.from(new Set([...set, parseInt(value, 10)]));

                                        let newVal = {
                                            ...before,
                                            [attribute.ID]: set,
                                        };

                                        setAttributes.mutate(newVal);

                                        return newVal;
                                    });
                                }}
                            >
                                <option value="">Lägg till {attribute.Key.toLowerCase()}</option>
                                {attribute.Values.map(val => (
                                    <option key={val.id} value={val.id}>
                                        {val.value}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <div
                            css={css`
                                display: flex;
                                gap: 16px;
                                padding-bottom: 40px;
                                border-bottom: 1px solid ${color.lighterGrey};
                            `}
                        >
                            {(selectedAttributes[attribute.ID] || []).map((val, idx) => (
                                <button
                                    key={idx}
                                    css={css`
                                        ${buttonReset};
                                        padding: 8px 16px;
                                        border: 1px solid ${color.lightgrey};
                                        border-radius: 3px;
                                        cursor: pointer;
                                    `}
                                    onClick={() => {
                                        setSelectedAttributes(before => {
                                            let set = new Set(before?.[attribute.ID] || []);
                                            set.delete(val);

                                            let newVal = {
                                                ...before,
                                                [attribute.ID]: Array.from(set),
                                            };

                                            setAttributes.mutate(newVal);

                                            return newVal;
                                        });
                                    }}
                                >
                                    {attrValueById(attribute, val)}&nbsp;x
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

const ChangeInfo = () => {
    const irisApi = useIrisApi();
    const authStatus = useAuthStatus();

    let [name, setName] = useState(authStatus.data?.user?.name);

    useEffect(() => {
        setName(authStatus.data?.user?.name);
    }, [authStatus.data?.user?.name]);

    const queryClient = useQueryClient();

    const updateMe = useMutation(
        async () => {
            await irisApi.patch('/api/v1/auth/me', {
                Name: name,
                Attributes: authStatus.data?.user?.attributes,
            });
            await queryClient.invalidateQueries('auth_status');
        },
        { useErrorBoundary: false }
    );

    let nameError = !name && 'Namn är obligatoriskt';

    const hasErrors = !!nameError;

    return (
        <section
            css={css`
                max-width: 350px;
                margin-bottom: ${s(5)};
                h1 {
                    font-size: 20px;
                }
            `}
        >
            <h1>Min information</h1>
            <Form
                error={updateMe.error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        updateMe.mutate();
                        return true;
                    }
                }}
            >
                <Field label="Namn" error={nameError}>
                    <input
                        placeholder="Namn Namnesson"
                        value={name}
                        autoComplete="off"
                        onChange={ev => setName(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={updateMe.isLoading}>
                        {updateMe.isLoading ? 'Laddar' : 'Spara'}
                    </Button>
                </Buttons>
            </Form>
        </section>
    );
};
