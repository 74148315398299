import { css } from '@emotion/react';
import { AxiosInstance } from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Field, Button } from '../../components/Form';
import { useIrisApi } from '../../lib/api';
import { color, h1 } from '../../styles';
import * as Sentry from '@sentry/browser';

async function loginMutation({
    email,
    password,
    irisApi,
}: {
    email: string;
    password: string;
    irisApi: AxiosInstance;
}): Promise<void> {
    if (email && password) {
        try {
            await irisApi.post('/api/v1/auth/login', { email, password });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            Sentry.setContext('Email failed ', { email });
            Sentry.captureException(err);
            let maybeMessage = err.response?.data;
            if (maybeMessage && typeof maybeMessage === 'string') {
                throw new Error(maybeMessage);
            }
            throw err;
        }
    } else {
        throw new Error('Email and password required');
    }
}

export const Login = () => {
    const irisApi = useIrisApi();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetPasswordIsSend, setResetPasswordIsSend] = useState(false);
    const [forgotPass, setForgotPass] = useState<boolean>(false);

    const queryClient = useQueryClient();

    let resetPassword = useMutation(async () => {
        await irisApi.post('/api/v1/users/reset-password', {
            email: email,
        });
    });

    let { mutate, isLoading, error } = useMutation(
        async () => loginMutation({ email, password, irisApi }),
        {
            onError: error => {
                Sentry.captureException(error);
                console.error(error);
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries(
                    'auth_status',
                    {
                        refetchInactive: true,
                    },
                    { throwOnError: true }
                );
            },
            useErrorBoundary: false,
        }
    );

    const emailError = !email && 'E-post är obligatoriskt';
    const passwordError = !password && 'Lösenord är obligatoriskt';

    const hasErrors = emailError || passwordError;

    if (forgotPass) {
        return (
            <div
                css={css`
                    max-width: 800px;
                    margin: 0 auto;
                    margin-top: 20vh;
                    border: 1px solid ${color.lightgrey};
                    border-radius: 3px;
                    padding: 24px;
                `}
            >
                <h1 css={h1}>Glömt ditt lösenord?</h1>
                <Form
                    error={error as Error}
                    onSubmit={() => {
                        if (!emailError) {
                            resetPassword.mutate();
                            setResetPasswordIsSend(true);
                        }
                    }}
                >
                    {resetPasswordIsSend || (
                        <Field label="E-post" error={emailError}>
                            <input
                                placeholder="din@e-post.se"
                                value={email}
                                type="email"
                                autoComplete="email"
                                onChange={ev => setEmail(ev.target.value)}
                            />
                        </Field>
                    )}
                    {resetPasswordIsSend && (
                        <div
                            css={css`
                                margin: 40px auto;
                            `}
                        >
                            E-post har skickats till {email}
                        </div>
                    )}
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        `}
                    >
                        <button
                            css={css`
                                border: none;
                                background: inherit;
                                text-decoration: underline #5f5f5f 0.5px;
                                font-weight: bold;
                                font-size: 16px;
                            `}
                            onClick={ev => {
                                ev.preventDefault();
                                setForgotPass(false);
                                setResetPasswordIsSend(false);
                            }}
                        >
                            Tillbaka
                        </button>
                        {resetPasswordIsSend || (
                            <Button disabled={resetPassword.isLoading}>
                                {resetPassword.isLoading ? '...' : 'Skicka'}
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        );
    }

    return (
        <div
            css={css`
                max-width: 800px;
                margin: 0 auto;
                margin-top: 20vh;
                border: 1px solid ${color.lightgrey};
                border-radius: 3px;
                padding: 24px;
            `}
        >
            <h1 css={h1}>Logga in</h1>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        mutate();
                    }
                }}
            >
                <Field label="E-post" error={emailError}>
                    <input
                        placeholder="din@e-post.se"
                        value={email}
                        type="email"
                        autoComplete="email"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Field label="Lösenord" error={passwordError}>
                    <input
                        placeholder="*****"
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <div
                    /* Keep "row-reverse", as it is necessary so that the login button is the next focus after tab so that enter => login. This is similiar to the login for gmail.com*/
                    css={css`
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    `}
                >
                    <Button disabled={isLoading}>{isLoading ? 'Laddar' : 'Logga in'}</Button>
                    <button
                        css={css`
                            border: none;
                            background: inherit;
                            text-decoration: underline #5f5f5f 0.5px;
                            font-weight: bold;
                            font-size: 16px;
                        `}
                        onClick={ev => {
                            ev.preventDefault();
                            setForgotPass(true);
                        }}
                    >
                        Glömt lösenord?
                    </button>
                </div>
            </Form>
        </div>
    );
};
