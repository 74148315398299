import { css } from '@emotion/react';
import { navigate, RouteComponentProps } from '@reach/router';
import { format, intervalToDuration } from 'date-fns';
import React from 'react';
import DOMPurify from 'dompurify';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    useAttributes,
    useAuthStatus,
    useIrisApi,
    useFeatureToggles,
    useTextMapper,
} from '../../lib/api';
import { useGlobalEvents } from '../../lib/global_events';
import { toPlural } from '../../lib/utils';
import { attributeButtons, button, color } from '../../styles';
import { LoggedInLayout } from './LoggedInLayout';

type QueueChat = {
    ID: string;
    CreatedAt: string;
    FriendlyName: string;
    Attributes: Record<string, string>;
    VideoID: string;
};

type QueueData = {
    Queued: Array<QueueChat> | null;
};

export const Queue: FC<RouteComponentProps> = () => {
    const { show_all_chats, internal_info } = useFeatureToggles();
    const extraArea = show_all_chats ? 'chats' : internal_info ? 'internal_info' : '';

    // There will currently only be show_all_chats or internal_info used as features by a tenant, not both at once.
    // Therefore, we assume that the max amount of columns will be three.
    const columns = show_all_chats || internal_info ? 3 : 2;
    const areas = `queued users ${extraArea}`;

    return (
        <LoggedInLayout>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: repeat(${columns}, 1fr);
                    grid-template-areas: '${areas}';
                    grid-gap: 24px;
                    align-items: start;
                    justify-items: start;

                    @media (max-width: 900px) {
                        grid-template-areas:
                            'queued . users'
                            '. . ${extraArea}';
                        align-items: center;
                    }
                `}
            >
                <Queued />
                <ActiveUsers />
                {internal_info && <InternalInfo />}
                {show_all_chats && <AllActiveChats />}
            </div>
        </LoggedInLayout>
    );
};

const Queued: FC = () => {
    const irisApi = useIrisApi();
    const attributes = useAttributes();
    const authStatus = useAuthStatus();

    let queryClient = useQueryClient();
    let queue = useQuery(
        'queue',
        async () => {
            let res = await irisApi.get<QueueData>('/api/v1/queue');
            return res.data;
        },
        {
            // We get an event when users are added to the queue, but not when they disappear from
            // the queue (since that is not something that happens, just a timeout. So there is no
            // event)
            //
            // Therefore we refertch the queue every 10th second
            refetchInterval: 30000,
        }
    );

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('queue').catch(err => console.error(err));
    });

    if (queue.error) {
        throw queue.error;
    }

    if (attributes.error) {
        throw attributes.error;
    }

    if (authStatus.error) {
        throw authStatus.error;
    }

    if (
        queue.isLoading ||
        attributes.isLoading ||
        authStatus.isLoading ||
        !authStatus.data ||
        !authStatus.data.user
    ) {
        return (
            <div
                css={css`
                    grid-area: queued;
                `}
            >
                Laddar...
            </div>
        );
    }

    // Split into two lists, one where the user knows all attributes and one where they don't
    let userSelectableAttributes = (attributes.data || []).filter(attr => attr.UserAvailable);
    let userSelectableAttributesHeading = userSelectableAttributes
        .map(attr => toPlural(attr.Key))
        .join(', ');

    let myQueue: QueueChat[] = [];
    let allQueue: QueueChat[] = [];

    let allAttributes = attributes.data;
    let myRawAttributes = authStatus.data.user.attributes;
    let myAttributes: Record<string, string[]> = {};
    Object.keys(myRawAttributes).forEach(attr => {
        let attribute = allAttributes?.find(a => a.ID.toString() === attr);

        if (attribute) {
            myAttributes[attribute.Key] = myRawAttributes[attr]
                .map(key => {
                    let value = attribute?.Values.find(v => v.id === key);
                    return value?.value;
                })
                .filter(isDefined);
        }
    });

    (queue.data?.Queued || []).forEach(chat => {
        if (
            userSelectableAttributes.every(attr =>
                myAttributes[attr.Key]?.includes(chat.Attributes[attr.Key])
            )
        ) {
            myQueue.push(chat);
        } else {
            allQueue.push(chat);
        }
    });

    return (
        <div
            css={css`
                grid-area: queued;
            `}
        >
            <h2>Kö</h2>
            <h3>Mina {userSelectableAttributesHeading.toLowerCase()}</h3>
            {(myQueue || []).map(chat => (
                <ChatInQueue key={chat.ID} chat={chat} />
            ))}
            <h3>Alla {userSelectableAttributesHeading.toLowerCase()}</h3>
            {(allQueue || []).map(chat => (
                <ChatInQueue key={chat.ID} chat={chat} />
            ))}
        </div>
    );
};

function isDefined<T>(item: T | undefined | null): item is T {
    return item !== null && item !== undefined;
}

const ChatInQueue: FC<{ chat: NonNullable<QueueData['Queued']>[0] }> = ({ chat }) => {
    const irisApi = useIrisApi();

    const attributes = useAttributes();

    const claim = useMutation(
        async () => {
            await irisApi.post(`/api/v1/chat/${chat.ID}/claim`);
            await navigate(`/frivillig/chat/${chat.ID}`);
        },
        { retry: 2 }
    );

    const remove = useMutation(async () => {
        if (confirm('Är du säker på att du vill ta bort den här chatten?')) {
            await irisApi.post(`/api/v1/chat/${chat.ID}/removeFromQueue`);
        }
    });

    if (attributes.isLoading || !attributes.data) {
        return <div>Laddar...</div>;
    }

    const hideAttrKeys = attributes.data.filter(val => val.HideInChat).map(val => val.Key);
    // Only show attribute data if not in hideAttrKeys
    const showAttrData = Object.keys(chat.Attributes)
        .filter(key => !hideAttrKeys.includes(key))
        .map(key => [key, chat.Attributes[key]]);

    return (
        <div
            css={css`
                display: grid;
                grid-template-columns: auto auto;
                gap: 16px 24px;
                margin-bottom: 16px;
                border: 1px solid #ececec;
                padding: 16px;
                border-radius: 2px;
            `}
        >
            <h3
                css={css`
                    font-size: 20px;
                    margin: 0;
                `}
            >
                {chat.FriendlyName}
            </h3>
            <div
                css={css`
                    justify-self: end;
                `}
            >
                <Timer start={new Date(chat.CreatedAt)} />
            </div>
            {showAttrData.map(([key, val]) => (
                <>
                    <div
                        css={css`
                            font-weight: bold;
                        `}
                    >
                        {key}
                    </div>
                    <div
                        css={css`
                            justify-self: end;
                        `}
                    >
                        {val}
                    </div>
                </>
            ))}
            <div
                css={css`
                    grid-column: 1 / span 2;
                    justify-self: end;
                    display: flex;
                    gap: 8px;
                `}
            >
                <button
                    disabled={remove.isLoading}
                    onClick={() => remove.mutate()}
                    css={css`
                        display: block;
                        ${attributeButtons};
                    `}
                >
                    {remove.isLoading ? '...' : 'Ta bort'}
                </button>
                <button
                    disabled={claim.isLoading}
                    onClick={() => claim.mutate()}
                    css={css`
                        display: block;
                        ${button};
                    `}
                >
                    {claim.isLoading
                        ? 'Laddar chatt'
                        : chat.VideoID
                        ? 'Starta videochatt '
                        : 'Starta chatt'}
                </button>
            </div>
        </div>
    );
};

export const Timer: FC<{ start: Date; end?: Date }> = ({ start, end }) => {
    // Start at start because of SSR
    let [now, setNow] = useState<Date | undefined>(end);

    useEffect(() => {
        if (!end) {
            setNow(new Date());
            let interval = setInterval(() => {
                setNow(new Date());
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [end]);

    if (!now) {
        return null;
    }

    let duration = intervalToDuration({ start, end: now });

    return (
        <div>
            {ifNotNull(duration.years, 'years ')}
            {ifNotNull(duration.months, 'months ')}
            {ifNotNull(duration.weeks, 'weeks ')}
            {ifNotNull(duration.hours, 'h ')}
            {formatValue(duration.minutes)}m {formatValue(duration.seconds)}s
        </div>
    );
};

function ifNotNull(value: number | undefined, postfix: string) {
    if (!value || value == 0) {
        return '';
    } else {
        return `${formatValue(value)}${postfix}`;
    }
}

function formatValue(value: number | undefined) {
    if (value == undefined) {
        return '00';
    }

    if (value < 10) {
        return `0${value}`;
    } else {
        return `${value}`;
    }
}

const InternalInfo: FC = () => {
    const { t } = useTextMapper();
    const info = t('internal_info').split('\n');

    return (
        <div
            css={css`
                grid-area: internal_info;
                display: grid;
                justify-content: start;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: start;
                `}
            >
                <h2>Information</h2>
            </div>
            <div
                css={css`
                    padding: 16px;
                    border: 1px solid #ececec;
                `}
            >
                {info.map((val, i) => {
                    let sanitizedHtml = DOMPurify.sanitize(val.trim(), {
                        USE_PROFILES: { html: true },
                    });

                    return (
                        <div
                            css={css`
                                a {
                                    text-decoration: underline;
                                }
                            `}
                            dangerouslySetInnerHTML={{
                                __html: sanitizedHtml,
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

type ActiveUser = {
    ID: number;
    Name: string;
    Attributes: Record<string, number[]>;
    ChatCount: number;
};

const ActiveUsers: FC = () => {
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(true);
    const featureToggles = useFeatureToggles();
    const irisApi = useIrisApi();
    let queryClient = useQueryClient();
    let users = useQuery(
        'active_users',
        async () => {
            let res = await irisApi.get<ActiveUser[]>('/api/v1/active_users');
            return res.data;
        },
        {
            // There is no event when users are not active anymore, so we poll
            refetchInterval: 30000,
        }
    );

    let attributes = useAttributes();

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('active_users').catch(err => console.error(err));
    });

    if (users.error) {
        throw users.error;
    }

    if (users.isLoading || !users.data) {
        return (
            <div
                css={css`
                    grid-area: users;
                `}
            >
                Laddar...
            </div>
        );
    }

    return (
        <div
            css={css`
                grid-area: users;
                display: grid;
                justify-content: start;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: start;
                `}
            >
                <h2>Aktiva frivilliga</h2>

                {featureToggles.show_more_subjects && (
                    <button
                        css={css`
                            border: none;
                            background: inherit;
                            text-decoration: underline #5f5f5f 0.5px;
                            font-weight: bold;
                            font-size: 16px;
                            margin: 1vh 0px 0px 1vh;
                        `}
                        onClick={() => {
                            setShowMoreInfo(!showMoreInfo);
                        }}
                    >
                        {showMoreInfo ? 'Visa mindre' : 'Visa mer'}
                    </button>
                )}
            </div>
            {users.data.map(user => (
                <div
                    key={user.ID}
                    css={css`
                        display: grid;
                        grid-template-columns: auto auto auto;
                        gap: 16px 24px;
                        margin-bottom: 16px;
                        border: 1px solid #ececec;
                        padding: 16px;
                        border-radius: 2px;
                    `}
                >
                    <h3
                        css={css`
                            font-size: 20px;
                            margin: 0;
                            grid-column: 1 / span 2;
                        `}
                    >
                        {user.Name}
                    </h3>
                    <div
                        css={css`
                            grid-column: 3;

                            font-family: monospace;
                            border-radius: 100%;
                            height: 24px;
                            width: 24px;
                            display: flex;
                            line-height: 16px;
                            font-size: 16px;
                            font-weight: bold;
                            justify-content: center;
                            align-items: center;
                            background: ${color.lavendel};
                        `}
                    >
                        {user.ChatCount}
                    </div>
                    {showMoreInfo &&
                        attributes.data
                            ?.filter(a => a.UserAvailable)
                            .map(attr => {
                                let values = (user.Attributes[attr.ID] || []).map(
                                    valueId => attr.Values.find(v => v.id === valueId)?.value
                                );

                                values.sort();

                                return (
                                    <React.Fragment key={attr.Key}>
                                        <div
                                            css={css`
                                                font-weight: bold;
                                                grid-column: 1;
                                            `}
                                        >
                                            {toPlural(attr.Key)}
                                        </div>
                                        {values.map((val, idx) => (
                                            <div
                                                key={idx}
                                                css={css`
                                                    grid-column: 2 / span 2;
                                                `}
                                            >
                                                {val}
                                            </div>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                </div>
            ))}
        </div>
    );
};

type AllActiveChatsData = {
    AllChats: Array<{
        ID: string;
        CreatedAt: string;
        UpdatedAt: string;
        FriendlyName: string;
        Attributes: Record<string, string>;
        Users: string[];
        VideoID: string;
    }> | null;
};

const AllActiveChats: FC = () => {
    const irisApi = useIrisApi();

    let queryClient = useQueryClient();
    let chats = useQuery(
        'all_chats',
        async () => {
            let res = await irisApi.get<AllActiveChatsData>('/api/v1/active_chats');
            return res.data;
        },
        {
            refetchInterval: 30000,
        }
    );

    useGlobalEvents('queue_updated', () => {
        queryClient.invalidateQueries('all_chats').catch(err => console.error(err));
    });

    if (chats.error) {
        throw chats.error;
    }

    if (chats.isLoading) {
        return (
            <div
                css={css`
                    grid-area: chats;
                `}
            >
                Laddar...
            </div>
        );
    }

    return (
        <div
            css={css`
                grid-area: chats;
            `}
        >
            <h2>Alla chattar</h2>
            {(chats.data?.AllChats || [])
                .filter(chat => !chat.ID.startsWith('internal'))
                .map(chat => (
                    <ChatInAllActiveChats key={chat.ID} chat={chat} />
                ))}
        </div>
    );
};

const ChatInAllActiveChats: FC<{ chat: NonNullable<AllActiveChatsData['AllChats']>[0] }> = ({
    chat,
}) => {
    const irisApi = useIrisApi();
    const authStatus = useAuthStatus();
    const attributes = useAttributes();

    const join = useMutation(
        async () => {
            const userName = authStatus.data?.user?.name;
            if (userName && !chat.Users.includes(userName)) {
                try {
                    await irisApi.post(`/api/v1/chat/${chat.ID}/join`);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (err: any) {
                    let maybeMessage = err.response?.data;
                    if (maybeMessage && typeof maybeMessage === 'string') {
                        throw new Error(maybeMessage);
                    }
                    throw err;
                }
            }
            await navigate(`/frivillig/chat/${chat.ID}`);
        },
        { retry: 2 }
    );

    const makeAllUsersLeave = useMutation(async () => {
        try {
            await irisApi.post(`/api/v1/chat/${chat.ID}/make-all-users-leave`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            let maybeMessage = err.response?.data;
            if (maybeMessage && typeof maybeMessage === 'string') {
                throw new Error(maybeMessage);
            }
            throw err;
        }
    });
    const chatUsers = chat.Users.filter(x => x != null);

    if (attributes.isLoading || !attributes.data) {
        return <div>Laddar...</div>;
    }

    const hideAttrKeys = attributes.data.filter(val => val.HideInChat).map(val => val.Key);
    // Only show attribute data if not in hideAttrKeys
    const showAttrData = Object.keys(chat.Attributes)
        .filter(key => !hideAttrKeys.includes(key))
        .map(key => [key, chat.Attributes[key]]);

    return (
        <div
            css={css`
                display: grid;
                grid-template-columns: auto auto;
                gap: 16px 24px;
                margin-bottom: 16px;
                border: 1px solid #ececec;
                padding: 16px;
                border-radius: 2px;
            `}
        >
            <h3
                css={css`
                    font-size: 20px;
                    margin: 0;
                `}
            >
                {chat.FriendlyName}
            </h3>
            <div
                css={css`
                    justify-self: end;
                `}
            ></div>
            {showAttrData.map(([key, val]) => (
                <React.Fragment key={key}>
                    <div
                        css={css`
                            font-weight: bold;
                        `}
                    >
                        {key}
                    </div>
                    <div
                        css={css`
                            justify-self: end;
                        `}
                    >
                        {val}
                    </div>
                </React.Fragment>
            ))}
            {chatUsers.length > 0 && (
                <>
                    <h3
                        css={css`
                            font-size: 20px;
                            margin: 0;
                        `}
                    >
                        Frivillig:
                    </h3>
                    <div
                        css={css`
                            justify-self: end;
                        `}
                    >
                        {chatUsers.join(', ')}
                    </div>
                </>
            )}
            <h3
                css={css`
                    font-size: 20px;
                    margin: 0;
                `}
            >
                Senast aktiv:
            </h3>
            <div
                css={css`
                    justify-self: end;
                `}
            >
                {format(new Date(chat.UpdatedAt), 'dd.MM.yyyy HH:mm')}
            </div>
            <div
                css={css`
                    grid-column: 1 / span 2;
                    justify-self: end;
                    display: flex;
                    gap: 16px;
                `}
            >
                <button
                    disabled={makeAllUsersLeave.isLoading}
                    css={css`
                        display: block;

                        ${attributeButtons};
                    `}
                    onClick={() => makeAllUsersLeave.mutate()}
                >
                    {makeAllUsersLeave.isLoading ? 'Avsluter...' : 'Avsluta'}
                </button>
                <button
                    disabled={join.isLoading}
                    css={css`
                        display: block;

                        ${button};
                    `}
                    onClick={() => join.mutate()}
                >
                    {join.isLoading
                        ? 'Laddar chatt'
                        : chat.VideoID
                        ? 'Öppna videochatt'
                        : 'Öppna chatt'}
                </button>
            </div>
        </div>
    );
};
