import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Field, Buttons, Button } from '../../components/Form';
import { useAttributes, useAuthStatus, useIrisApi } from '../../lib/api';
import { toPlural } from '../../lib/utils';
import { button, attributeButton, color, s, attributeButtons } from '../../styles';
import { SettingsLayout } from './SettingsLayout';

type AttributeValue = {
    id: number;
    value: string;
    userAvailable: boolean;
};

type Attribute = {
    ID: number;
    Key: string;
    UserAvailable: boolean;
    Values: Array<AttributeValue> | null;
};

export const AttributesAdmin: FC<RouteComponentProps> = () => {
    const attributes = useAttributes();

    const authStatus = useAuthStatus();

    if (authStatus.data?.user?.role == 'volunteer') {
        return <Redirect to="/frivillig/queue"></Redirect>;
    }

    if (attributes.isLoading || !attributes.data) {
        return <div>Laddar...</div>;
    }
    return (
        <SettingsLayout>
            <section
                css={css`
                    h1 {
                        font-size: 20px;
                    }
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    gap: 70px;
                `}
            >
                {attributes.data.map(attr => {
                    return (
                        <>
                            <AddAttribute key={attr.ID} attribute={attr} />
                            <Attributes key={attr.ID} attribute={attr} />
                        </>
                    );
                })}
            </section>
        </SettingsLayout>
    );
};

const AddAttribute: FC<{ attribute: Attribute }> = ({ attribute }) => {
    const irisApi = useIrisApi();

    let [name, setName] = useState('');

    const queryClient = useQueryClient();

    let addAttribute = useMutation(
        async () => {
            await irisApi.post('/api/v1/attributes', {
                value: name,
                attributeID: attribute.ID,
            });
            await queryClient.invalidateQueries('attributes');
            setName('');
        },
        { useErrorBoundary: false }
    );

    let nameError = !name && 'Namn är obligatoriskt';

    const hasErrors = nameError;

    return (
        <section
            css={css`
                max-width: 350px;
                margin-bottom: ${s(5)};
            `}
        >
            <h1>Lägg till {attribute.Key.toLowerCase()}</h1>
            <Form
                error={addAttribute.error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        addAttribute.mutate();
                        return true;
                    }
                }}
            >
                <Field label="Namn" error={nameError}>
                    <input
                        placeholder={`Namn på ${attribute.Key.toLowerCase()}`}
                        value={name}
                        autoComplete="off"
                        onChange={ev => setName(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={addAttribute.isLoading}>
                        {addAttribute.isLoading ? 'Laddar' : 'Lägg till'}
                    </Button>
                </Buttons>
            </Form>
        </section>
    );
};

const Attributes: FC<{ attribute: Attribute }> = ({ attribute }) => {
    const [showAll, setShowAll] = useState(false);

    return (
        <div key={attribute.Key}>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding-right: 16px;
                `}
            >
                <h1>{toPlural(attribute.Key)}</h1>
                <button
                    css={css`
                        border: none;
                        background: inherit;
                        text-decoration: underline #5f5f5f 0.5px;
                        font-weight: bold;
                        font-size: 16px;
                        height: 100%;
                        align-self: center;
                    `}
                    onClick={() => setShowAll(!showAll)}
                >
                    {!showAll ? 'Visa allt' : 'Dölj'}
                </button>
            </div>
            <table
                css={css`
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: auto;
                    border-spacing: 0;

                    tr {
                        border-top: 1px solid #000;
                    }

                    tr:last-of-type {
                        border-bottom: 1px solid #000;
                    }

                    td,
                    th {
                        padding: 10px 40px 10px 0;
                        text-align: left;
                    }

                    select {
                        font-size: 18px;
                        line-height: 24px;
                        display: block;
                        width: 100%;
                        border: 1px solid ${color.lightgrey};
                        border-radius: 3px;
                        padding: ${s(1)} ${s(2)};
                    }
                `}
            >
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Handling</th>
                    </tr>
                </thead>
                <tbody>
                    {showAll
                        ? attribute.Values?.map(value => (
                              <AttributeRow
                                  key={value.id}
                                  value={value}
                                  attributeID={attribute.ID}
                              />
                          ))
                        : '...'}
                </tbody>
            </table>
        </div>
    );
};

const AttributeRow: FC<{ value: AttributeValue; attributeID: number }> = ({
    value,
    attributeID,
}) => {
    const irisApi = useIrisApi();
    let queryClient = useQueryClient();

    let deleteAttribute = useMutation(async () => {
        await irisApi.delete(`/api/v1/attributes/${value.value}/${attributeID}`);
        await queryClient.invalidateQueries('attributes');
    });
    return (
        <tr>
            <td>{value.value}</td>
            <td>
                <div
                    css={css`
                        display: flex;
                        gap: 16px;
                    `}
                >
                    <button
                        onClick={() => deleteAttribute.mutate()}
                        disabled={deleteAttribute.isLoading}
                        css={css`
                            ${attributeButtons}
                        `}
                    >
                        {deleteAttribute.isLoading ? '...' : 'Ta bort'}
                    </button>
                </div>
            </td>
        </tr>
    );
};
