// This is it's own page so that we can redirect here from the iframe embed

import { css } from '@emotion/react';
import { navigate, RouteComponentProps, useLocation, useParams } from '@reach/router';
import { nanoid } from 'nanoid';
import { parse } from 'query-string';
import { FC, useEffect, useRef, useState } from 'react';
import { SodiumPlus } from 'sodium-plus';
import { useFeatureToggles, useIrisApi, useTenantData } from '../lib/api';
import { createAndKeepFriendlyName, createFriendlyName } from '../lib/friendlyName';
import { getPrivateKeyPair } from '../lib/private-key';
import * as Sentry from '@sentry/browser';
import { setSentryChatId, setSentryFriendlyName } from '../App';

export const SetupChat: FC<RouteComponentProps> = () => {
    const featureToggles = useFeatureToggles();
    const irisApi = useIrisApi();
    let { attributes } = useParams<Record<string, string>>();
    const location = useLocation();
    const query = parse(location.search);

    let [error, setError] = useState<Error>();
    const [isClient, setIsClient] = useState(false);

    const createChat = useRef(true);

    useEffect(() => {
        setIsClient(true);
    }, []);

    useEffect(() => {
        if (featureToggles.only_one_chat) {
            const timer = setTimeout(() => {
                // close broadcast channel listening for polo after 2 seconds
                bc.close();
            }, 2000);

            const bc = new window.BroadcastChannel('marco_polo');
            bc.postMessage('marco');

            bc.onmessage = async e => {
                if (e.data === 'polo') {
                    createChat.current = false;
                    await navigate(`/already-in-chat`, { replace: true });
                    bc.close();
                }
            };

            () => {
                clearTimeout(timer);
                bc.close();
            };
        }
    }, [featureToggles.only_one_chat]);

    useEffect(() => {
        const timer = setTimeout(() => {
            // only initiate chat if no other chats are in the same browser
            if (!createChat.current) {
                return;
            }
            (async () => {
                let chatSecret = nanoid();
                let chatId = nanoid();

                setSentryChatId(chatId);

                sessionStorage.setItem(`chat_${chatId}_secret`, chatSecret);
                let sodium = await SodiumPlus.auto();
                let keyPair = await getPrivateKeyPair();
                let publicKey = await sodium.crypto_box_publickey(keyPair);

                let friendlyName = '';
                if (featureToggles.keep_friendly_name) {
                    friendlyName = createAndKeepFriendlyName();
                } else {
                    friendlyName = createFriendlyName();
                }

                setSentryFriendlyName(friendlyName);

                await irisApi.put(`/api/v1/chat/${chatId}`, {
                    // Send a randomly generated chat ID. The same secrets need to be used when retriving the chat
                    chatSecret,
                    friendlyName: friendlyName,
                    publicKey: publicKey.toString('base64'),
                    attributes: JSON.parse(decodeURIComponent(attributes)),
                    enableVideo: query.video === 'true',
                });

                await navigate(`/chat/${chatId}`, { replace: true });
            })().catch(err => setError(err as Error));
        }, 2000);

        () => {
            clearTimeout(timer);
        };
    }, [attributes, featureToggles.keep_friendly_name, irisApi, query.video]);

    if (!isClient) {
        return null;
    }

    if (error) {
        Sentry.captureException(error);
        throw error;
    }

    return (
        <div
            css={css`
                font-size: 24px;
            `}
        >
            Laddar chatt
        </div>
    );
};
