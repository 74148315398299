import { css } from '@emotion/react';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { fontStyle, s, color, button } from '../styles';

const FormContext = React.createContext<{
    submitAttempted: boolean;
} | null>(null);

export const Form: FC<
    PropsWithChildren<{ onSubmit: () => void | boolean; error?: Error | false }>
> = ({ children, onSubmit, error }) => {
    const [submitAttempted, setSubmitAttempted] = React.useState(false);

    return (
        <FormContext.Provider value={{ submitAttempted }}>
            <form
                onSubmit={ev => {
                    ev.preventDefault();
                    setSubmitAttempted(true);
                    let success = onSubmit();
                    if (success) {
                        // Reset when successful
                        setSubmitAttempted(false);
                    }
                }}
                method="post"
                css={css`
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                `}
            >
                {error && (
                    <div
                        role="alert"
                        css={css`
                            padding: ${s(1)} ${s(2)};
                            background: ${color.negativeBg};
                            color: ${color.negative};
                            border-radius: 5px;
                            margin: ${s(2)} 0;
                        `}
                    >
                        {error.message || error.toString()}
                    </div>
                )}
                {children}
            </form>
        </FormContext.Provider>
    );
};

export const Field: FC<PropsWithChildren<{ label: string; error?: string | false }>> = ({
    label,
    error,
    children,
}) => {
    let context = useContext(FormContext);

    if (!context) {
        throw new Error('Field needs to be inside a Form');
    }
    let { submitAttempted } = context;
    let showError = error && submitAttempted;

    return (
        <label
            css={css`
                display: block;
                ${fontStyle.label};
                margin-bottom: ${s(4)};

                input,
                textarea,
                select {
                    font-size: 18px;
                    line-height: 24px;
                    display: block;
                    width: 100%;
                    border: 1px solid ${color.lightgrey};
                    border-radius: 3px;
                    padding: ${s(1)} ${s(2)};

                    ${showError &&
                    css`
                        border-color: ${color.negative};
                    `}
                }
            `}
        >
            {label}
            {children}
            {showError && (
                <div
                    role="alert"
                    css={css`
                        color: ${color.negative};
                    `}
                >
                    {error}
                </div>
            )}
        </label>
    );
};

export const CheckBox: FC<{
    label: string;
    value: boolean;
    onChange: (checked: boolean) => void;
}> = ({ label, value, onChange }) => {
    return (
        <label css={css``}>
            <input
                type="checkbox"
                checked={value}
                onChange={ev => onChange(ev.target.checked)}
                css={css`
                    margin-right: ${s(1)};
                `}
            />
            {label}
        </label>
    );
};

const RadioButtonsContext = React.createContext<{
    name: string;
    value: string;
    onChange: (value: string) => void;
} | null>(null);

export const RadioButtons: FC<
    PropsWithChildren<{
        label: string;
        name: string;
        value: string;
        onChange: (value: string) => void;
    }>
> = ({ label, children, name, onChange, value }) => {
    return (
        <RadioButtonsContext.Provider value={{ name, onChange, value }}>
            <div>
                <div
                    css={css`
                        margin-bottom: ${s(1)};
                    `}
                >
                    {label}
                </div>
                <div
                    css={css`
                        display: flex;
                        margin-bottom: ${s(4)};
                    `}
                >
                    {children}
                </div>
            </div>
        </RadioButtonsContext.Provider>
    );
};

export const Buttons: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
    return (
        <div
            className={className}
            css={css`
                align-self: flex-end;
                margin-bottom: ${s(2)};
                display: flex;
            `}
        >
            {children}
        </div>
    );
};

export const Button: FC<
    { secondary?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ secondary, ...props }) => {
    let style = secondary ? button : button;

    return (
        <button
            {...props}
            css={[
                style,
                {
                    marginRight: s(1),
                },
            ]}
        />
    );
};
