import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useIrisApi } from '../lib/api';
import * as Sentry from '@sentry/browser';
import { useMutation } from 'react-query';
import { useParams } from '@reach/router';
import { Cross2Icon } from '@radix-ui/react-icons';

export const Feedback: FC<{
    chatId: string;
}> = ({ chatId }) => {
    const irisApi = useIrisApi();

    const feedbackEmojis = ['🙁', '😐', '🙂', '😁'];

    const [comment, setComment] = useState('');
    const [chosenEmoji, setChosenEmoji] = useState(-1);
    const [submitted, setSubmitted] = useState(false);

    let { mutate } = useMutation(
        async () => {
            try {
                await irisApi.post(`/api/v1/chat/${chatId}/feedback`, {
                    feedback: {
                        emoji: chosenEmoji,
                        comment: comment,
                    },
                });
            } catch (error) {
                Sentry.captureException(error);
                throw error;
            }
        },
        { useErrorBoundary: false }
    );

    const submitButtonFilter = submitted
        ? css`
              filter: grayscale(60%);
          `
        : css`
              filter: none;
          `;

    return (
        <div
            css={css`
                max-width: 370px;
                height: auto;
                padding: 16px;
                display: flex;
                flex-direction: column;
                border-size: 1px;
                border-style: solid;
                margin-bottom: 24px;
                gap: 4px;
                background-color: #fff;
                border-color: #e2d4f5;
                box-shadow: 0 0 0.3rem 0.1rem #e2d4f5;
            `}
        >
            <div
                css={css`
                    max-width: 344px;
                    display: flex;
                    width: 100%;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        font-weight: bold;
                        justify-content: flex-start;
                        font-size: 17px;
                    `}
                >
                    Hur upplevde du chatten?
                </div>
            </div>
            <div
                css={css`
                    font-style: italic;
                    font-size: 15px;
                    justify-content: flex-start;
                `}
            >
                Det du delar här sammanställs tillsammans med de svar alla andra gett och används
                för att göra chatten bättre.
            </div>
            <div
                css={css`
                    display: flex;
                    align-self: center;
                    gap: 24px;
                    margin: 16px 0 8px 0;
                `}
            >
                {feedbackEmojis.map((el, index) => {
                    const filter =
                        index === chosenEmoji
                            ? css`
                                  border: 2px solid #5f5f5f;
                                  background: #ededed;
                                  border-radius: 8px;
                              `
                            : css``;
                    return (
                        <button
                            key={index}
                            css={css`
                                background: none;
                                color: 'inherit';
                                border: none;
                                padding: 0 6px;
                                ${filter}

                                @media(min-width: 600px) {
                                    font-size: 36px;
                                }
                            `}
                            onClick={() => {
                                setChosenEmoji(index);
                            }}
                        >
                            {el}
                        </button>
                    );
                })}
            </div>
            {chosenEmoji !== -1 ? (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        justify-content: flex-end;
                        gap: 8px;
                        font-size: 15px;
                    `}
                >
                    <div
                        css={css`
                            font-weight: bold;
                            width: 80%;
                        `}
                    >
                        Dela gärna en kommentar på din reaktion:
                    </div>
                    <input
                        type="text"
                        name="comment"
                        value={comment}
                        placeholder={'Skriv här...'}
                        onChange={event => {
                            setComment(event.target.value);
                        }}
                        css={css`
                            border: 0;
                            outline: 0;
                            background: transparent;
                            border-bottom: 1px solid black;
                            width: 80%;
                            font-size: 15px;
                            margin-top: 8px;
                            ::placeholder {
                                font-style: italic;
                            }
                        `}
                    />
                    <button
                        onClick={() => {
                            if (chosenEmoji !== -1) {
                                mutate();
                                setSubmitted(true);
                            }
                        }}
                        css={css`
                            border-radius: 8px;
                            border: 1px solid #e2d4f5;
                            background: #e2d4f5;
                            padding: 8px 16px;
                            align-self: flex-end;
                            color: #5d477a;
                            font-size: 14px;
                            font-weight: bold;
                            margin-top: 24px;
                            ${submitButtonFilter}
                        `}
                    >
                        Skicka svar
                    </button>
                </div>
            ) : (
                <div />
            )}
            {submitted ? (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        width: 60%;
                        height: 100%;
                        gap: 8px;
                        align-self: center;
                        text-align: center;
                        margin-top: 24px;
                        font-weight: bold;
                    `}
                >
                    <div
                        css={css`
                            font-size: 16px;
                            align-content: center;
                        `}
                    >
                        Tack för din feedback!
                    </div>
                    <a
                        css={css`
                            font-size: 12px;
                            color: #8d71b4;
                            text-decoration: underline;
                        }
                        `}
                        rel="noreferrer"
                        href={`/more-feedback/${chatId}`}
                        target="_blank"
                    >
                        {' '}
                        Har du tid att svara på några fler frågor?
                    </a>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};
