import { css } from '@emotion/react';
import { UnderlineIcon } from '@radix-ui/react-icons';
import { tint } from 'polished';

export const s = (size: number): string => `${size * 8}px`;

const negative = '#A80936';

export const color = {
    lightgrey: 'rgba(0, 0, 0, 0.5)',
    lighterGrey: '#E4E4E3',
    black: '#2b2929',
    negative,
    negativeBg: tint(0.95, negative),
    lavendel: '#E8D5F7',
    redCrossRed: '#FF0003',
    red: '#E3000B',
    darkerRed: '#B60009',
    redTint: 'rgba(227, 0, 11, 0.05)',
    lila: '#28117D',
};

export const h1 = css`
    margin: 0 0 16px;
    font-size: 20px;
    font-weight: 700;
`;

export const fontStyle = {
    label: css`
        font-size: 13px;
        font-weight: bold;
        line-height: 24px;
    `,
};

export const buttonReset = css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    background: none;
`;

export const button = {
    borderRadius: 3,
    border: 0,
    color: "#fff",
    backgroundColor: color.red,
    padding: '8px 16px',
    ':hover': {
        background: color.darkerRed,
    },
    
};

export const attributeButton = {
    borderRadius: 3,
    border: "1px solid " + color.darkerRed,
    color: color.darkerRed,
    backgroundColor: "#fff",
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    ':hover': {
        background: color.redTint,
    },  
};

export const attributeButtons = css`
    border-radius: 3px;
    border: 1px solid ${color.darkerRed};
    color: ${color.darkerRed};
    background-color: #fff;
    padding: 8px 16px;
    white-space: nowrap;
    &:hover {
        background-color: ${color.redTint};
    };
`;




export const lavendelButton = css`
    ${buttonReset};
    border-radius: 3px;
    border: none;
    color: ${color.black};
    background: ${color.lavendel};
    padding: 8px 16px;
    &:hover {
        background-color: ${color.lila};
        color: #fff;
    };
`;

export const greyButton = css`
    ${buttonReset};
    border-radius: 3px;
    border: 1px solid ${color.lighterGrey};
    color: ${color.black};
    background: #fff;
    padding: 8px 16px;
    &:hover {
        border: 1px solid ${color.black};
    };
`;
