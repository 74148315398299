import { css } from '@emotion/react';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CryptographyKey, SodiumPlus } from 'sodium-plus';
import { useAuthStatus, useBaseUrl, useFeatureToggles, useIrisApi } from '../lib/api';
import { getPrivateKeyPair } from '../lib/private-key';
import { createMapWithKey, hasTouchScreen } from '../lib/utils';
import { button, buttonReset, color, lavendelButton } from '../styles';
import { PellEditor } from './PellEditor';
import * as Sentry from '@sentry/browser';
import { useQueueStatus } from '../lib/use-queue-status';
import { DownloadSimple } from 'phosphor-react';
import { useQueuePosition } from '../lib/use-queue-position';
import useSound from 'use-sound';

type ChatData = {
    createdAt: string;
    id: string;
    friendlyName: string;
    messages: DecryptedIrisMessage[];
    systemMessages: IrisSystemMessage[];
    files: DecryptedIrisFile[];
    users: Record<
        string,
        {
            UserID: string;
            UserName: string;
            HasLeft: string;
            IsTyping?: string;
            ReadMark: string;
        }
    >;
    allUsersHasLeft: boolean;
    isClaimed: boolean;
    encryptionKey: CryptographyKey;
    me: string;
    attributes: Record<string, string>;
    videoId: string;
    isTyping?: string;
    closed: boolean;
    closedReason?: 'lost-connection' | 'queue' | 'seeker' | 'volunteer';
    closedAt?: string;
    readMark: string;
};

const ONE_HOUR_IN_MILLI = 3_600_000;

// chatId is always needed. chatSecret is only needed for non-logged-in-users
export const Chat: FC<{
    chatId: string;
    archiveMode?: boolean;
    chatSecret?: string;
    playChatSounds?: boolean;
}> = ({ chatId, chatSecret, archiveMode = false, playChatSounds = false }) => {
    const baseUrl = useBaseUrl();
    const [now, setNow] = useState(() => new Date());
    const inputFieldInFocus = useRef(false);
    const unMarkedMessages = useRef(false);

    const [touchScreen, setTouchScreen] = useState(false);

    const iris_chat = require('../assets/sounds/iris_chat.mp3');
    const [playNewChatSound] = useSound(iris_chat);

    useEffect(() => {
        let interval = setInterval(() => {
            setNow(new Date());
        }, 5000);

        setTouchScreen(hasTouchScreen(window));

        return () => {
            clearInterval(interval);
        };
    }, []);

    const [input, setInput] = useState('');

    useEffect(() => {
        setInput('');
    }, [chatId]);

    const queryClient = useQueryClient();
    const authStatus = useAuthStatus();
    const messagesDiv = useRef<HTMLDivElement>(null);
    const apiBaseUrl = useBaseUrl();
    const irisApi = useIrisApi();
    const featureToggles = useFeatureToggles();

    const setReadMark = useCallback(
        (readMark: string | undefined) => {
            if (!readMark) {
                return;
            }

            if (inputFieldInFocus.current) {
                (async () => {
                    await irisApi.post(`/api/v1/chat/${chatId}/read-mark`, {
                        readMark,
                        chatSecret,
                    });

                    await queryClient.invalidateQueries('chat_list');
                })()
                    .then(() => {
                        unMarkedMessages.current = false;
                    })
                    .catch(err => {
                        console.error('Error setting read mark', err);
                        Sentry.captureException(err);
                    });
            } else {
                unMarkedMessages.current = true;
            }
        },
        [chatId, chatSecret, irisApi, queryClient]
    );

    const isTyping = useRef(false);
    const sendIsTypingTimeout = useRef<null | NodeJS.Timeout>(null);

    useEffect(() => {
        sendIsTypingTimeout.current = null;
    }, [chatId]);

    useEffect(() => {
        // Send if the user is typing at most every 1000ms
        if (input.length == 0 || input === '<br>') {
            isTyping.current = false;
        } else {
            isTyping.current = true;
        }

        async function sendIsTyping() {
            sendIsTypingTimeout.current = null;
            await irisApi.post(`/api/v1/chat/${chatId}/isTyping`, {
                isTyping: isTyping.current,
                chatSecret,
            });
        }

        if (!sendIsTypingTimeout.current) {
            sendIsTypingTimeout.current = setTimeout(() => {
                sendIsTyping().catch(err => console.error(err));
            }, 1000);
        }

        // When input is changing, send a "is typing" message
    }, [input, irisApi, chatId, chatSecret]);

    // Using useQuery + websockets means that when for instance the user returns to the page,
    // they'll get new messages. We'll update the chat-data when we get data on the websocket
    let chatData = useQuery<ChatData>(['chat_data', chatId], async () => {
        let sodium = await SodiumPlus.auto();
        let keyPair = await getPrivateKeyPair();
        let publicKey = await sodium.crypto_box_publickey(keyPair);
        let privateKey = await sodium.crypto_box_secretkey(keyPair);

        // If chatSecret is specified and the chat exists, it needs to be the same as was used when
        // the chat was created.
        //
        // If chatSecret is undefined, the current user needs to be logged in and a part of the
        // chat.
        let chatRes = await irisApi.get<{
            id: string;
            createdAt: string;
            friendlyName: string;
            messages?: Array<IrisMessage>;
            systemMessages?: Array<IrisSystemMessage>;
            files?: Array<IrisFile>;
            users?: Array<{
                UserID: string;
                UserName: string;
                HasLeft: string;
                IsTyping: string;
                ReadMark: string;
            }>;
            encryptedEncryptionKey: string;
            isClaimed: boolean;
            allUsersHasLeft: boolean;
            me: string;
            attributes: Record<string, string>;
            videoId: string;
            isTyping: string;
            closed: boolean;
            closedReason?: ChatData['closedReason'];
            closedAt: string;
            readMark: string;
        }>(`/api/v1/chat/${chatId}`, {
            params: {
                // Go likes empty strings better than null
                chatSecret: chatSecret || '',
                // Note: If chatSecret is set, the publicKey needs to be the same as was used when
                // creating the chat. This is because chatSecrets are not as secure as the cookie,
                // since it is being sent as query param. While the session secret is only used as a
                // cookie. Also the support seeker will only ever use one browser, therefore we can
                // require them to always send the same public key
                publicKey: publicKey.toString('base64'),
            },
        });

        let chat = chatRes.data;

        let encryptionKey = new CryptographyKey(
            await sodium.crypto_box_seal_open(
                Buffer.from(chat.encryptedEncryptionKey, 'base64'),
                publicKey,
                privateKey
            )
        );

        setReadMark(chat.messages?.[chat.messages.length - 1]?.CreatedAt);

        return {
            id: chat.id,
            createdAt: chat.createdAt,
            friendlyName: chat.friendlyName,
            messages: await Promise.all(
                (chat.messages || []).map(msg => mapIrisMessage(msg, encryptionKey))
            ),
            files: await Promise.all(
                (chat.files || []).map(file => mapIrisFile(file, encryptionKey))
            ),
            systemMessages: chat.systemMessages || [],
            users: createMapWithKey(u => u.UserID.toString(), chat.users || []),
            allUsersHasLeft: chat.allUsersHasLeft,
            isClaimed: chat.isClaimed,
            encryptionKey: encryptionKey,
            me: chat.me,
            attributes: chat.attributes,
            videoId: chat.videoId,
            isTyping: chat.isTyping,
            closed: chat.closed,
            closedReason: chat.closedReason,
            closedAt: chat.closedAt,
            readMark: chat.readMark,
        };
    });

    useEffect(() => {
        if (chatData.status != 'success' || archiveMode) {
            // Don't start SSE connection until after we have checked that the chat exists
            // Or if the chat is openend in archiveMode
            return;
        }

        let evtSource: EventSource | null = null;
        let closed = false;
        let reconnectTimer: NodeJS.Timeout | null = null;

        function connect() {
            if (evtSource) {
                evtSource.close();
                evtSource = null;
            }

            let url = `${apiBaseUrl}/api/v1/chat/${chatId}/sse`;
            if (chatSecret) {
                url += `?chat-secret=${chatSecret}`;
            }
            evtSource = new EventSource(url, {
                withCredentials: true,
            });

            evtSource.addEventListener('chat_message', function (event_) {
                (async () => {
                    let event = event_ as MessageEvent;

                    const data: ChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        let msg = await mapIrisMessage(
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            JSON.parse(event.data) as IrisMessage,
                            data.encryptionKey
                        );
                        setReadMark(msg.CreatedAt);
                        data.messages.push(msg);

                        // Reset "is typing" asap because it's weird when it takes a second for "skriver..." to dissappear
                        if (msg.isSupportSeeker) {
                            data.isTyping = undefined;
                        } else {
                            data.users[msg.userId].IsTyping = undefined;
                        }

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                })().catch(err => {
                    console.error(`Error parsing SSE message`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                });
            });

            evtSource.addEventListener('chat_file', function (event_) {
                (async () => {
                    let event = event_ as MessageEvent;
                    // Cancel any queries so they don't overwrite our update
                    await queryClient.cancelQueries(['chat_data', chatId]);

                    const data: ChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        let msg = await mapIrisFile(
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            JSON.parse(event.data) as IrisFile,
                            data.encryptionKey
                        );
                        setReadMark(msg.CreatedAt);
                        data.files.push(msg);
                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                })().catch(err => {
                    console.error(`Error parsing SSE file`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                });
            });

            evtSource.addEventListener('user_is_typing', function (event_) {
                try {
                    let event = event_ as MessageEvent;

                    const data: ChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        let msg = JSON.parse(event.data) as { UserID: string; IsTyping: boolean };

                        let value = msg.IsTyping ? new Date().toISOString() : undefined;

                        if (msg.UserID === 'seeker') {
                            data.isTyping = value;
                        } else {
                            data.users[msg.UserID].IsTyping = value;
                        }

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                } catch (err) {
                    console.error(`Error parsing SSE is typing`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                }
            });

            evtSource.addEventListener('user_read_mark', function (event_) {
                try {
                    let event = event_ as MessageEvent;

                    const data: ChatData | undefined = queryClient.getQueryData([
                        'chat_data',
                        chatId,
                    ]);
                    if (data) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        let msg = JSON.parse(event.data) as { UserID: string; ReadMark: string };

                        let value = msg.ReadMark ? msg.ReadMark : '';

                        if (msg.UserID === 'seeker') {
                            data.readMark = value;
                        } else {
                            data.users[msg.UserID].ReadMark = value;
                        }

                        queryClient.setQueryData(['chat_data', chatId], data);
                    }
                } catch (err) {
                    console.error(`Error parsing SSE is typing`, err);
                    Sentry.captureException(err);
                    // Force a re-fetch, since data is now probably out of date
                    queryClient
                        .invalidateQueries(['chat_data', chatId])
                        .catch(err => console.error(err));
                }
            });

            evtSource.addEventListener('chat_updated', () => {
                // Generic event that just says "refresh data"
                queryClient
                    .invalidateQueries(['chat_data', chatId])
                    .catch(err => console.error(err));
            });

            evtSource.addEventListener('ping', () => {
                irisApi
                    .post(`/api/v1/chat/${chatId}/ping`, { chatSecret })
                    .catch(err => console.error('Error pinging chat', err));
            });

            evtSource.onerror = error => {
                Sentry.captureException(error);
                if (evtSource?.readyState === 2 && !closed) {
                    // Force a reconnect if event source is closed
                    console.log('reconnecting');
                    if (reconnectTimer) {
                        clearTimeout(reconnectTimer);
                    }
                    reconnectTimer = setTimeout(() => connect(), 5000);
                }
            };
        }

        connect();

        return () => {
            closed = true;
            if (evtSource) {
                evtSource.close();
            }
            evtSource = null;
        };
    }, [chatId, chatData.status, apiBaseUrl, chatSecret, queryClient, setReadMark, irisApi]);

    useLayoutEffect(() => {
        if (messagesDiv.current) {
            messagesDiv.current.scrollTo(0, messagesDiv.current.scrollHeight);
        }

        const me = chatData.data?.me;

        if (me !== 'seeker' || !playChatSounds) {
            return;
        }

        const numUnreadMessages =
            chatData.data?.messages?.filter(
                msg => msg.CreatedAt > chat.readMark && !msg.isSupportSeeker
            ).length ?? 0;

        if (numUnreadMessages == 0) {
            return;
        }

        // Only play new chat sound if the user is a seeker
        playNewChatSound();
    }, [
        chatData.data?.messages.length,
        chatData.data?.systemMessages.length,
        chatData.data?.files.length,
    ]);

    if (chatData.isLoading || authStatus.isLoading || !chatData.data || !authStatus.data) {
        return <div>Laddar...</div>;
    }

    let chat = chatData.data;

    // When chatSecret is set, we are a "support seeker". If not, we should be a logged in user
    let me = chatData.data.me;

    let whoIsTyping = Object.values(chat.users || {})
        .filter(
            user =>
                user.UserID !== me &&
                user.IsTyping &&
                new Date(user.IsTyping).getTime() > now.getTime() - 30000 &&
                !chat.closed
        )
        .map(u => u.UserName);
    if (
        me !== 'seeker' &&
        chat.isTyping &&
        new Date(chat.isTyping).getTime() > now.getTime() - 30000 &&
        !chat.closed
    ) {
        whoIsTyping.push('Eleven');
    }

    if (chat.closed && me === 'seeker') {
        return <Closed />;
    }

    if (!chat.isClaimed && !archiveMode) {
        return <InQueue chatId={chat.id} />;
    }

    let messages = mergeMessages(chatData.data);

    let userReadMark: Date | null = null;
    if (me !== 'seeker') {
        userReadMark = new Date(chat.readMark);
    } else {
        let biggest: Date | null = null;
        Object.entries(chat.users).forEach(([k, v]) => {
            let d = new Date(v.ReadMark);
            if (biggest == null || d > biggest) {
                biggest = d;
            }
        });
        userReadMark = biggest;
    }

    let lastSeenMessageId: number | null = null;
    if (userReadMark) {
        let filteredList = messages.filter(
            message => userReadMark! >= new Date(message.CreatedAt) && message.type === 'user'
        ) as DecryptedIrisMessage[];
        lastSeenMessageId = filteredList.length > 0 ? filteredList[filteredList.length - 1].id : -1;
    }

    let closedMoreThanHourAgo = false;

    if (chat.closed && chat.closedAt !== undefined) {
        const closedTime = new Date(chat.closedAt);

        if (now.getTime() - closedTime.getTime() > ONE_HOUR_IN_MILLI) {
            closedMoreThanHourAgo = true;
        }
    }

    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            `}
        >
            <div>
                {chat.videoId && (
                    <a
                        href={`https://meet.jit.si/${chat.videoId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={css`
                            ${lavendelButton};
                            display: block;
                            margin-bottom: 16px;
                        `}
                    >
                        Öppna videochatt
                    </a>
                )}
            </div>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    ${touchScreen && !archiveMode ? 'margin-top: auto;' : ''};
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        overflow-y: scroll;
                        max-height: 20vh;

                        // Please make this better if you can.
                        @media (min-height: 500px) {
                            height: 45vh;
                            max-height: 45vh;
                        }

                        @media (min-height: 720px) {
                            height: 60vh;
                            max-height: 60vh;
                        }

                        @media (min-height: 1080px) {
                            height: 75vh;
                            max-height: 75vh;
                        }

                        padding-right: 8px;
                    `}
                    ref={messagesDiv}
                >
                    {messages.map((message, index) => {
                        if (message.type === 'user') {
                            let sanitizedHtml = DOMPurify.sanitize(message.content.trim(), {
                                USE_PROFILES: { html: true },
                            });
                            let user = chat.users[message.userId];
                            let seen = false;

                            if (message.id === lastSeenMessageId) {
                                seen = true;
                            }

                            return (
                                <div
                                    key={`user_${message.id}`}
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 24px;
                                        ${message.userId === me
                                            ? 'align-self: flex-end'
                                            : 'align-self: flex-start'};
                                    `}
                                >
                                    <div
                                        css={css`
                                            font-size: 12px;
                                            line-height: 1.5;
                                            ${message.userId === me
                                                ? 'align-self: flex-end'
                                                : 'align-self: flex-start'};
                                        `}
                                    >
                                        {format(new Date(message.CreatedAt), 'HH:mm')}
                                    </div>
                                    <div
                                        css={css`
                                            ${message.userId === me
                                                ? 'align-self: flex-end'
                                                : 'align-self: flex-start'};
                                            padding: 8px;
                                            background: ${message.userId === me
                                                ? color.lavendel
                                                : '#ececec'};
                                            border-radius: 4px;
                                            max-width: 600px;

                                            p:first-of-type {
                                                margin-top: 0;
                                            }
                                            p:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        `}
                                        key={message.id}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizedHtml,
                                            }}
                                        />
                                    </div>
                                    <div
                                        css={css`
                                            font-size: 12px;
                                            font-weight: bold;
                                            line-height: 1.5;
                                            padding: 0 2px;
                                            ${message.userId === me
                                                ? 'align-self: flex-end'
                                                : 'align-self: flex-start'};
                                        `}
                                    >
                                        {seen && message.userId === me ? 'Sett' : ''}
                                    </div>
                                    {/* Show name, but only if the feature is enabled (Volunteers always see the name) */}
                                    {user &&
                                        (me != 'seeker' || featureToggles.show_names) &&
                                        message.userId !== me && (
                                            <div
                                                css={css`
                                                    font-size: 12px;
                                                    line-height: 1.5;
                                                    ${message.userId === me
                                                        ? 'align-self: flex-end'
                                                        : 'align-self: flex-start'};
                                                `}
                                            >
                                                {user.UserName}
                                            </div>
                                        )}
                                </div>
                            );
                        }
                        if (message.type === 'system') {
                            return (
                                <div
                                    key={`system_${message.ID}`}
                                    css={css`
                                        font-style: italic;
                                        margin-bottom: 24px;
                                    `}
                                >
                                    {message.Message}
                                </div>
                            );
                        }
                        if (message.type === 'file') {
                            let user = chat.users[message.userId];
                            return (
                                <a
                                    href={
                                        chatSecret
                                            ? `${baseUrl}/api/v1/chat/${chatId}/file/${message.id}?chatSecret=${chatSecret}`
                                            : `${baseUrl}/api/v1/chat/${chatId}/file/${message.id}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    css={css`
                                        ${buttonReset};
                                        text-align: left;
                                        margin-bottom: 24px;
                                        ${message.userId === me
                                            ? 'align-self: flex-end'
                                            : 'align-self: flex-start'};
                                    `}
                                >
                                    <div
                                        css={css`
                                            display: grid;
                                            border: 1px solid ${color.lightgrey};
                                            padding: 16px;
                                            grid-template-areas:
                                                'name icon'
                                                'filesize icon';
                                            grid-gap: 8px 16px;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                grid-area: name;
                                                font-weight: bold;
                                                font-size: 16px;
                                            `}
                                        >
                                            {shortenFileName(message.filename)}
                                        </div>
                                        <div
                                            css={css`
                                                grid-area: filesize;
                                                font-size: 14px;
                                            `}
                                        >
                                            {humanFileSize(message.filesize)}
                                        </div>
                                        <div
                                            css={css`
                                                grid-area: icon;
                                            `}
                                        >
                                            <DownloadSimple />
                                        </div>
                                    </div>
                                    {user && me != 'seeker' && message.userId !== me && (
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                line-height: 1.5;
                                                ${message.userId === me
                                                    ? 'align-self: flex-end'
                                                    : 'align-self: flex-start'};
                                            `}
                                        >
                                            {user.UserName}
                                        </div>
                                    )}
                                </a>
                            );
                        }
                    })}
                    {chat.closedReason === 'lost-connection' && closedMoreThanHourAgo ? (
                        <p>
                            <i>
                                <b>Användaren tappade anslutningen</b>
                            </i>
                        </p>
                    ) : null}
                </div>
            </div>

            {archiveMode ? (
                <div>
                    <button
                        css={css`
                            ${button};
                        `}
                        type="button"
                        onClick={() => {
                            let content = `${chat.friendlyName}\n`;

                            content += `Participants: ${Object.values(chat.users)
                                .map(u => u.UserName)
                                .join(', ')}\n\n`;

                            chat.messages.forEach(message => {
                                let user = chat.users[message.userId]?.UserName || message.userId;

                                content += `${user} @ ${format(
                                    new Date(message.CreatedAt),
                                    'HH:mm'
                                )}: ${message.content}\n\n`;
                            });

                            let encodedUri = encodeURI(`data:text/plain;charset=utf-8,${content}`);
                            let link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', `${chat.friendlyName}.txt`);
                            document.body.appendChild(link);

                            link.click();
                        }}
                    >
                        Ladda ned
                    </button>
                </div>
            ) : (
                <PellEditor
                    me={me}
                    whoIsTyping={whoIsTyping}
                    input={input}
                    setInput={setInput}
                    chatId={chatId}
                    chatSecret={chatSecret}
                    encryptionKey={chatData.data.encryptionKey}
                    setTextFieldFocus={(value: boolean) => {
                        inputFieldInFocus.current = value;
                        if (unMarkedMessages.current && value) {
                            setReadMark(chat.messages?.[chat.messages.length - 1]?.CreatedAt);
                        }
                    }}
                />
            )}
        </div>
    );
};

function mergeMessages(chatData: ChatData): MergedMessage[] {
    let attribuesMessages: MergedMessage[] = chatData.attributes['Meddelande']
        ? [
              {
                  type: 'user',
                  CreatedAt: chatData.createdAt,
                  id: -11010,
                  content: chatData.attributes['Meddelande'],
                  userId: 'seeker',
                  isSupportSeeker: true,
              },
          ]
        : [];

    let messagesMerged: MergedMessage[] = chatData.messages.map(m => ({ type: 'user', ...m }));
    let systemMerged: MergedMessage[] = (chatData.systemMessages || []).map(m => ({
        type: 'system',
        ...m,
    }));
    let filesMerged: MergedMessage[] = chatData.files.map(m => ({ type: 'file', ...m }));

    // Since both lists are sorted, this could be done in O(n), but this should be more than fast enough
    let merged = [...attribuesMessages, ...messagesMerged, ...systemMerged, ...filesMerged];
    merged.sort((a, b) => a.CreatedAt.localeCompare(b.CreatedAt));

    return merged;
}

type MergedMessage =
    | ({ type: 'user' } & DecryptedIrisMessage)
    | ({ type: 'system' } & IrisSystemMessage)
    | ({ type: 'file' } & DecryptedIrisFile);

type IrisMessage = {
    ID: number;
    CreatedAt: string;
    EncryptedMessage: string;
    Nonce: string;
    UserID: string;
    IsSupportSeeker: boolean;
};

type IrisFile = {
    ID: number;
    CreatedAt: string;
    EncryptedFilename: string;
    ContentNonce: string;
    FilenameNonce: string;
    ContentType: string;
    Filesize: number;
    UserID: string;
    IsSupportSeeker: boolean;
};

type DecryptedIrisFile = {
    CreatedAt: string;
    id: number;
    filename: string;
    contentType: string;
    contentNonce: string;
    filesize: number;
    userId: string;
    isSupportSeeker: boolean;
};

type DecryptedIrisMessage = {
    CreatedAt: string;
    id: number;
    content: string;
    userId: string;
    isSupportSeeker: boolean;
};

type IrisSystemMessage = {
    ID: number;
    CreatedAt: string;
    Message: string;
};

async function mapIrisMessage(
    msg: IrisMessage,
    encryptionKey: CryptographyKey
): Promise<DecryptedIrisMessage> {
    let sodium = await SodiumPlus.auto();
    let content = await sodium.crypto_secretbox_open(
        Buffer.from(msg.EncryptedMessage, 'base64'),
        Buffer.from(msg.Nonce, 'base64'),
        encryptionKey
    );

    return {
        id: msg.ID,
        CreatedAt: msg.CreatedAt,
        content: content.toString('utf-8'),
        userId: msg.UserID,
        isSupportSeeker: msg.IsSupportSeeker,
    };
}

async function mapIrisFile(
    file: IrisFile,
    encryptionKey: CryptographyKey
): Promise<DecryptedIrisFile> {
    let sodium = await SodiumPlus.auto();
    let filename = await sodium.crypto_secretbox_open(
        Buffer.from(file.EncryptedFilename, 'base64'),
        Buffer.from(file.FilenameNonce, 'base64'),
        encryptionKey
    );

    return {
        id: file.ID,
        CreatedAt: file.CreatedAt,
        filename: filename.toString('utf-8'),
        contentNonce: file.ContentNonce,
        userId: file.UserID,
        isSupportSeeker: file.IsSupportSeeker,
        contentType: file.ContentType,
        filesize: file.Filesize,
    };
}

const InQueue: FC<{ chatId: string }> = ({ chatId }) => {
    const queueStatus = useQueueStatus();
    const featureToggles = useFeatureToggles();
    const queuePosition = useQueuePosition(chatId);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
            `}
        >
            {featureToggles.show_queue_position &&
            queuePosition.data &&
            queuePosition.data.queuePosition > 0 ? (
                <h4>Du är nummer {queuePosition.data?.queuePosition} i kön</h4>
            ) : (
                <h4>Du står nu i kö</h4>
            )}
            {featureToggles.show_queue_length && queueStatus.data && (
                <>
                    <div
                        css={css`
                            font-size: 20px;
                        `}
                    >
                        Det är just nu {queueStatus.data.queueLength}{' '}
                        {queueStatus.data.queueLength == 1 ? 'elev' : 'elever'} i kö
                    </div>
                </>
            )}
        </div>
    );
};

const Closed: FC = () => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
            `}
        >
            <h4>Chatten är avslutad</h4>
        </div>
    );
};
function shortenFileName(filename: string): string {
    if (filename.length < 25) {
        return filename;
    }

    let lastPart = filename.substring(filename.length - 5);
    let firstPart = filename.substring(0, 15);

    return `${firstPart}...${lastPart}`;
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

function typedarrayToBuffer(arr: ArrayBuffer) {
    return ArrayBuffer.isView(arr)
        ? // To avoid a copy, use the typed array's underlying ArrayBuffer to back
          // new Buffer, respecting the "view", i.e. byteOffset and byteLength
          Buffer.from(arr.buffer, arr.byteOffset, arr.byteLength)
        : // Pass through all other types to `Buffer.from`
          Buffer.from(arr);
}
