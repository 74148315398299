import { css } from '@emotion/react';
import { navigate, RouteComponentProps, useParams } from '@reach/router';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { Form, Field, Buttons, Button } from '../components/Form';
import { useIrisApi } from '../lib/api';
import { color, h1 } from '../styles';
import * as Sentry from '@sentry/browser';

export const ResetPassword: FC<RouteComponentProps> = () => {
    const irisApi = useIrisApi();
    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    let { mutate, isLoading, error } = useMutation(
        async () => {
            try {
                await irisApi.post('/api/v1/auth/reset-password', { password, token });
                await navigate('/frivillig');
            } catch (error) {
                Sentry.captureException(error);
                throw error;
            }
        },
        { useErrorBoundary: false }
    );

    const passwordError = !password && 'Lösenord är obligatoriskt';
    const repeatPasswordError = repeatPassword != password && 'Lösenorden matchar inte';

    const hasErrors = repeatPasswordError || passwordError;

    return (
        <div
            css={css`
                max-width: 800px;
                margin: 0 auto;
                margin-top: 20vh;
                border: 1px solid ${color.lightgrey};
                border-radius: 3px;
                padding: 24px;
            `}
        >
            <h1 css={h1}>Reset Password</h1>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        mutate();
                    }
                }}
            >
                <Field label="Lösenord" error={passwordError}>
                    <input
                        placeholder="*****"
                        type="password"
                        value={password}
                        autoComplete="new-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <Field label="Bekräfta lösenord" error={repeatPasswordError}>
                    <input
                        placeholder="*****"
                        type="password"
                        value={repeatPassword}
                        autoComplete="new-password"
                        onChange={ev => setRepeatPassword(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={isLoading}>{isLoading ? 'Laddar' : 'Ange lösenord'}</Button>
                </Buttons>
            </Form>
        </div>
    );
};
