import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { FC, useEffect, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { Attribute, useAttributes, useAuthStatus, useIrisApi } from '../../lib/api';
import { Button, Buttons, Field, Form } from '../../components/Form';
import { attributeButtons, color, s } from '../../styles';
import { SettingsLayout } from './SettingsLayout';
import { toPlural } from '../../lib/utils';
import { ArrowDown, ArrowUp, Funnel } from 'phosphor-react';
import { format, formatISO, subDays } from 'date-fns';

export const UserAdmin: FC<RouteComponentProps> = () => {
    return (
        <SettingsLayout>
            <AddUser />
            <ListUsers />
        </SettingsLayout>
    );
};

const AddUser: FC = () => {
    const irisApi = useIrisApi();
    const authStatus = useAuthStatus();

    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [role, setRole] = useState('');

    const queryClient = useQueryClient();

    let addUser = useMutation(
        async () => {
            await irisApi.post('/api/v1/users', {
                Name: name,
                Email: email,
                Role: role,
            });
            await queryClient.invalidateQueries('users');
            setName('');
            setEmail('');
            setRole('');
        },
        { useErrorBoundary: false }
    );

    if (authStatus.data?.user?.role == 'volunteer') {
        return <Redirect to="/frivillig/queue"></Redirect>;
    }

    let emailError = !email && 'E-post är obligatoriskt';
    let nameError = !name && 'Namn är obligatoriskt';
    let roleError = !role && 'Roll är obligatoriskt';

    const hasErrors = emailError || nameError || roleError;

    return (
        <section
            css={css`
                max-width: 350px;
                margin-bottom: ${s(5)};
                h1 {
                    font-size: 20px;
                }
            `}
        >
            <h1>Lägg till ny användare</h1>
            <Form
                error={addUser.error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        addUser.mutate();
                        return true;
                    }
                }}
            >
                <Field label="Namn" error={nameError}>
                    <input
                        placeholder="Namn Namnesson"
                        value={name}
                        autoComplete="off"
                        onChange={ev => setName(ev.target.value)}
                    />
                </Field>
                <Field label="E-post" error={emailError}>
                    <input
                        placeholder="the@email.com"
                        value={email}
                        type="email"
                        autoComplete="off"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Field label="Roll" error={roleError}>
                    {authStatus.data?.user?.role == 'admin' ? (
                        <select value={role} onChange={ev => setRole(ev.target.value)}>
                            <option value="">Välj roll</option>
                            <option value="admin">Admin</option>
                            <option value="volunteer">Frivillig</option>
                            <option value="manager">Verksamhetsledare</option>
                        </select>
                    ) : authStatus.data?.user?.role == 'manager' ? (
                        <select value={role} onChange={ev => setRole(ev.target.value)}>
                            <option value="">Välj roll</option>
                            <option value="volunteer">Frivillig</option>
                        </select>
                    ) : null}
                </Field>
                <Buttons css={css``}>
                    <Button disabled={addUser.isLoading}>
                        {addUser.isLoading ? 'Laddar' : 'Lägg till'}
                    </Button>
                </Buttons>
            </Form>
        </section>
    );
};

type User = {
    ID: number;
    CreatedAt: string;
    Email: string;
    Name: string;
    Role: 'admin' | 'volunteer' | 'manager';
    Attributes: Record<string, number[]>;
    LastActive: string;
};
const roles = ['admin', 'volunteer', 'manager'] as const;

const labelForRole = (role: User['Role']) => {
    switch (role) {
        case 'admin':
            return 'Admin';
        case 'volunteer':
            return 'Frivillig';
        case 'manager':
            return 'Verksamhetsledare';
    }
};

type Sorting =
    | {
          fieldKey: Exclude<keyof User, 'Attributes' | 'ID'>;
          ascending: boolean;
      }
    | {
          fieldKey: 'Attributes';
          attributeKey: number;
          ascending: boolean;
          labels: Attribute['Values'];
      };

const handleSorting = (first: User, second: User, sorting: Sorting) => {
    switch (sorting.fieldKey) {
        case 'CreatedAt':
        case 'LastActive':
            if (sorting.ascending) {
                return Date.parse(first[sorting.fieldKey]) - Date.parse(second[sorting.fieldKey]);
            }
            return Date.parse(second[sorting.fieldKey]) - Date.parse(first[sorting.fieldKey]);

        case 'Email':
        case 'Name':
            if (sorting.ascending) {
                return first[sorting.fieldKey] < second[sorting.fieldKey] ? -1 : 1;
            }
            return second[sorting.fieldKey] < first[sorting.fieldKey] ? -1 : 1;

        case 'Role': {
            const firstLabel = labelForRole(first[sorting.fieldKey]);
            const secondLabel = labelForRole(second[sorting.fieldKey]);
            if (sorting.ascending) {
                return firstLabel < secondLabel ? -1 : 1;
            }
            return secondLabel < firstLabel ? -1 : 1;
        }

        case 'Attributes': {
            const firstLabels =
                first.Attributes[sorting.attributeKey]
                    ?.map(
                        // These are only stored by ID so to get an alphabetic sorting here we have to map them first
                        attributeValueId =>
                            sorting.labels.find(label => label.id === attributeValueId)?.value
                    )
                    .sort()
                    .join('') || '';

            const secondLabels =
                second.Attributes[sorting.attributeKey]
                    ?.map(
                        attributeValueId =>
                            sorting.labels.find(label => label.id === attributeValueId)?.value
                    )
                    .sort()
                    .join('') || '';

            if (sorting.ascending) {
                return firstLabels < secondLabels ? -1 : 1;
            }
            return secondLabels < firstLabels ? -1 : 1;
        }
    }
};

type Filter = StringFilter | AttributeFilter;

type StringFilter = {
    filterType: 'string' | 'dateNewerThan' | 'dateOlderThan'; // OLDER isn't implemented because of time
    value: string;
};

type AttributeFilter = {
    filterType: 'arrayIncludesAny' | 'arrayIncludesAll'; // ALL isn't implemented because of time
    attributeFilters: Record<string, number[]>;
};

const handleFilter = (user: User, fieldKey: keyof User, filter: Filter) => {
    switch (filter.filterType) {
        case 'string': {
            let value = user[fieldKey] as string | undefined;

            // Values are in English, table shows labels in Swedish. Search should match
            // the values users see in the table
            if (fieldKey === 'Role') {
                value = labelForRole(value as User['Role']);
            }

            return value
                ? value.toLocaleLowerCase('sv-SE').includes(filter.value.toLocaleLowerCase('sv-SE'))
                : false;
        }
        case 'dateNewerThan': {
            const value = user[fieldKey] as string | undefined;
            return value ? Date.parse(value) >= Date.parse(filter.value) : false;
        }
        case 'dateOlderThan': {
            const value = user[fieldKey] as string | undefined;
            return value ? Date.parse(value) <= Date.parse(filter.value) : false;
        }
        case 'arrayIncludesAny': {
            // Map over each attribute column (these all have to match for the entire filter to match)
            return Object.entries(filter.attributeFilters).every(
                ([attributeKey, filterValuesForAttribute]) => {
                    const userValuesForAttribute = user.Attributes[attributeKey] ?? [];
                    // Map over the values in each column (this is where the "any" is handled)
                    return userValuesForAttribute.some(attr =>
                        filterValuesForAttribute.includes(attr)
                    );
                }
            );
        }
        case 'arrayIncludesAll': {
            // Map over each attribute column (these all have to match for the entire filter to match)
            return Object.entries(filter.attributeFilters).every(
                ([attributeKey, filterValuesForAttribute]) => {
                    const userValuesForAttribute = user.Attributes[attributeKey] || [];
                    // Map over the values in each column (this is where the "any" is handled)
                    return userValuesForAttribute.every(attr =>
                        filterValuesForAttribute.includes(attr)
                    );
                }
            );
        }
    }
};

type FilterState = { [fieldKey in keyof User]?: Filter };

const getAllowedUsers = (me: ReturnType<typeof useAuthStatus>['data'], users: User[]) => {
    if (me?.user?.role === 'admin') {
        return users;
    }
    if (me?.user?.role === 'manager') {
        return users.filter(user => user.Role === 'volunteer');
    }
    return [];
};

const ListUsers: FC = () => {
    const irisApi = useIrisApi();
    const attributes = useAttributes();
    const userSelectableAttributes = (attributes.data || []).filter(attr => attr.UserAvailable);
    const me = useAuthStatus();
    const users = useQuery('users', async () => {
        let usersRes = await irisApi.get<User[]>('/api/v1/users');
        return usersRes.data;
    });

    const [sorting, setSorting] = useState<Sorting>({ fieldKey: 'Name', ascending: true });
    const [filters, setFilters] = useState<FilterState>({});
    const [usersToDisplay, setUsersToDisplay] = useState<User[]>([]);

    useEffect(() => {
        if (users?.data) {
            const allowedUsers = getAllowedUsers(me.data, users.data);

            const filteredUsers = allowedUsers.filter(user => {
                if (Object.keys(filters).length === 0) return true;

                return Object.entries(filters).every(([fieldKey, filter]) =>
                    handleFilter(user, fieldKey as keyof User, filter)
                );
            });

            setUsersToDisplay(filteredUsers.sort((a, b) => handleSorting(a, b, sorting)));
        }
    }, [users.data, sorting, filters, me.data]);

    if (users.isLoading || !users.data) {
        return <section>Laddar...</section>;
    }

    const handleTableHeadClicked = (fieldKey: Sorting['fieldKey'], attributeKey?: number) => {
        if (
            sorting.fieldKey === fieldKey ||
            (sorting.fieldKey === 'Attributes' && attributeKey === sorting.attributeKey)
        ) {
            setSorting({ ...sorting, ascending: !sorting.ascending });
        } else {
            if (fieldKey === 'Attributes') {
                setSorting({
                    fieldKey,
                    ascending: true,
                    attributeKey: attributeKey!,
                    labels: attributes.data!.find(attribute => attribute.ID === attributeKey)!
                        .Values,
                });
            } else {
                setSorting({ fieldKey, ascending: true });
            }
        }
    };

    const unsetFilter = (fieldKey: keyof User, attributeKey?: string) => {
        if (fieldKey === 'Attributes') {
            // We're trying to unset an Attribute subkey, so we have to do a bit of digging
            const attributesFilter = filters['Attributes'];
            if (attributesFilter === undefined) return;

            if (
                attributesFilter.filterType === 'arrayIncludesAll' ||
                attributesFilter.filterType === 'arrayIncludesAny'
            ) {
                const newAttributeFilters = Object.entries(
                    attributesFilter.attributeFilters
                ).filter(([key]) => key !== attributeKey);

                if (newAttributeFilters.length === 0) {
                    // There are no more attribute keys, remove it in its entirety
                    setFilters(
                        Object.fromEntries(
                            Object.entries(filters).filter(([key]) => key !== fieldKey)
                        )
                    );
                } else {
                    // There's one or more attribute keys left, leave it
                    setFilters({
                        ...filters,
                        Attributes: {
                            ...attributesFilter,
                            attributeFilters: Object.fromEntries(newAttributeFilters),
                        },
                    });
                }
            }
        } else {
            // Normal key, remove it in its entirety
            setFilters(
                Object.fromEntries(Object.entries(filters).filter(([key]) => key !== fieldKey))
            );
        }
    };

    const setStringFilter = (
        fieldKey: keyof User,
        filterType: StringFilter['filterType'],
        filterValue: string
    ) => {
        setFilters({
            ...filters,
            [fieldKey]: {
                filterType,
                value: filterValue,
            },
        });
    };

    const setAttributeFilter = (
        attributeKey: number,
        filterType: AttributeFilter['filterType'],
        filterValue: number
    ) => {
        const existingFilter = filters['Attributes'] as AttributeFilter | undefined;

        if (!existingFilter) {
            // No filter exists, make one
            setFilters({
                ...filters,
                Attributes: {
                    filterType,
                    attributeFilters: {
                        [attributeKey]: [filterValue],
                    },
                },
            });
        } else {
            // Filter exists, change it
            const attributeValues = existingFilter.attributeFilters[attributeKey];

            if (attributeValues.includes(filterValue)) {
                // If the value is already in the attribute filter, remove it
                if (attributeValues.length === 1) {
                    // This is the last value in the attribute filter, so we have to remove it
                    if (Object.keys(existingFilter.attributeFilters).length === 1) {
                        // If the attribute filter is also the last one that exists
                        // we can remove the Attributes filter in its entirety
                        setFilters(
                            Object.fromEntries(
                                Object.entries(filters).filter(([key]) => key !== 'Attributes')
                            )
                        );
                    } else {
                        // Otherwise, modify the Attributes filter instead
                        setFilters({
                            ...filters,
                            Attributes: {
                                ...existingFilter,
                                attributeFilters: Object.fromEntries(
                                    Object.entries(existingFilter.attributeFilters).filter(
                                        ([key]) => parseInt(key) !== attributeKey
                                    )
                                ),
                            },
                        });
                    }
                } else {
                    // There are more values left so we can just filter
                    setFilters({
                        ...filters,
                        Attributes: {
                            ...existingFilter,
                            attributeFilters: {
                                ...existingFilter.attributeFilters,
                                [attributeKey]: attributeValues.filter(f => f !== filterValue),
                            },
                        },
                    });
                }
            } else {
                // This value is not in the attribute filter, add it
                setFilters({
                    ...filters,
                    Attributes: {
                        ...existingFilter,
                        attributeFilters: {
                            ...existingFilter.attributeFilters,
                            [attributeKey]: [
                                ...existingFilter.attributeFilters[attributeKey],
                                filterValue,
                            ],
                        },
                    },
                });
            }
        }
    };

    return (
        <section
            css={css`
                h1 {
                    font-size: 20px;
                }
            `}
        >
            <h1>Alla användare</h1>
            <table
                css={css`
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: auto;
                    border-spacing: 0;

                    tr {
                        border-top: 1px solid #000;
                    }

                    tr:last-of-type {
                        border-bottom: 1px solid #000;
                    }

                    td,
                    th {
                        padding: 10px 40px 10px 0;
                        text-align: left;
                    }

                    select {
                        font-size: 18px;
                        line-height: 24px;
                        display: block;
                        width: 100%;
                        border: 1px solid ${color.lightgrey};
                        border-radius: 3px;
                        padding: ${s(1)} ${s(2)};
                        min-width: 110px;
                    }
                `}
            >
                <thead>
                    <tr
                        css={css`
                            th {
                                cursor: pointer;
                            }
                        `}
                    >
                        <HeaderCell
                            fieldKey="Name"
                            sorting={sorting}
                            onClick={handleTableHeadClicked}
                            filters={filters}
                            setStringFilter={setStringFilter}
                            unsetFilter={unsetFilter}
                        />
                        <HeaderCell
                            fieldKey="Email"
                            sorting={sorting}
                            onClick={handleTableHeadClicked}
                            filters={filters}
                            setStringFilter={setStringFilter}
                            unsetFilter={unsetFilter}
                        />
                        <HeaderCell
                            fieldKey="Role"
                            sorting={sorting}
                            onClick={handleTableHeadClicked}
                            filters={filters}
                            setStringFilter={setStringFilter}
                            unsetFilter={unsetFilter}
                        />
                        {userSelectableAttributes?.map(a => (
                            <HeaderCell
                                key={a.ID}
                                fieldKey="Attributes"
                                attributeKey={a.ID}
                                attributeLabel={a.Key}
                                sorting={sorting}
                                onClick={handleTableHeadClicked}
                                filters={filters}
                                setAttributeFilter={setAttributeFilter}
                                unsetFilter={unsetFilter}
                            />
                        ))}
                        <HeaderCell
                            fieldKey="LastActive"
                            sorting={sorting}
                            onClick={handleTableHeadClicked}
                            filters={filters}
                            setStringFilter={setStringFilter}
                            unsetFilter={unsetFilter}
                        />
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {usersToDisplay.length === 0 ? (
                        <tr>
                            <td>Hittade inga matchande användare</td>
                        </tr>
                    ) : (
                        usersToDisplay.map(user => <UserRow key={user.ID} user={user} />)
                    )}
                </tbody>
            </table>
        </section>
    );
};

type HeaderCellProps = {
    fieldKey: Sorting['fieldKey'];
    attributeKey?: number;
    attributeLabel?: string;
    sorting: Sorting;
    onClick: (fieldKey: Sorting['fieldKey'], attributeKey?: number) => void;
    filters: FilterState;
    setStringFilter?: (
        fieldKey: keyof User,
        filterType: StringFilter['filterType'],
        filterValue: string
    ) => void;
    setAttributeFilter?: (
        attributeKey: number,
        filterType: AttributeFilter['filterType'],
        filterValue: number
    ) => void;
    unsetFilter: (fieldKey: keyof User, attributeKey?: string) => void;
};

const getLabelForField = (fieldKey: Sorting['fieldKey'], attributeLabel?: string) => {
    switch (fieldKey) {
        case 'Email':
            return 'E-post';
        case 'Name':
            return 'Namn';
        case 'Role':
            return 'Roll';
        case 'Attributes':
            return attributeLabel ? toPlural(attributeLabel) : fieldKey;
        case 'LastActive':
            return 'Senast inloggad';
        default:
            return fieldKey;
    }
};

const HeaderCell = ({
    fieldKey,
    attributeKey,
    attributeLabel,
    sorting,
    onClick,
    filters,
    setStringFilter,
    setAttributeFilter,
    unsetFilter,
}: HeaderCellProps) => {
    const label = getLabelForField(fieldKey, attributeLabel);
    const isActive =
        fieldKey === sorting.fieldKey ||
        (sorting.fieldKey === 'Attributes' && attributeKey === sorting.attributeKey);
    const hasFilter =
        fieldKey === 'Attributes'
            ? Object.keys((filters[fieldKey] as AttributeFilter)?.attributeFilters ?? {}).includes(
                  attributeKey!.toString()
              )
            : Object.keys(filters).includes(fieldKey);

    const [showPopover, setShowPopover] = useState(false);

    return (
        <th onClick={() => onClick(fieldKey, attributeKey)}>
            <div
                css={css`
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    position: relative;
                `}
            >
                <div
                    css={css`
                        padding: 4px;
                        border-radius: 2px;
                        ${hasFilter ? 'background-color: #e8d5f7;' : ''}
                        height: 26px;
                        width: 26px;

                        :hover {
                            background-color: #d1a9ef;
                        }

                        svg {
                            vertical-align: top;
                        }
                    `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowPopover(true);
                    }}
                >
                    <Funnel size={18} />
                </div>
                {label}

                {isActive && (
                    <div
                        css={css`
                            width: 20px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        {sorting.ascending ? <ArrowUp /> : <ArrowDown />}
                    </div>
                )}

                {showPopover && (
                    <div
                        css={css`
                            cursor: default;
                        `}
                        onClick={e => e.stopPropagation()}
                    >
                        <div
                            onClick={() => setShowPopover(false)}
                            css={css`
                                position: fixed;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background-color: rgba(69, 69, 69, 0.4);
                                z-index: 2;
                                cursor: pointer;
                            `}
                        ></div>
                        <div
                            css={css`
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: white;
                                padding: 20px;
                                border-radius: 5px;
                                z-index: 3;
                            `}
                        >
                            {fieldKey === 'Attributes' ? (
                                <AttributeFilterContent
                                    attributeKey={attributeKey!}
                                    attributeFilter={
                                        filters['Attributes'] as AttributeFilter | undefined
                                    }
                                    setFilter={setAttributeFilter!}
                                    unsetFilter={unsetFilter}
                                />
                            ) : (
                                <StringFilterContent
                                    fieldKey={fieldKey}
                                    filter={filters[fieldKey] as StringFilter | undefined}
                                    setFilter={setStringFilter!}
                                    unsetFilter={unsetFilter}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </th>
    );
};

type AttributeFilterContentProps = {
    attributeKey: number;
    attributeFilter?: AttributeFilter;
    setFilter: (
        attributeKey: number,
        filterType: AttributeFilter['filterType'],
        filterValue: number
    ) => void;
    unsetFilter: (fieldKey: keyof User, attributeKey?: string) => void;
};

const AttributeFilterContent = ({
    attributeKey,
    attributeFilter,
    setFilter,
    unsetFilter,
}: AttributeFilterContentProps) => {
    const { data: attributes } = useAttributes();

    const currentAttribute = attributes?.find(a => a.ID === attributeKey);
    const selectedValues = attributeFilter?.attributeFilters[attributeKey] ?? [];

    if (!currentAttribute) {
        return null;
    }

    return (
        <div
            css={css`
                max-width: 500px;
                min-width: 200px;
                display: flex;
                flex-flow: column nowrap;
                gap: 24px;
            `}
        >
            <h3
                css={css`
                    margin: 0;
                `}
            >
                Filtrera på {getLabelForField('Attributes', currentAttribute.Key)}
            </h3>
            <div
                css={css`
                    display: flex;
                    flex-flow: row wrap;
                    gap: 8px;
                `}
            >
                {currentAttribute.Values.map(value => (
                    <button
                        key={value.id}
                        type="button"
                        role="button"
                        css={css`
                            border: none;
                            padding: 4px 8px;
                            border-radius: 2px;
                            background-color: ${selectedValues.includes(value.id)
                                ? color.lavendel
                                : 'white'};

                            :hover {
                                background-color: #d1a9ef;
                            }
                        `}
                        onClick={e => {
                            e.preventDefault();
                            setFilter(
                                attributeKey,
                                attributeFilter?.filterType ?? 'arrayIncludesAny',
                                value.id
                            );
                        }}
                    >
                        {value.value}
                    </button>
                ))}
            </div>
            <button
                type="button"
                role="button"
                css={css`
                    border: 1px solid ${color.red};
                    padding: 4px 8px;
                    border-radius: 2px;
                    color: ${color.red};
                    background-color: white;
                    align-self: flex-start;

                    :hover {
                        background-color: ${color.redTint};
                    }
                `}
                onClick={e => {
                    e.preventDefault();
                    unsetFilter('Attributes', currentAttribute.ID.toString());
                }}
            >
                Töm
            </button>
        </div>
    );
};

type StringFilterContentProps = {
    fieldKey: Exclude<keyof User, 'Attributes' | 'ID'>;
    filter?: StringFilter;
    setFilter: (
        fieldKey: keyof User,
        filterType: StringFilter['filterType'],
        filterValue: string
    ) => void;
    unsetFilter: (fieldKey: keyof User, attributeKey?: string) => void;
};

const StringFilterContent = ({
    fieldKey,
    filter,
    setFilter,
    unsetFilter,
}: StringFilterContentProps) => {
    const [filterValue, setFilterValue] = useState(
        filter?.value ??
            (fieldKey === 'LastActive' ? format(subDays(new Date(), 7), 'yyyy-MM-dd') : '')
    );

    return (
        <div
            css={css`
                max-width: 500px;
                min-width: 200px;
                display: flex;
                flex-flow: column nowrap;
                gap: 24px;
            `}
        >
            <h3
                css={css`
                    margin: 0;
                `}
            >
                Filtrera på {getLabelForField(fieldKey)}
            </h3>
            {fieldKey === 'LastActive' ? (
                <input
                    type="date"
                    value={filterValue}
                    onChange={e => setFilterValue(e.target.value)}
                />
            ) : (
                <input
                    type="text"
                    value={filterValue}
                    placeholder="Filtrera..."
                    onChange={e => setFilterValue(e.target.value)}
                />
            )}

            <div
                css={css`
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 8px;
                `}
            >
                <button
                    type="button"
                    role="button"
                    css={css`
                        border: 1px solid ${color.red};
                        padding: 4px 8px;
                        border-radius: 2px;
                        color: ${color.red};
                        background-color: white;

                        :hover {
                            background-color: ${color.redTint};
                        }
                    `}
                    onClick={e => {
                        e.preventDefault();
                        unsetFilter(fieldKey);
                        setFilterValue(
                            fieldKey === 'LastActive' ? formatISO(subDays(new Date(), 7)) : ''
                        );
                    }}
                >
                    Töm
                </button>
                <button
                    type="button"
                    role="button"
                    css={css`
                        border: 1px solid ${color.red};
                        padding: 4px 8px;
                        border-radius: 2px;
                        color: ${color.red};
                        background-color: white;

                        :hover {
                            background-color: ${color.redTint};
                        }
                    `}
                    onClick={e => {
                        e.preventDefault();
                        if (filterValue) {
                            setFilter(
                                fieldKey,
                                fieldKey === 'LastActive' ? 'dateNewerThan' : 'string',
                                filterValue
                            );
                        } else {
                            unsetFilter(fieldKey);
                            setFilterValue(
                                fieldKey === 'LastActive' ? formatISO(subDays(new Date(), 7)) : ''
                            );
                        }
                    }}
                >
                    Filtrera
                </button>
            </div>
        </div>
    );
};

const UserRow: FC<{ user: User }> = ({ user }) => {
    const irisApi = useIrisApi();
    const attributes = useAttributes();
    const userSelectableAttributes = (attributes.data || []).filter(attr => attr.UserAvailable);
    const me = useAuthStatus();
    const queryClient = useQueryClient();

    const setRole = useMutation(async (roleVal: string) => {
        let role = roleVal;
        await irisApi.patch(`/api/v1/users/${user.ID}`, { role });
        await queryClient.invalidateQueries('users');
    });

    const deleteUser = useMutation(async () => {
        await irisApi.delete(`/api/v1/users/${user.ID}`);
        await queryClient.invalidateQueries('users');
    });

    const resetPassword = useMutation(async () => {
        await irisApi.post(`/api/v1/users/${user.ID}/reset-password`);
        await queryClient.invalidateQueries('users');
    });

    const lastActive = new Date(user.LastActive).toISOString().split('T')[0];

    return (
        <tr>
            <td>{user.Name}</td>
            <td>{user.Email}</td>
            <td>
                {setRole.isLoading ? (
                    '...'
                ) : me.data?.user?.role == 'admin' ? (
                    <select value={user.Role} onChange={ev => setRole.mutate(ev.target.value)}>
                        {roles.map(role => (
                            <option key={role} value={role}>
                                {labelForRole(role)}
                            </option>
                        ))}
                    </select>
                ) : me.data?.user?.role == 'manager' ? (
                    <div>Frivillig</div>
                ) : null}
            </td>
            {userSelectableAttributes?.map(a => {
                let values = (user.Attributes[a.ID] || []).map(
                    valueId => a.Values.find(v => v.id === valueId)?.value
                );

                values.sort();

                return <td key={a.ID}>{values.join(', ')}</td>;
            })}
            <td>{lastActive}</td>
            <td>
                {me.data?.user?.id === user.ID ? null : (
                    <div
                        css={css`
                            display: flex;
                            gap: 16px;
                        `}
                    >
                        <button
                            onClick={() => deleteUser.mutate()}
                            disabled={deleteUser.isLoading}
                            css={css`
                                ${attributeButtons};
                            `}
                        >
                            {deleteUser.isLoading ? '...' : 'Radera'}
                        </button>
                        <button
                            onClick={() => resetPassword.mutate()}
                            disabled={resetPassword.isLoading}
                            css={css`
                                ${attributeButtons};
                            `}
                        >
                            {resetPassword.isLoading ? 'Skickar e-post...' : 'Återställ lösenord'}
                        </button>
                    </div>
                )}
            </td>
        </tr>
    );
};
