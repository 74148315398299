import { css } from '@emotion/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { FC } from 'react';
import { Chat } from '../../components/Chat';
import { FeedbackArchive } from '../../components/FeedbackArchive';

export const ArchiveChat: FC<RouteComponentProps> = () => {
    let { chatId } = useParams();
    return (
        <div
            css={css`
                max-width: 790px;
                height: 100%;
                display: flex;
                gap: 16px;
            `}
        >
            <div
                css={css`
                    width: 80%;
                `}
            >
                <Chat chatId={chatId} archiveMode={true} />
            </div>
            <div css={css``}>
                <FeedbackArchive />
            </div>
        </div>
    );
};
